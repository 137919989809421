import { QueryCache } from "@tanstack/react-query";
import {toast} from 'react-toastify';

const apiURL = process.env.REACT_APP_LOCAL_URL;

const queryCache = new QueryCache({
  onError: (error) => {
    console.log(error);
  },
  onSuccess: (data) => {
    console.log(data);
  },
});

async function client(endpoint,{ data, method, headers: customHeaders, ...customConfig } = {})
{
   console.log("🚀 ~ data:", data)
  const token = localStorage.getItem("Token");
  const config = {
    method: method || (data ? "POST" : "GET"),
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      Authorization: token ? `${token}` : "",
      "Content-Type": data ? "application/json" : "",
      ...customHeaders,
    },
    ...customConfig,
  };
  

  try {
    const response = await fetch(`${apiURL}/${endpoint}`, config);
    const responseData = await response.json();
      console.log("🚀 ~ responseData:", responseData)
    if (response.ok) {
      // toast.success(responseData?.message)
      return responseData;
    } else if (response.status === 401) {
      queryCache.clear();
      toast.error(responseData?.message || "Unauthorized access", {
        position: "bottom-right"
      });
      throw new Error("Please re-authenticate.");
    } else {
      toast.error(responseData?.message, {
        position: "bottom-right"
      })
      return responseData;
    }
  } catch (error) {
    console.error("API request failed:", error);
    toast.error("An error occurred while processing the request", {
      position: "bottom-right"
    });
    return error;
  }
}

async function imageUploadClient(
  endpoint,
  { data, headers: customHeaders = {}, ...customConfig } = {}
) {
  const token = localStorage.getItem("Token");

  // Create FormData and append the file

  // Configure the request
  const config = {
    method: "POST",
    body: data,
    headers: {
      Authorization: token ? `${JSON.parse(token)}` : "",
      //    "Content-Type":"multipart/form-data" ,
      ...customHeaders,
    },
    ...customConfig,
  };

  try {
    const response = await fetch(`${apiURL}/${endpoint}`, config);
    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else if (response.status === 401) {
      toast.error(responseData?.message || "Unauthorized access");
      throw new Error("Please re-authenticate.");
    } else {
      toast.error(responseData?.message || "An error occurred");
      throw new Error(responseData?.message || "Request failed");
    }
  } catch (error) {
    console.error("API request failed:", error);
    toast.error("An error occurred while processing the request");
    throw error;
  }
}

export { client,imageUploadClient };
