import React from 'react'
import { useNavigate } from 'react-router-dom'
import AssignmentIcon from '../../assets/assigGen.png'
import ComprehensionIcon from '../../assets/reading.png'
const Tools = () => {
const navigate=useNavigate()
  const handlenavigate=()=>{
    navigate('/dashboard/tools/essay-title')
  }
  const handleComprehension=()=>{
    navigate('/dashboard/comprehension')
  }
  return (
    <div className=' flex p-10 gap-10'>
     <div onClick={handlenavigate} className='w-80 cursor-pointer bg-[#FFFFFF] border border-[#5e97b3] p-4 shadow-lg rounded-lg  flex justify-center gap-4 items-center'>
        <img src={AssignmentIcon} alt='AssignmentIcon' className='w-16'/>
        <h1 className='text-[20px] font-bold text-[#36498f]'>Essay Title Generator</h1>
     </div>
     <div onClick={handleComprehension} className='w-80 bg-[#FFFFFF] border cursor-pointer border-[#5e97b3] p-4 shadow-lg rounded-lg ew-[100px] flex justify-center gap-4 items-center'>
        <img src={ComprehensionIcon} alt='ComprehensionIcon' className='w-16'/>
        <h1 className='text-[20px] font-bold text-[#36498f]'>Comprehension</h1>
     </div>
    </div>
  )
}

export default Tools
