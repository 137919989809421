// utils/constants.js

export const truncateText = (text, wordCount = 7) => {
  const words = text.split(" ");
  if (words.length > wordCount) {
    return words.slice(0, wordCount).join(" ") + "...";
  }
  return text;
};
  

export  const grades=[
  {label:'Grade 1' , value:1},
  {label:'Grade 2' , value:2},
  {label:'Grade 3' , value:3},
  {label:'Grade 4' , value:4},
  {label:'Grade 5' , value:5},
  {label:'Grade 6' , value:6},
  {label:'Grade 7' , value:7},
  {label:'Grade 8' , value:8},
  {label:'Grade 9' , value:9},
  {label:'Grade 10' , value:10},
  {label:'Grade 11' , value:11},
  {label:'Grade 12' , value:12},

  // {label:'Grade 12' , value:12},
]