import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { client ,imageUploadClient} from "./clientApi";
import { toast } from "react-toastify";
// import axiosInstance from '../axios';


export const useGenerateComAiTopic = () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      const response = await client("comprehension/generate-comprehension-topics", {
        data,
      });
      return response;
    },
    onSuccess: (response) => {
      // queryClient.invalidateQueries(["getClasses"]);
      toast.success(response?.data?.message , {
        position: "bottom-right"
      });
    },
    onError: (error) => {
      console.log("Login failed", error);
    },
  });
};

export const useGenerateComPassage = () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      const response = await client("comprehension/get-passage", {
        data,
      });
      return response;
    },
    onSuccess: (response) => {
      // queryClient.invalidateQueries(["getClasses"]);
      toast.success(response?.data?.message , {
        position: "bottom-right"
      });
    },
    onError: (error) => {
      console.log("Login failed", error);
    },
  });
};

export const useGenerateComQuestion = () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      const response = await client("comprehension/generate-questions", {
        data,
      });
      return response;
    },
    onSuccess: (response) => {
      // queryClient.invalidateQueries(["getClasses"]);
      toast.success(response?.data?.message , {
        position: "bottom-right"
      });
    },
    onError: (error) => {
      console.log("Login failed", error);
    },
  });
};

export const useUpdateComQuestion = () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      const response = await client("comprehension/teacher/update-questions", {
        data,
      });
      return response;
    },
    onSuccess: (response) => {
      // queryClient.invalidateQueries(["getClasses"]);
      toast.success(response?.data?.message , {
        position: "bottom-right"
      });
    },
    onError: (error) => {
      console.log("Login failed", error);
    },
  });
};

export const useGenerateComEvaluation= () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      const response = await client("comprehension/generate-comprehension-sample-evaluation", {
        data,
      });
      return response;
    },
    onSuccess: (response) => {
      // queryClient.invalidateQueries(["getClasses"]);
      toast.success(response?.data?.message , {
        position: "bottom-right"
      });
    },
    onError: (error) => {
      console.log("Login failed", error);
    },
  });
};

export const useAssignComAssignment= () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      const response = await client("comprehension/classroom/assign-comprehension", {
        data,
      });
      return response;
    },
    onSuccess: (response) => {
      // queryClient.invalidateQueries(["getClasses"]);
      toast.success(response?.data?.message , {
        position: "bottom-right"
      });
    },
    onError: (error) => {
      console.log("Login failed", error);
    },
  });
};

export const useUploadPassageFile = () => {
  return useMutation({
    mutationFn: async (file) => {
      const formData = new FormData();
      formData.append('file', file);

      const response = await imageUploadClient('comprehension/teacher/upload-passage', {
        data: formData
      });
      return response;
    },
    onError: (error) => {
      console.error('Upload error:', error);
      toast.error(error.message || 'Failed to upload file');
    }
  });
};



export const useGetStudentCompAssignment= (id) =>
  useQuery({
    queryKey: ["getStudentCompAssignment",id],
    queryFn: async () => await client(`comprehension/student/get-assignment/${id}`),
    staleTime: 60000,
    cacheTime: 3600000,
  });
  export const useSubmitComAssignment= () => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: async (data) => {
        const response = await client("comprehension/student/submit-comprehension", {
          data,
        });
        return response;
      },
      onSuccess: (response) => {
        queryClient.invalidateQueries(["getAssignment"]);
        toast.success(response?.data?.message , {
          position: "bottom-right"
        });
      },
      onError: (error) => {
        console.log("Login failed", error);
      },
    });
  };


    export const useGetCompAssignmentEvaluation= () => {
      return useMutation({
        mutationFn: async (data) => {
          const response = await client("comprehension/teacher/get-raw-comprehension-evaluation", {
            data,
          });
          return response;
        },
        onSuccess: (response) => {
          // queryClient.invalidateQueries(["getClasses"]);
          toast.success(response?.data?.message , {
            position: "bottom-right"
          });
        },
        onError: (error) => {
          console.log("Login failed", error);
        },
      });
    };
    export const useGetCompAssignmentEvaluationApprove= () => {
      return useMutation({
        mutationFn: async (data) => {
          const response = await client("comprehension/teacher/approve-comprehension-evaluation", {
            data,
          });
          return response;
        },
        onSuccess: (response) => {
          // queryClient.invalidateQueries(["getClasses"]);
          toast.success(response?.data?.message , {
            position: "bottom-right"
          });
        },
        onError: (error) => {
          console.log("Login failed", error);
        },
      });
    };

    export const useStartCompAssignment= () => {
      // const queryClient = useQueryClient();
      return useMutation({
        mutationFn: async ( data ) => {
          return client(`comprehension/student/start-comprehension-timer`, { data });
        },
        onSuccess: (response) => {
          // queryClient.invalidateQueries(["getAssignment"]);
          toast.success(response?.data?.message, {
            position: "bottom-right"
          });
        },
        onError: (error) => {
          console.log("Login failed", error);
        },
      });
    };
    
    export const useAutoSaveDraft = () => {
      // const queryClient = useQueryClient();
      return useMutation({
        mutationFn: async ( data ) => {
          return client(`comprehension/student/auto-save-comprehension`, { data });
        },
        onSuccess: (response) => {
          // queryClient.invalidateQueries(["getAssignment"]);
          toast.success(response?.data?.message, {
            position: "bottom-right"
          });
        },
        onError: (error) => {
          console.log("Login failed", error);
        },
      });
    };
// export const useGetClasses = (id, searchValue, page) =>
//   useQuery({
//     queryKey: ["getClasses", id, searchValue, page],
//     queryFn: async () =>
//       await client(
//         `teacher/get-classes/${id}?search=${searchValue || ""}&page=${
//           page || 1
//         }`
//       ),
//     staleTime: 60000,
//     cacheTime: 3600000,
//   });
