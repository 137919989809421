import React from 'react';

const StatCard = ({ title, value, icon: Icon, color, bgColor }) => {
  return (
    <div
      className="bg-white p-4 rounded-xl shadow-sm border border-gray-200 flex items-center justify-between"
      style={{ backgroundColor: bgColor }}
    >
      <div>
        <p className="text-[14px] font-bold text-gray-600">{title}</p>
        <p className="text-[20px] font-bold" style={{ color }}>
          {value}
        </p>
      </div>
      <Icon
        className="w-8 h-8"
        style={{ color }}
      />
    </div>
  );
};

export default StatCard;