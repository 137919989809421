import React from 'react'
import DOMPurify from 'dompurify'
import CloseIcon from "../../../assets/closeIcon.svg";

const StudentAssignmentText = ({handleAssignmentTextClose,assignmentTextOpen,AssigText}) => {
    console.log(AssigText,"AssigText")
  return (
    
    <>
      {assignmentTextOpen && (
        <div
          class="relative z-50"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>

          <div class="fixed inset-0 z-10 w-screen px-20 ">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div class="pb-4 relative transform overflow-hidden rounded-lg bg-white text-center shadow-xl transition-all ">
                <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-full ">
                  <div class="flex justify-between items-center pb-4 gap-4">
                    <h1 className="text-[18px] text-[#000000] font-[700] ">
                      Submitted Assignment
                    </h1>
                   <img   src={CloseIcon} alt="close" className="w-5 cursor-pointer" onClick={handleAssignmentTextClose}/>
                  </div>

                  <div class="border border-gray-200 rounded-lg p-2 
                  h-[500px] overflow-y-auto 
                  scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100
  hover:scrollbar-thumb-gray-500 w-full ">
                    {/* {!AssigText?.submissionText ?
                     <div className='flex justify-center items-center h-full w-full'>
                      <h1 className='text-[16px] font-[500] text-[#000]'>No Content Found</h1>
                    </div>
                    : */}
                      {AssigText?.type === "comprehension" ? (
                      <>
                        {AssigText?.submissionText?.map((section) => (
                          <div className="mb-2">
                            <div className="space-y-4">
                              <div
                                key={section.id}
                                className="bg-white rounded-lg border flex flex-col justify-start items-start border-gray-200 p-4"
                              >
                                {/* <h3 className="text-lg font-semibold mb-4">
                                Type : <span className="underline underline-offset-4 text-blue-500">{section.type} Questions</span> 
                                </h3> */}
                                <div className=" mb-2">
                                    <p className=" font-medium">
                                      Q : {section.question}
                                    </p>
                                    {/* {question.line_reference && (
                                <span className="text-sm text-gray-500">Reference: {question.line_reference}</span>
                              )} */}
                                  
                                  {/* <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
                              {section.marks} marks
                            </span> */}
                                </div>
                                <div className="mt-2 flex flex-col items-start text-left gap-2">
                                  <p className="text-gray-700">
                                    <span className=" text-left font-medium">Answer:</span>{" "}
                                    {section.answer}
                                  </p>
                                  {section?.options && (
                                    <ul className="grid grid-cols-2 gap-2">
                                      {section?.options?.map((option) => (
                                        <li className={`text-gray-700 text-sm text-left border  border-gray-200 p-2 rounded-md ${section?.answer === option ? "bg-blue-100" : ""}`}>{option}</li>
                                      ))}
                                    </ul>
                                  )}
                                  {section.explanation && (
                                    <p className="text-gray-600 mt-2 text-sm">
                                      <span className="font-medium">
                                        Explanation:
                                      </span>{" "}
                                      {section.explanation}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <div
                        className="text-left"
                        dangerouslySetInnerHTML={{
                          __html: AssigText?.submissionText,
                        }}
                      ></div>
                    )}
                  
                    
                  </div>
                 
                </div>
              
              </div>
            </div>
          </div>
        </div>
      )}
</>
  )
}

export default StudentAssignmentText
