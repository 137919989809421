import React, { useState,useRef,useEffect} from "react";
import axios from "axios";
import { useGetAssignment} from "../../../utils/api/userApi";

import { toast } from "react-toastify";
import StudentAssgTable from "./studentAssigTable";
import { useNavigate } from "react-router-dom";

import Loader from "../../../components/sharedUi/loader";

import AssignmentText from './studentAssigText'
import AssignmentEvaluationView from './studentEvaluationView'
import AssignmentNotice from './assignmentNotice'
import ComprehensionEvaluationView from './comrehension/compEvaluationView'
// import Assignment from './studentAssignment';

const Assignment = () => {
  const navigate=useNavigate()
  const [searchValue, setSearchValue] = useState("");
  const textAreaRef = useRef(null);
  const [status, setStatus] = useState(""); 
  const [page, setPage] = useState(1);
  const [evaluationViewOpen,setEvaluationViewOpen]=useState(false)
  const [compShowEvaluation,setCompShowEvaluation]=useState(false)
  const [selecAssignment, setSelectAssignmet] = useState();
  console.log("🚀 ~ Assignment ~ selecAssignment:", selecAssignment)
  const [assignmentNoticeOpen,setAssignmentNoticeOpen]=useState(false)
  console.log("🚀 ~ Assignment ~ selecAssignment:", selecAssignment)
 
  
 
  
  const userData = JSON.parse(localStorage.getItem("UserData"));
  const { data: studentAssignment ,isPending:studentAssignmentLoading} = useGetAssignment({
    id:userData?.userId,
    search:searchValue,
    status:status,
    page:page
  });
  
 
  const [assignmentTextOpen,setAssignmentTextOpen] = useState(false);
  const [AssigText,setAssigText]=useState('')
 console.log("🚀 ~ Assignment ~ AssigText:", AssigText)

  const [essayFile, setEssayFile] = useState(null);

 
  const handleAssignmentOpen = (assignment) => {
    if(assignment?.status === 'Graded' || assignment?.status === 'Submitted'|| assignment?.status === 'Submitted Late'  || assignment?.status === 'Evaluation Failed'){
      return toast.success(`assignment already ${assignment?.status === 'late submission' ? 'late submitted' : assignment?.status}`, {
        position: "bottom-right"
      });
    } else if (assignment?.status === 'In Progress'){
      if(assignment?.type === "comprehension"){
        navigate('/dashboard/compreAssignment',{state:{selecAssignment:assignment}})
      }else{
        navigate('/dashboard/assignment/editing',{state:{selecAssignment:assignment}})
      }
    }else{
      setAssignmentNoticeOpen(true);
      setSelectAssignmet(assignment)
      // navigate('/dashboard/assignment/editing',{state:{assignment}})
    }
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };
  const totalPages = studentAssignment?.pagination?.totalPages || 1;

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const leftOffset = Math.max(page - Math.floor(maxVisiblePages / 2), 1);
      const rightOffset = Math.min(
        leftOffset + maxVisiblePages - 1,
        totalPages
      );

      if (leftOffset > 1) pageNumbers.push(1, "...");
      for (let i = leftOffset; i <= rightOffset; i++) {
        pageNumbers.push(i);
      }
      if (rightOffset < totalPages) pageNumbers.push("...", totalPages);
    }

    return pageNumbers;
  };
  const handleEvaluationView = (e, assignment) => {
    e.stopPropagation(); // Prevent the default action
    if (assignment?.type === "comprehension") {
      setCompShowEvaluation(true)
      setSelectAssignmet(assignment)
    } else {
      setEvaluationViewOpen(true)
      setSelectAssignmet(assignment)
    }
};
useEffect(() => {
  const handleCopyPaste = (e) => {
      e.preventDefault();
      alert('Copy-paste is disabled on this page.');
  };

  const textArea = textAreaRef.current;
  if (textArea) {
      textArea.addEventListener('copy', handleCopyPaste);
      textArea.addEventListener('paste', handleCopyPaste);
  }

  return () => {
      if (textArea) {
          textArea.removeEventListener('copy', handleCopyPaste);
          textArea.removeEventListener('paste', handleCopyPaste);
      }
  };
}, []);

  const handleAssignmentTextOpen=(e,assignment)=>{
    e.stopPropagation(); // Prevent the default action
    setAssignmentTextOpen(!assignmentTextOpen)
    setAssigText(assignment)
  }
  const handleAssignmentTextClose=()=>{
    setAssignmentTextOpen(false)
  }
  const handleAssigNoticeClose=()=>{
    setAssignmentNoticeOpen(false)
  }

  const handleAssigEditorOpen=()=>{
    if(selecAssignment?.type === "comprehension"){
      navigate(`/dashboard/compreAssignment`,{state:{selecAssignment}})
    }else{
      navigate('/dashboard/assignment/editing',{state:{selecAssignment}})
    }

  }

  return (
    <>
    {studentAssignmentLoading && <Loader/>}
      <div className="p-5 flex flex-col gap-2">
      <div class="flex justify-end items-center gap-2 pb-1 bg-white ">
              <label for="table-search" class="sr-only">
                Search
              </label>
              <div class="relative ">
                <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    class="w-4 h-4 text-gray-500 "
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  value={searchValue}
                  onChange={handleSearch}
                  type="text"
                  id="table-search"
                  class="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                  placeholder="Search By Name"
                />
              </div>
              <select
                value={status}
                onChange={handleStatusChange}
                className="w-40 p-2 text-sm bg-gray-50  text-[#000] border border-gray-300 rounded-lg"
              >
                <option value=""  >
                  All
                </option>
                <option value="Not Started">Not Started</option>
                <option value="submitted">Submitted</option>
                <option value="Graded">Graded</option>
                <option value="Submitted Late">Submitted Late</option>
               
                <option value=" Evaluation Failed"> Evaluation Failed </option>


              </select>
            </div>
       <StudentAssgTable  handleAssignmentTextClose={handleAssignmentTextClose} handleAssignmentTextOpen={handleAssignmentTextOpen} handleEvaluationView={handleEvaluationView} handleAssignmentOpen={handleAssignmentOpen}  studentAssignment={studentAssignment} page={page} totalPages={totalPages} getPageNumbers={getPageNumbers} handlePageChange={handlePageChange}/>
      </div>
             <AssignmentText handleAssignmentTextClose={handleAssignmentTextClose} assignmentTextOpen={assignmentTextOpen} AssigText={AssigText} />         
    {evaluationViewOpen && <AssignmentEvaluationView setEvaluationViewOpen={setEvaluationViewOpen} selectedAssignment={selecAssignment}/>}
    {assignmentNoticeOpen && <AssignmentNotice handleAssigEditorOpen={handleAssigEditorOpen} selectedAssignment={selecAssignment} handleAssigNoticeClose={handleAssigNoticeClose}/>}
    {compShowEvaluation && <ComprehensionEvaluationView
   setCompShowEvaluation={setCompShowEvaluation}
   selecAssignment={selecAssignment}
    />}
    </>
  );
};

export default Assignment;
