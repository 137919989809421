

import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { Clock, FileCheck, XCircle, Send, AlertTriangle } from 'lucide-react';
import StatCard from './statsCard';
import {toast} from 'react-toastify';

import Loader from "../../components/sharedUi/loader";
import { useGetStudentStats } from "../../utils/api/userApi";
import { useState } from 'react';
import JoinClass from './joinClass';
import { useJoinClass } from "../../utils/api/userApi";
const COLORS = ['#10B981', '#F59E0B', '#3B82F6', '#EC4899', '#8B5CF6'];

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, value }) => {
  const RADIAN = Math.PI / 180;
  const radius = outerRadius * 1.2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const lineX = cx + (outerRadius + 10) * Math.cos(-midAngle * RADIAN);
  const lineY = cy + (outerRadius + 10) * Math.sin(-midAngle * RADIAN);
  const textAnchor = x > cx ? 'start' : 'end';
  
  return (
    <g>
      <path
        d={`M ${cx + outerRadius * Math.cos(-midAngle * RADIAN)},${
          cy + outerRadius * Math.sin(-midAngle * RADIAN)
        }L ${lineX},${lineY}L ${x},${y}`}
        stroke="#333"
        fill="none"
      />
      <circle cx={lineX} cy={lineY} r="2" fill="#333" />
      <text
        x={x}
        y={y}
        textAnchor={textAnchor}
        fill="#333"
        dominantBaseline="central"
        className="text-sm font-medium"
      >
        {`${value} (${(percent * 100).toFixed(1)}%)`}
      </text>
    </g>
  );
};

const StudentDashboard = () => {

  const user=JSON.parse(localStorage.getItem("UserData"));
    const {data:studentStats ,isPending}=useGetStudentStats(user?.userId)
    const {mutate:joinClass,isPending:joinClassPending}=useJoinClass()
    console.log("🚀 ~ StudentDashboard ~ data:", studentStats)
    const [joinClassOpen,setJoinClassOpen]=useState(false)
    const [classCode,setClassCode]=useState("")
    const [classCodeError,setClassCodeError]=useState("")
    const [joinClassSuccess,setJoinClassSuccess]=useState()

    const handleClassCodeChange=(e)=>{
      setClassCode(e.target.value)
      setClassCodeError("")
    }


    const handleJoinClassOpen=()=>{
      setJoinClassOpen(true)
    }
      const handleJoinClassClose=()=>{
        setJoinClassOpen(false)
      }
  const statusCards = [
    { name: 'Not Started', value: studentStats?.assignmentStats?.notStarted, icon: Clock,color: COLORS[0] },
    { name: 'Submitted', value: studentStats?.assignmentStats?.submitted, icon: Send,color: COLORS[1] },
    { name: 'Late Submissions', value: studentStats?.assignmentStats?.submittedLate, icon: AlertTriangle,color: COLORS[2] },
    { name: 'Graded', value: studentStats?.assignmentStats?.graded, icon: FileCheck,color: COLORS[3] },
    { name: 'Evaluation Failed', value: studentStats?.assignmentStats?.failed, icon: XCircle,color: COLORS[4] },
  ];

  const pieData = statusCards
    .map(card => ({
      name: card.name,
      value: parseInt(card?.value),
      color: card.color
    }))
    .filter(item => item?.value > 0);

    const handleJoinClass=()=>{
      if(!classCode){
        return setClassCodeError("Class code is required")
      }
      const data={
        class_code:classCode,
        student_id:user?.userId,
   

      }
      joinClass(data,{
        onSuccess:(response)=>{
          console.log("🚀 ~ handleJoinClass ~ response:", response)
          if(response?.success){
          toast.success(response?.message,{
            position:"bottom-right"
          })
          setJoinClassSuccess(response)
          setTimeout(() => {
            setJoinClassOpen(false);
          }, 5000);
        }
        }
      })
    }


  return (
    <>
    {isPending  || joinClassPending&& <Loader/>}
    <div className='p-5'>
      <div className='flex justify-end w-full pb-2'>

      <button onClick={handleJoinClassOpen} className='bg-blue-500 text-white px-4 py-2 rounded-md '>Join Class</button>
      </div>
    <div className="bg-white p-6 rounded-xl shadow-lg border border-gray-200">
      <h2 className="text-xl font-bold mb-4">Student Progress</h2>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-6">
        {statusCards.map((stat, index) => (
          <StatCard
            key={stat.name}
            title={stat.name}
            value={stat.value}
            icon={stat.icon}
            color={stat?.color}
            bgColor={`${stat?.color}15`}
          />
        ))}
      </div>
      <div className="h-[350px]">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={pieData}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
              labelLine={false}
              label={renderCustomizedLabel}
            >
              {pieData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
    </div>
    {joinClassOpen && <JoinClass joinClassSuccess={joinClassSuccess} classCodeError={classCodeError} handleClassCodeChange={handleClassCodeChange} classCode={classCode} handleJoinClassClose={handleJoinClassClose} handleJoinClass={handleJoinClass} />}
    </>
  );
};

export default StudentDashboard;



















// import React, { useState,useContext } from "react";

// import Loader from "../../components/sharedUi/loader";
// import { useGetStudentStats } from "../../utils/api/userApi";
// import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid} from 'recharts';
// import { BookOpen, Clock,BookCheck, FileCheck} from 'lucide-react';


// const COLORS = ['#10B981', '#F59E0B', '#3B82F6', '#EC4899', '#8B5CF6'];
// const RADIAN = Math.PI / 180;
// const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, value, name, index }) => {
//   const radius = outerRadius * 1.2;
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
//   // Calculate the position for the line's end point
//   const lineX = cx + (outerRadius + 10) * Math.cos(-midAngle * RADIAN);
//   const lineY = cy + (outerRadius + 10) * Math.sin(-midAngle * RADIAN);
  
//   // Determine text anchor based on which side of the pie the label is on
//   const textAnchor = x > cx ? 'start' : 'end';
  
//   return (
//     <g>
//       {/* Line from pie to label */}
//       <path
//         d={`M ${cx + outerRadius * Math.cos(-midAngle * RADIAN)},${
//           cy + outerRadius * Math.sin(-midAngle * RADIAN)
//         }L ${lineX},${lineY}L ${x},${y}`}
//         stroke={COLORS[index % COLORS.length]}
//         fill="none"
//       />
//       {/* Circle at the bend point */}
//       <circle cx={lineX} cy={lineY} r="2" fill={COLORS[index % COLORS.length]} />
      
//       {/* Label text */}
//       <text
//         x={x}
//         y={y}
//         textAnchor={textAnchor}
//         fill={COLORS[index % COLORS.length]}
//         dominantBaseline="central"
//         className="text-sm font-medium"
//       >
//         {`${value} (${(percent * 100).toFixed(1)}%)`}
//       </text>
//     </g>
//   );
// };

// const StudentDashboard = () => {
//   const user=JSON.parse(localStorage.getItem("UserData"));
//   const {data:studentStats ,isPending}=useGetStudentStats(user?.userId)
//   console.log("🚀 ~ StudentDashboard ~ data:", studentStats)
  
//   const chartData = [
//     { name: 'Submitted', value: parseInt(studentStats?.submittedAssignments) ,icon:BookCheck},
//     { name: 'Pending', value: parseInt(studentStats?.pendingAssignments), icon:BookOpen},
//     { name: 'Evaluated', value: parseInt(studentStats?.evaluatedAssignments), icon:FileCheck},
//     { name: 'In Progress', value: parseInt(studentStats?.inProgressAssignments) ,icon:Clock},
//   ];
//   const total = chartData?.reduce((sum, item) => sum + item.value, 0);
// console.log("🚀 ~ StudentDashboard ~ total:", total)

// const calculatePercentage = (value, total) => {
//   // Add console.log to debug values
//   console.log('Value:', value, 'Total:', total);
  
//   // Handle all possible cases
//   if (!value || !total || total === 0) return '0';
  
//   const percentage = (value / total) * 100;
  
//   // Check if result is NaN
//   return isNaN(percentage) ? '0' : percentage.toFixed(1);
// };

//   return (
//     <>
//     {isPending && <Loader/>}
//    <div className="flex flex-col p-5 gap-4">
    
//    <div className="bg-white p-6 rounded-xl shadow-lg border border-gray-200">
//       <h2 className="text-xl font-bold mb-4">Student Assignment Stats</h2>
      
//       <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
//         {chartData?.map((item, index) => (
//           <div className='flex items-center justify-between p-4 rounded-lg'style={{ backgroundColor: `${COLORS[index % COLORS.length]}15` }}>
//           <div 
//             key={item.name} 
//             className="p-3 rounded-lg"
           
//           >
//             <p className="text-sm text-gray-600">{item.name}</p>
//             <p className="text-xl font-bold" style={{ color: COLORS[index % COLORS.length] }}>
//               {item.value || 0}
//             </p>
//             <p className="text-xs text-gray-500">
//   {/* {item.value === '' ? 0 : (((item.value) / total) * 100).toFixed(1)}% */}
//   {calculatePercentage(item.value, total)}%

//               {/* {(((item.value || 0)/ total) * 100).toFixed(1) }%  */}
//             </p>

            
//           </div>
//           <item.icon 
//                 className="w-10 h-10"
//                 style={{ color: COLORS[index % COLORS.length] }}
//               />
//           </div>
//         ))}
//       </div>

//       <div className="h-[350px]"> {/* Increased height to accommodate labels */}
//         <ResponsiveContainer width="100%" height="100%">
//           <PieChart>
//             <Pie
//               data={chartData}
//               cx="50%"
//               cy="50%"
//               innerRadius={60}
//               outerRadius={80}
//               fill="#8884d8"
//               paddingAngle={5}
//               dataKey="value"
//               labelLine={false}
//               label={renderCustomizedLabel}
//             >
//               {chartData?.map((_, index) => (
//                 <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//               ))}
//             </Pie>
//             <Tooltip />
//             <Legend />
//           </PieChart>
//         </ResponsiveContainer>
//       </div>
//     </div>
//    </div>
   
  
     
//     </>
//   );
// };

// export default StudentDashboard;
