import React, { createContext, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
const TimerContext = createContext();

export const useTimerContext = () => useContext(TimerContext);

export const TimerProvider = ({ children }) => {


    const [essayContent, setEssayContent] = useState('');

    const [ocrText,setOcrText]=useState('')


  const handleEssayContent=useCallback((content)=>{
    // event.stopPropagation()
    setEssayContent(content)
  },[])




  
  

  const value = {
    essayContent,
    setEssayContent,
    handleEssayContent,
    setOcrText,
    ocrText,
   
  };

  return <TimerContext.Provider value={value}>{children}</TimerContext.Provider>;
};
