import React, { useState, useEffect } from "react";
import SearchFilter from "../../../components/searchFilter";
import TitleList from "../../../components/titleItem";
import AiButton from "../../../components/AiButton";
import { Topics } from "../../../utils/constants";
import axios from "axios";
import { DatePicker } from "rsuite";
import {
  useGetClasses,
  useGenerateAiTitle,
  useSaveEssayTopics,
  useAssignEssay,
} from "../../../utils/api/userApi";
import { toast } from "react-toastify";
import { useClassContext } from "../../../components/context/classContext";
import Loader from "../../../components/sharedUi/loader";
import { useNavigate, useLocation } from "react-router-dom";
import BackIocn from "../../../assets/arrowBackIcon.svg";

import FormValidationError from "../../../components/sharedUi/FormValidatorError";
import RubricTable from "./rubricTable";
import EssayDetails from "./essayDetails";
const EssayGenerator = () => {
  const logData = JSON.parse(localStorage.getItem("UserData"));
  const navigate = useNavigate();

  const { data: classes } = useGetClasses(logData?.userId);
  console.log("🚀 ~ EssayGenerator ~ classes:", classes);
  const { mutate: generateAiTitle, isPending: generateAiTitlePending } = useGenerateAiTitle();
  const { mutate: saveEssayTopics, isPending: saveEssay } =useSaveEssayTopics();
  // const { mutate: assignEssay, isPending: assignEssayPending } = useAssignEssay();
  const { selectedClass } = useClassContext();
  console.log("selectedClass",selectedClass);

  const [essayTitles, setEssayTitles] = useState([]);
  const [selectClass, setSelectClass] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  console.log("selectedOptions",selectedOptions);
  const [classError, setClassError] = useState("");
  const [dueDate, setDueDate] = useState();
  const [dueDateError, setDueDateError] = useState("");
  const [timeLimit, setTimeLimit] = useState("");
  const [wordLimit, setWordLimit] = useState("");
  const [topicError, setTopicError] = useState("");
  const [GradeError, setGradeError] = useState("");

  const [filterEssayRubric, setFilterEssayRubric] = useState();
  const [currentView, setCurrentView] = useState("initial"); // 'initial', 'titleList', 'assignmentSetup'

  const [aigenEssayRubric, setAiGenEssayRubric] = useState();
  const [selectedTitle, setSelectedTitle] = useState(null);

  const [modifyPrompt, setModifyPrompt] = useState('');

  const handleModifyPrompt = (e) => {
    setModifyPrompt(e.target.value);
  };

  const [filters, setFilters] = useState({
    topic: "",
    Grade: selectedClass?.classroom_grade || "",
  });
  const [sessionId, setSessionId] = useState("");
  // const [modifyQuery,setModifyQuery] = useState();
  const handleTimeLimit = (e) => {
    const value = e.target.value;
    if (value === "" || /^[0-9]+$/.test(value)) {
      setTimeLimit(value);
    }
  };
  const handleWordLimit = (e) => {
    const value = e.target.value;
    if (value === "" || /^[0-9]+$/.test(value)) {
      setWordLimit(value);
    }
  };

  const GenerateAiTitle = async () => {
    if (filters?.Grade === "") {
      return setGradeError("Please Select Grade"); 
    }
    if (filters?.topic === "") {
      return setTopicError("Field Required");
    }

    const data = {
      query: filters?.topic,
      grade: filters?.Grade,
      sessionId: sessionId || "",
    };

    generateAiTitle(data, {
      onSuccess: (response) => {
        console.log("response12121", response);
        setEssayTitles(response);
        setCurrentView("titleList"); // Set view to title list after generation
        setSessionId(response?.sessionId);
        toast.success(response?.message,{
          position:"bottom-right"
        });
      },
      onError: (error) => {
        toast.error("Failed to approve evaluation: " + error.message,{
          position:"bottom-right"
        });
      },
    });
  };


  const handleSelectedTitle = (title) => {
    setSelectedTitle(title);
    setCurrentView("essayDetails");
  };

  const handleGenerateRubric = () => {
    if (selectedOptions?.length === 0) {
      return setClassError(" Class is Required");
    }
    if (!dueDate) {
      return setDueDateError(" Due Date is Required");
    }
    setCurrentView("assignmentSetup");
  }

  const SaveGeneratedEssay = async () => {
    const data ={
      topic:selectedTitle?.title,
      grade:filters?.Grade,
      rubricId:aigenEssayRubric?.rubric?.id || null,
      modificationPrompt:modifyPrompt || ""
  }
    saveEssayTopics(data, {
      onSuccess: (response) => {
        console.log("saveResponse", response);
        setAiGenEssayRubric(response);
        // toast.success(response?.message);
        setModifyPrompt("");
      },
      onError: (error) => {
        toast.error("Failed to approve evaluation: " + error.message, {
          position: "bottom-right"
        });
      },
    });
  };

  const AsssignTopic = async () => {
   
    const data = {
      essay_id: selectedTitle?.id || aigenEssayRubric?.essayId,
      classroom_ids: selectedOptions,
      due_date: dueDate,
      timeLimit: timeLimit,
      wordLimit: wordLimit,
      classes
    };
    navigate("/dashboard/tools/generate-sample-evaluation", {
      state: { data },
    });
  };

  const handleselectClass = (selectedOptions) => {
    if (selectedOptions.length !== 0) {
      setSelectClass(selectedOptions);
      setClassError("");
    } 
  };

  const handleDuedate = (e) => {
    const selectedDateTime = new Date(e.target.value);
    const now = new Date();

    // Compare full datetime
    if (selectedDateTime < now) {
      setDueDateError("Due date and time cannot be in the past.");
      setDueDate(""); // Clear the invalid date
      return;
    }

    // If it's today, check if time is at least 30 minutes from now
    if (selectedDateTime.toDateString() === now.toDateString()) {
      const minTimeGap = 30; // minimum minutes gap required
      const minutesDiff = (selectedDateTime - now) / (1000 * 60);

      if (minutesDiff < minTimeGap) {
        setDueDateError(
          `Due time must be at least ${minTimeGap} minutes from now.`
        );
        setDueDate("");
        return;
      }
    }

    // If all validations pass
    setDueDate(e.target.value);
    setDueDateError("");
  };

  useEffect(() => {
    if (selectedTitle) {
      SaveGeneratedEssay();
    }
  }, [selectedTitle]);

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
    if (e.target.name === "Grade") {
      setGradeError("");
    }
    if (e.target.name === "topic") {
      setTopicError("");
    }
  };
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        if(currentView === "essayDetails"){
          if(selectedOptions?.length === 0 && !dueDate){
            toast.error("Please select class and due date", {
        position: "bottom-right"
      })
            return;
          }else{
            e.preventDefault();
            handleGenerateRubric()
          }
        }
       else if (modifyPrompt) {
          e.preventDefault();
          SaveGeneratedEssay();
        } 
        else if (filters?.topic && filters?.Grade) {
          e.preventDefault();
          GenerateAiTitle();
        }else{
          toast.error("Please fill all the fields", {
            position: "bottom-right"
          })
        }
      }
    };

    // Add event listener
    document.addEventListener("keydown", handleKeyPress);

    // Cleanup
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [GenerateAiTitle ]);

  const handleBack = () => {
    switch (currentView) {
      case "titleList":
        setCurrentView("initial");
        setEssayTitles([]);
        setFilters({
          topic: "",
          Grade: "",
        });
        setSessionId("");
        break;
        case "essayDetails":
        setCurrentView("titleList");
        setSelectedTitle(null);
        break;
      case "assignmentSetup":
        setCurrentView("essayDetails");
        
        break;
      default:
        navigate(-1);
        setFilters({
          topic: "",
          Grade: "",
        });
      // Only use router navigation when at initial view
    }
  };

  useEffect(() => {
    if (selectedClass?.length !== 0) {
      console.log("selectedClass",selectedClass);
        // Assuming selectedClass is an array of objects
        const preselectedOptions = {
          id: selectedClass?.id, // Access the 'id' property
          name: selectedClass?.name // Access the 'name' property
      };
      setSelectedOptions([preselectedOptions]); 
    }else{
      setSelectedOptions([]);
    }
}, [selectedClass]);

  return (
    <>
      {(generateAiTitlePending || (saveEssay && modifyPrompt) ) && <Loader />}

      <div className="bg-gray-100 min-h-screen px-6 flex flex-col  item-center">
        <div className="flex justify-start">
          <p
            className="flex gap-2 items-center py-2 w-fit  cursor-pointer"
            onClick={handleBack}
          >
            <img src={BackIocn} alt="backicon" />
            Back
          </p>
        </div>

        <div className="bg-[#FFFFFF] p-4 w-full h-auto mb-0  rounded-lg  ">
          {currentView === "essayDetails" ? (
          <EssayDetails  
          selectClass={selectClass} 
           dueDate={dueDate}
            handleDuedate={handleDuedate}
             timeLimit={timeLimit} 
             handleTimeLimit={handleTimeLimit}
             wordLimit={wordLimit}
             handleWordLimit={handleWordLimit}
             handleGenerateRubric={handleGenerateRubric}
               classError={classError}
                dueDateError={dueDateError}
                 classes={classes}
                 handleselectClass={handleselectClass}
                 setSelectedOptions={setSelectedOptions}
                 selectedOptions={selectedOptions}
                 setClassError={setClassError}
                 />

          ) : currentView === "assignmentSetup"  ? (
              ( saveEssay && !modifyPrompt) ? (
                <Loader/>
               ):(
            <div className="flex   flex-col text-left">
              <div className="flex justify-center  w-full gap-4">
               
              </div>
              <div className="w-full mt-2">
                <RubricTable aigenEssayRubric={aigenEssayRubric} AsssignTopic={AsssignTopic} />
              </div>
              <div>
                <div className="flex gap-2 w-full pb-2">
                  <div className="flex relative w-full border border-gray-300 rounded-lg p-2 items-center justify-center">
                    <input
                      name="topic"
                      value={modifyPrompt}
                      onChange={handleModifyPrompt}
                      className="block flex-1 w-full  outline-none rounded-lg  "
                      placeholder="Tell me how to improve this rubric! 'Make it tougher or simplify levels description,add more criteria'"
                      required
                    />
                  </div>
                  <button
                    onClick={SaveGeneratedEssay}
                    className={` text-white  font-bold text-[14px] px-6 rounded-lg w-[180px] bg-blue-700`}
                  >
                    Modify Rubric
                  </button>
                  <div />
                </div>
              </div>
            </div>
          ) 
          ) : currentView === "titleList" ? (
            <div>
              {essayTitles?.length !== 0 && (
                <>
                  <div className=" w-full  pb-2">
                    <p className="text-[16px] font-semibold text-[#000]">
                      {essayTitles?.response}
                    </p>
                  </div>
                  <TitleList
                    title={essayTitles?.topics}
                    handleSelectedTitle={handleSelectedTitle}
                  />
                  <div className="flex relative border border-gray-300 rounded-lg p-2 items-center justify-center">
                    <input
                      name="topic"
                      value={filters?.topic}
                      onChange={handleFilterChange}
                      className="block flex-1  outline-none rounded-lg h-7 "
                      placeholder="Enter a keyword for new topic ideas... "
                      required
                    />
                    {filters?.Grade && (
                      <div>
                        <span className="text-[#000] flex-1 font-semibold text-[16px]">
                          {" "}
                          For Grade {filters?.Grade}
                        </span>
                      </div>
                    )}
                  </div>
                  <button
                    onClick={GenerateAiTitle}
                    className={`mt-2  text-white py-2 font-bold text-[18px] px-6 rounded-lg  ${
                      essayTitles?.length !== 0 ? "w-full" : "w-[750px]"
                    } bg-[#4f24ff]`}
                  >
                    Explore More Topics
                  </button>
                </>
              )}
            </div>
          ) : (
            <>
              <div className="flex flex-col justify-center items-center w-full  pb-10">
                <h1 className="text-[28px] font-[700]  text-[#000] ">
                  Generate Essay Topics{" "}
                </h1>
                <p className="text-[16px] font-semibold text-gray-500">
                  Generate high-quality essay topics using AI, search past
                  papers or assign your own topic
                </p>
                <SearchFilter
                  filters={filters}
                  topicError={topicError}
                  GradeError={GradeError}
                  handleFilterChange={handleFilterChange}
                />

                <div className="flex justify-between ">
                  <button
                    onClick={GenerateAiTitle}
                    className={`mt-14  text-white py-3 font-bold text-[18px] px-6 rounded-lg w-[750px] bg-[#4f24ff]`}
                  >
                    Generate Essay Topics
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default EssayGenerator;
