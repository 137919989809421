import React, { useState } from "react";
import DeleteModal from "../../../components/sharedUi/deleteModal";
import { toast } from "react-toastify";
import DeleteIcon from "../../../assets/delete.svg";
import ViewIcon from "../../../assets/view.svg";
import { truncateText } from "../../../utils/constants";
import SortingIcon from "../../../assets/sortingIcon.svg";
import { useGetStudentSubmissions,useGetEvaluation,useApproveEvaluation } from "../../../utils/api/userApi";
import { useLocation ,useNavigate} from "react-router-dom";
import AssignmentText from "./assignmentText";
import InfoIcon from "../../../assets/infoIcon.svg";
import AssignmentIcon from "../../../assets/assignment.svg"
import BackIocn from "../../../assets/arrowBackIcon.svg";
import { useClassContext } from "../../../components/context/classContext";
import EvaluationView from "./evaluationView";
import Loader from "../../../components/sharedUi/loader";
import { useTeacherEvaluationContext } from "../../../components/context/teacherEvaluationContext";
const StudentSubmissionTable = () => {
    const {state}=useLocation()
    const navigate=useNavigate()
    const TeacherId = JSON.parse(localStorage.getItem("UserData"));
    console.log("🚀 ~ StudentSubmissionTable ~ state:", state)
    const [searchValue, setSearchValue] = useState("");
    const [status, setStatus] = useState(""); 
    const [viewDetailsOpen,setViewDetailsOpen]=useState(false)
    const [viewDetails,setViewDetails]=useState()
    const {setBackTab}=useClassContext()
    const [showEvaluation, setShowEvaluation] = useState(false);
      const {
        setSubmission,
        submission,
        evaluations,
        setEvaluations,
        EditEvaluation,
        setEditEvaluation,
        handleApproveEvaluation,
        handleEditAiEvaluation,
        handleEvaluationChange,
        fetchEvaluation,
        approveEvaluationPending,
        isPending
      }=useTeacherEvaluationContext()
const {data:studentSubmission}=useGetStudentSubmissions({
 Tid: TeacherId?.userId,
  Sid: state?.student_id,
  search:searchValue,
  status:status

})
console.log("🚀 ~ StudentSubmissionTable ~ studentSubmission:", studentSubmission)

const handleStatusChange = (e) => {
  setStatus(e.target.value);
};
const handleSearch = (e) => {
  const value = e.target.value;
  setSearchValue(value);
};
    
  const [sortConfig, setSortConfig] = useState({
    key: "title",
    direction: "ascending",
  });
  const [page, setPage] = useState(1);

  const sortedAssignments = React.useMemo(() => {
    let sortableAssignments = [...(studentSubmission?.submissions|| [])];
    if (sortConfig !== null) {
      sortableAssignments.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableAssignments;
  }, [studentSubmission, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const totalPages = studentSubmission?.pagination?.totalPages || 1;

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const leftOffset = Math.max(page - Math.floor(maxVisiblePages / 2), 1);
      const rightOffset = Math.min(
        leftOffset + maxVisiblePages - 1,
        totalPages
      );

      if (leftOffset > 1) pageNumbers.push(1, "...");
      for (let i = leftOffset; i <= rightOffset; i++) {
        pageNumbers.push(i);
      }
      if (rightOffset < totalPages) pageNumbers.push("...", totalPages);
    }

    return pageNumbers;
  };
  const handleNavigate=(submission)=>{
    // navigate('/dashboard/assignments/evaluaton',{state:assignment})
    setShowEvaluation(true)
    setSubmission(submission)
   
  }

  const handleViewDetails=(submission)=>{
   
    setViewDetails(submission)
    setViewDetailsOpen(true)
  }
  const handleViewDetailsClose=()=>{
    setViewDetailsOpen(false)
  }
  const handleBack=()=>{
    setBackTab(true)
    navigate(-1)
  }
  return (
    <>
    {isPending && <Loader/>}
    <div className="p-4 ">
      <div className="flex justify-between items-center ">
      <p
          className="flex gap-2 items-center py-2  cursor-pointer"
          onClick={handleBack}
        >
          <img src={BackIocn} alt="backicon" />
          Back
        </p>
        <div className="flex justify-end items-center py-2 gap-2">
              <label for="table-search" class="sr-only">
                Search
              </label>
              <div class="relative ">
                <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    class="w-4 h-4 text-gray-500 "
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  value={searchValue}
                  onChange={handleSearch}
                  type="text"
                  id="table-search"
                  class="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                  placeholder="Search By Name"
                />
              </div>
              <select
                value={status}
                onChange={handleStatusChange}
                className="w-40 p-2 text-sm bg-gray-50  text-[#000] border border-gray-300 rounded-lg"
              >
                <option value="" disabled>
                  Select status
                </option>
                <option value="Submitted">Submitted</option>
                <option value="Graded">Graded</option>
                <option value="Evaluation Failed">Evaluation Failed</option>
                <option value="Not Started">Not Started</option>
                <option value="In Progress">In Progress</option>
                
              </select>
              </div>
            </div>
    
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
          <th scope="col" className="  px-6 py-3">
              <div className="flex gap-2">
                Student Name{" "}
                <img
                  src={SortingIcon}
                  onClick={() => requestSort("name")}
                  className="w-4 cursor-pointer"
                />
              </div>
            </th>
            <th scope="col" className="  px-6 py-3">
              <div className="flex gap-2">
                Title{" "}
                <img
                  src={SortingIcon}
                  onClick={() => requestSort("title")}
                  className="w-4 cursor-pointer"
                />
              </div>
            </th>
            <th scope="col" className=" px-6 py-3">
              <div className="flex gap-2">
                Class Name{" "}
                <img
                  src={SortingIcon}
                  onClick={() => requestSort("classroom_name")}
                  className="w-4 cursor-pointer"
                />
              </div>
            </th>
            <th scope="col" className="  px-6 py-3">
              <div className="flex gap-2">
                Due Date{" "}
                <img
                  src={SortingIcon}
                  onClick={() => requestSort("due_date")}
                  className="w-4 cursor-pointer"
                />
              </div>
            </th>
            <th scope="col" className="   px-6 py-3">
              <div className="flex gap-2">
                Status{" "}
                <img
                  src={SortingIcon}
                  onClick={() => requestSort("status")}
                  className="w-4 cursor-pointer"
                />
              </div>
            </th>
            <th scope="col" className="   px-6 py-3">
              <div className="flex gap-2">
                Ai Score{" "}
                <img
                  src={SortingIcon}
                  onClick={() => requestSort("score")}
                  className="w-4 cursor-pointer"
                />
              </div>
            </th>
            <th scope="col" className="px-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {studentSubmission?.message !==
          "No classes found for this teacher" ? (
            sortedAssignments?.map((assignment, index) => (
              <tr
                key={index}
              onClick={()=>handleNavigate(assignment)}

                class="bg-white border-b  hover:bg-gray-50 "
              >
                 <td class="px-6 py-2">
                  {assignment?.student_name}
                </td>
                <td class="px-6 py-2">
                  {assignment?.essay_title}
                </td>
                <th class="px-6 py-2">{assignment?.classroom_name}</th>
                <td class="px-6 py-2">
                  {new Date(assignment?.due_date).toLocaleString()}
                </td>

                <td class="px-6 py-2">{assignment?.status}</td>
                <td class="px-6 py-2">
                    {assignment?.ai_score}
                </td>
                <td className="px-6 py-2 flex gap-2 items-center ">
                  {/* <div className="relative group">
                    <img
                      src={ViewIcon}
                      alt="view"
                      onClick={() => handleNavigate(assignment)}
                      className="w-4 cursor-pointer"
                    />
                    <span className="absolute  transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      View Evaluation
                    </span>
                  </div> */}
                  <div className="relative group">
                  <img onClick={(e)=>handleViewDetails(e,assignment)} src={AssignmentIcon} alt='Edit' className="w-4 cursor-pointer"/>
                  <span className="absolute  left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    Assignment Details
                  </span>
                </div>
                  {/* <div className="relative group">
                    <img
                      src={DeleteIcon}
                      alt="Delete"
                      className="w-3 cursor-pointer"
                    />
                    <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      Delete
                    </span>
                  </div> */}
                </td>
              </tr>
            ))
          ) : (
            <tr className="w-full h-full  flex justify-center items-center">
              <td class="px-6 py-4">
                <h1 className="text-[12px] font-[500]">No Classes found </h1>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="flex w-full justify-end item-center p-2">
        <nav
          className="isolate inline-flex -space-x-px rounded-md shadow-sm"
          aria-label="Pagination"
        >
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <span className="sr-only">Previous</span>
            <svg
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                clipRule="evenodd"
              />
            </svg>
          </button>

          {getPageNumbers().map((number, index) =>
            number === "..." ? (
              <span
                key={index}
                className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
              >
                ...
              </span>
            ) : (
              <button
                key={index}
                onClick={() => handlePageChange(number)}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                  page === number
                    ? "z-10 bg-[#1da1f2] text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  "
                    : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                }`}
              >
                {number}
              </button>
            )
          )}

          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={page === totalPages}
            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <span className="sr-only">Next</span>
            <svg
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </nav>
      </div>
    </div>
    </div>
    <AssignmentText viewDetails={viewDetails} viewDetailsOpen={viewDetailsOpen} handleViewDetailsClose={handleViewDetailsClose}/>
    {showEvaluation && <EvaluationView
     evaluations={evaluations} 
     evaluation={submission} 
     EditEvaluation={EditEvaluation}
     setShowEvaluation={setShowEvaluation}
     handleApproveEvaluation={handleApproveEvaluation}
     handleEditAiEvaluation={handleEditAiEvaluation}
     handleEvaluationChange={handleEvaluationChange}
    fetchEvaluation={fetchEvaluation}
     
     />}
    </>
  );
};

export default StudentSubmissionTable;
