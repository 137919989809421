import React, { useState } from 'react';
import { useComprehensionContext } from '../../../components/context/comprehensionContext';
import { toast } from 'react-toastify';
import BackIcon from '../../../assets/arrowBackIcon.svg';
import { useNavigate } from 'react-router-dom';
import { useUpdateComQuestion } from '../../../utils/api/compreApi';
import Loader from '../../../components/sharedUi/loader';
const QuestionDisplay = () => {
  const navigate = useNavigate();
  const { questions, updateQuestions } = useComprehensionContext();
  console.log("questionsss",questions);
  const [expandedType, setExpandedType] = useState(null);
  const [expandedQuestion, setExpandedQuestion] = useState(null);
  const [editMode, setEditMode] = useState({});
  const { mutate: updateQuestion  ,isPending:isUpdating} = useUpdateComQuestion();

  // Handle field changes
  const handleQuestionChange = (typeId, questionId, field, value) => {
    const updatedQuestions = questions.map(type => {
      if (type.typeId === typeId) {
        const updatedQuestions = type.questions.map(q => {
          if (q.id === questionId) {
            return { ...q, [field]: value };
          }
          return q;
        });
        return { ...type, questions: updatedQuestions };
      }
      return type;
    });
    updateQuestions(updatedQuestions);
  };

  // Handle option changes for MCQ/True-False
  const handleOptionChange = (typeId, questionId, optionIndex, value) => {
    const updatedQuestions = questions.map(type => {
      if (type.typeId === typeId) {
        const updatedQuestions = type.questions.map(q => {
          if (q.id === questionId) {
            const newOptions = [...q.options];
            newOptions[optionIndex] = value;
            return { ...q, options: newOptions };
          }
          return q;
        });
        return { ...type, questions: updatedQuestions };
      }
      return type;
    });
    updateQuestions(updatedQuestions);
  };

  // Toggle edit mode for a question
  const toggleEditMode = (questionId) => {
    setEditMode(prev => ({
      ...prev,
      [questionId]: !prev[questionId]
    }));
  };

  const handleSave = (questionId) => {
    toggleEditMode(questionId);
    toast.success('Changes saved successfully!',{position:"bottom-right"});
  };
  const handleBack = () => {
    navigate(-1);
  }

  const transformQuestionsToPayload = (questionsData) => {
    try {
      // Validate input
      if (!Array.isArray(questionsData)) {
        throw new Error('Questions data must be an array');
      }
  
      const questions = [];
  
      questionsData.forEach(type => {
        if (!Array.isArray(type.questions)) {
          throw new Error('Type questions must be an array');
        }
  
        type.questions.forEach(q => {
          // Validate required fields
          if (!q.id || !q.question || !q.answer || q.marks === undefined) {
            throw new Error('Question missing required fields');
          }
  
          // Validate marks is a number and positive
          const marks = parseInt(q.marks);
          if (isNaN(marks) || marks < 0) {
            throw new Error(`Invalid marks value for question ${q.id}`);
          }
  
          const questionObj = {
            id: parseInt(q.id),
            question: q.question.trim(),
            answer: q.answer,
            marks: marks,
            explanation: q.explanation || "",
            line_reference: q.line_reference || null
          };
  
          // Handle MCQ type questions
          if (Array.isArray(q.options) && q.options.length > 0) {
            questionObj.options = q.options;
          }
  
          // Handle gap filling type questions
          if (Array.isArray(q.answer)) {
            questionObj.gaps = q.gaps || [];
          }
  
          questions.push(questionObj);
        });
      });
  
      return { questions };
    } catch (error) {
      console.error('Error transforming questions:', error);
      throw error;
    }
  };

  const handleApproveQuestion = () => {
    const payload = transformQuestionsToPayload(questions);
    console.log("payload",payload);
    updateQuestion(payload,{
      onSuccess:(response)=>{
        console.log("response",response);
        if(response?.success){

    navigate('/dashboard/comprehension/sample-evaluation',{state:{questions:questions}});
          
        }
      },
      onError:(error)=>{
        toast.error(error?.response?.data?.message || "Something went wrong",{position:"bottom-right"});
      } 
    });

  }

  return (
    <>
    {isUpdating && <Loader/>}
    <div className='bg-gray-100 min-h-screen px-4 w-full flex flex-col pt-2'>
    <div className='  flex justify-between items-center'>
    <div className="flex items-center gap-2 mb-6">
        <img 
          src={BackIcon} 
          alt="back" 
          className="cursor-pointer" 
          onClick={handleBack} 
        />
        <h2 className="text-lg font-bold">Back</h2>
      </div>
      <button className='bg-blue-500 text-white px-4 py-2 rounded-lg' onClick={handleApproveQuestion}>
        Approve Questions
      </button>
    </div>
    <div className="w-full mx-auto pt-2 space-y-6">
        {questions?.map((type) => (
          <div key={type.typeId} className="bg-white rounded-xl shadow-md overflow-hidden">
            {/* Type Header */}
            <div 
              onClick={() => setExpandedType(expandedType === type.typeId ? null : type.typeId)}
              className={`cursor-pointer p-4 ${
                expandedType === type.typeId ? 'bg-blue-600 text-white' : 'bg-blue-50'
              }`}
            >
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold">{type.type}</h2>
                <div className="flex items-center gap-3">
                  <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
                    {type.questions.length} Questions
                  </span>
                  <svg 
                    className={`w-6 h-6 transition-transform ${expandedType === type.typeId ? 'rotate-180' : ''}`} 
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>
            </div>

            {/* Questions List */}
            {expandedType === type.typeId && (
              <div className="divide-y divide-gray-200">
                {type.questions.map((question) => (
                  <div key={question.id} className="p-4">
                    <div className="flex justify-between gap-2 items-center mb-4">
                      <div className="flex-1">
                        {editMode[question.id] ? (
                          <input
                            type="text"
                            value={question.question}
                            onChange={(e) => handleQuestionChange(type.typeId, question.id, 'question', e.target.value)}
                            className="w-full p-2 border rounded"
                          />
                        ) : (
                          <p className="font-medium text-gray-900">
                            Q{question.id}. {question.question}
                          </p>
                        )}
                      </div>
                      <button
                        onClick={() => editMode[question.id] ? handleSave(question.id) : toggleEditMode(question.id)}
                        className={`px-3 py-1 rounded-full text-sm ${
                          editMode[question.id] ? 'bg-green-500 text-white' : 'bg-blue-500 text-white'
                        }`}
                      >
                        {editMode[question.id] ? 'Save' : 'Edit'}
                      </button>
                    </div>

                    {/* Marks and Reference */}
                    {editMode[question.id] && (
                      <div className="grid grid-cols-2 gap-4 mb-4">
                        <div>
                          <label className="text-sm text-gray-600">Marks:</label>
                          <input
                            type="number"
                            value={question.marks}
                            onChange={(e) => handleQuestionChange(type.typeId, question.id, 'marks', e.target.value)}
                            className="w-full p-2 border rounded"
                          />
                        </div>
                        <div>
                          <label className="text-sm text-gray-600">Reference:</label>
                          <input
                            type="text"
                            value={question.line_reference}
                            onChange={(e) => handleQuestionChange(type.typeId, question.id, 'line_reference', e.target.value)}
                            className="w-full p-2 border rounded"
                          />
                        </div>
                      </div>
                    )}

                    {/* Answer and Details - Always visible when type is expanded */}
                    <div className="mt-4 pl-4 border-l-4 border-green-400">
                      {/* Answer */}
                      <div className="mb-3">
                        <h4 className="text-sm font-semibold text-gray-700 mb-1">Answer:</h4>
                        {editMode[question.id] ? (
                          <input
                            type="text"
                            value={question.answer}
                            onChange={(e) => handleQuestionChange(type.typeId, question.id, 'answer', e.target.value)}
                            className="w-full p-2 border rounded"
                          />
                        ) : (
                          <p className="text-gray-800">{question.answer}</p>
                        )}
                      </div>

                      {/* MCQ Options */}
                      {question.options && question.options.length > 0 && (
                        <div className="mb-3">
                          <h4 className="text-sm font-semibold text-gray-700 mb-1">Options:</h4>
                          <div className="grid grid-cols-2 gap-2">
                            {question.options.map((option, index) => (
                              <div key={index} className="relative">
                                {editMode[question.id] ? (
                                  <input
                                    type="text"
                                    value={option}
                                    onChange={(e) => handleOptionChange(type.typeId, question.id, index, e.target.value)}
                                    className={`w-full p-2 rounded ${
                                      option === question.answer ? 'border-green-300 bg-green-50' : 'border-gray-200'
                                    }`}
                                  />
                                ) : (
                                  <div className={`p-2 rounded ${
                                    option === question.answer ? 'bg-green-100 border border-green-300' : 'bg-gray-50 border border-gray-200'
                                  }`}>
                                    {option}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {/* Explanation */}
                      <div className="mb-2">
                        <h4 className="text-sm font-semibold text-gray-700 mb-1">Explanation:</h4>
                        {editMode[question.id] ? (
                          <textarea
                            value={question.explanation}
                            onChange={(e) => handleQuestionChange(type.typeId, question.id, 'explanation', e.target.value)}
                            className="w-full p-2 border rounded"
                            rows="3"
                          />
                        ) : (
                          <p className="text-gray-600 text-sm">{question.explanation}</p>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default QuestionDisplay;