
import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { BookOpen, Clock, FileCheck, GraduationCap, Users, CheckCircle, AlertCircle, HourglassIcon } from 'lucide-react';
import StatCard from './statsCard';
import { useGetTeacherStats } from "../../utils/api/userApi";
import Loader from "../../components/sharedUi/loader";


const COLORS = ['#10B981', '#F59E0B', '#3B82F6', '#EC4899', '#8B5CF6'];
const RADIAN = Math.PI / 180;


const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, value, name, index }) => {
  const radius = outerRadius * 1.2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const lineX = cx + (outerRadius + 10) * Math.cos(-midAngle * RADIAN);
  const lineY = cy + (outerRadius + 10) * Math.sin(-midAngle * RADIAN);
  const textAnchor = x > cx ? 'start' : 'end';
  
  return (
    <g>
      <path
        d={`M ${cx + outerRadius * Math.cos(-midAngle * RADIAN)},${
          cy + outerRadius * Math.sin(-midAngle * RADIAN)
        }L ${lineX},${lineY}L ${x},${y}`}
        stroke="#333"
        fill="none"
      />
      <circle cx={lineX} cy={lineY} r="2" fill="#333" />
      <text
        x={x}
        y={y}
        textAnchor={textAnchor}
        fill="#333"
        dominantBaseline="central"
        className="text-sm font-medium"
      >
        {`${value} (${(percent * 100).toFixed(1)}%)`}
      </text>
    </g>
  );
};

function TeacherDashboard() {

    const user=JSON.parse(localStorage.getItem("UserData"));
  const {data:teacherStats ,isPending}=useGetTeacherStats(user?.userId)
  console.log("🚀 ~ teacherDashboard ~ data:", teacherStats)

  

  const classStatsCards = [
    { name: 'Total Classes', value: teacherStats?.classStats?.totalClasses, icon: GraduationCap },
    { name: 'Total Students', value: teacherStats?.classStats?.totalStudents, icon: Users },
  ];

  const assignmentStatusCards = [
    { name: 'Assigned', value: teacherStats?.assignmentStats?.assigned, icon: BookOpen ,color: COLORS[0] },
    { name: 'In Review', value: teacherStats?.assignmentStats?.inReview, icon: Clock ,color: COLORS[1] },
    { name: 'Overdue', value: teacherStats?.assignmentStats?.overdue, icon: AlertCircle ,color: COLORS[2] },
    { name: 'Completed', value: teacherStats?.assignmentStats?.completed, icon: CheckCircle ,color: COLORS[3] },
  ];

  const submissionStatusCards = [
    { name: 'Not Started', value: teacherStats?.submissionStats?.notStarted, icon: Clock },
    { name: 'In Progress', value: teacherStats?.submissionStats?.inProgress, icon: HourglassIcon },
    { name: 'Graded', value: teacherStats?.submissionStats?.graded, icon: FileCheck },
  ];

  const assignmentPieData = assignmentStatusCards
    .map(card => ({
      name: card.name,
      value: parseInt(card.value),
      color: card.color
    }))
    .filter(item => item.value );

  const submissionPieData = [
    { name: 'Not Started', value: parseInt(teacherStats?.submissionStats?.notStarted) },
    { name: 'In Progress', value: parseInt(teacherStats?.submissionStats?.inProgress) },
    { name: 'Awaiting AI', value: parseInt(teacherStats?.submissionStats?.awaitingAI) },
    { name: 'Late Awaiting AI', value: parseInt(teacherStats?.submissionStats?.lateAwaitingAI) },
    { name: 'Awaiting Teacher', value: parseInt(teacherStats?.submissionStats?.awaitingTeacher) },
    { name: 'Late Awaiting Teacher', value: parseInt(teacherStats?.submissionStats?.lateAwaitingTeacher) },
    { name: 'Graded', value: parseInt(teacherStats?.submissionStats?.graded) },
  ].filter(item => item?.value > 0);

  // const allAssignmentStates = assignmentStatusCards.map((stat, index) => ({
  //   ...stat,
  //   color: COLORS[index % COLORS.length]
  // }));

  // const allSubmissionStates = [
  //   { name: 'Not Started', value: stats.submissionStats.notStarted },
  //   { name: 'In Progress', value: stats.submissionStats.inProgress },
  //   { name: 'Awaiting AI', value: stats.submissionStats.awaitingAI },
  //   { name: 'Late Awaiting AI', value: stats.submissionStats.lateAwaitingAI },
  //   { name: 'Awaiting Teacher', value: stats.submissionStats.awaitingTeacher },
  //   { name: 'Late Awaiting Teacher', value: stats.submissionStats.lateAwaitingTeacher },
  //   { name: 'Graded', value: stats.submissionStats.graded },
  // ].map((stat, index) => ({
  //   ...stat,
  //   color: COLORS[index % COLORS.length]
  // }));

  return (
    <>
    {isPending && <Loader/>}
    <div className='p-2'>
    {/* <div className="bg-white p-6 rounded-xl shadow-lg border border-gray-200"> */}
    <div className="flex flex-col p-5 gap-4">
      {/* Class Stats Cards */}
      <div className="grid grid-cols-2 gap-4">
        {classStatsCards.map((stat, index) => (
          <StatCard
            key={stat.name}
            title={stat.name}
            value={stat.value}
            icon={stat.icon}
            color={COLORS[index % COLORS.length]}
            bgColor={`${COLORS[index % COLORS.length]}15`}
          />
        ))}
      </div>

      {/* Stats Container */}
      <div className="grid md:grid-cols-1 lg:grid-cols-1 gap-4">
        {/* Assignment Stats */}
        <div className="bg-white p-6 rounded-xl shadow-lg border border-gray-200">
          <h2 className="text-xl font-bold mb-4">Assignment Stats</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
            {assignmentStatusCards.map((stat, index) => (
              <StatCard
                key={stat.name}
                title={stat.name}
                value={stat.value}
                icon={stat.icon}
                color={stat.color}
                bgColor={`${stat.color}15`}
              />
            ))}
          </div>
          <div className="h-[350px]">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={assignmentPieData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                  labelLine={false}
                  label={renderCustomizedLabel}
                >
                  {assignmentPieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
         
        </div>

        {/* Submission Stats */}
        <div className="bg-white p-6 rounded-xl shadow-lg border border-gray-200">
          <h2 className="text-xl font-bold mb-4">Submission Stats</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-6">
            {submissionStatusCards.map((stat, index) => (
              <StatCard
                key={stat.name}
                title={stat.name}
                value={stat.value}
                icon={stat.icon}
                color={COLORS[index % COLORS.length]}
                bgColor={`${COLORS[index % COLORS.length]}15`}
              />
            ))}
          </div>
          <div className="h-[350px]">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={submissionPieData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                  labelLine={false}
                  label={renderCustomizedLabel}
                >
                  {submissionPieData.map((_, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
         
        </div>
      </div>
    </div>
  </div>
  {/* </div> */}
  </>
  );
}

export default TeacherDashboard;




















// import React from 'react'
// import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid} from 'recharts';
// import { useGetTeacherStats } from "../../utils/api/userApi";
// import Loader from "../../components/sharedUi/loader";
// import { BookOpen, Clock, FileCheck, GraduationCap, Users } from 'lucide-react';

// const COLORS = ['#10B981', '#F59E0B', '#3B82F6', '#EC4899', '#8B5CF6'];
// const RADIAN = Math.PI / 180;
// const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, value, name, index }) => {
//   const radius = outerRadius * 1.2;
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
//   // Calculate the position for the line's end point
//   const lineX = cx + (outerRadius + 10) * Math.cos(-midAngle * RADIAN);
//   const lineY = cy + (outerRadius + 10) * Math.sin(-midAngle * RADIAN);
  
//   // Determine text anchor based on which side of the pie the label is on
//   const textAnchor = x > cx ? 'start' : 'end';
  
//   return (
//     <g>
//       {/* Line from pie to label */}
//       <path
//         d={`M ${cx + outerRadius * Math.cos(-midAngle * RADIAN)},${
//           cy + outerRadius * Math.sin(-midAngle * RADIAN)
//         }L ${lineX},${lineY}L ${x},${y}`}
//         stroke={COLORS[index % COLORS.length]}
//         fill="none"
//       />
//       {/* Circle at the bend point */}
//       <circle cx={lineX} cy={lineY} r="2" fill={COLORS[index % COLORS.length]} />
      
//       {/* Label text */}
//       <text
//         x={x}
//         y={y}
//         textAnchor={textAnchor}
//         fill={COLORS[index % COLORS.length]}
//         dominantBaseline="central"
//         className="text-sm font-medium"
//       >
//         {`${value} (${(percent * 100).toFixed(1)}%)`}
//       </text>
//     </g>
//   );
// };

// const Dashboard = () => {

//   const user=JSON.parse(localStorage.getItem("UserData"));
//   const {data:teacherStats ,isPending}=useGetTeacherStats(user?.userId)
//   console.log("🚀 ~ teacherDashboard ~ data:", teacherStats)
  
//   const chartData = [
//     { name: 'Submitted Assignments', value: parseInt(teacherStats?.totalSubmissions ) ,image:BookOpen},
//     { name: 'Pending  Evaluations', value: parseInt(teacherStats?.pendingEvaluations ) ,image:FileCheck },
//     { name: 'Active Assignment', value: parseInt(teacherStats?.activeAssignments ) ,image:Clock },
//     { name: 'Classes', value: parseInt(teacherStats?.totalClasses ) ,image:GraduationCap },
//     { name: 'Total Students', value: parseInt(teacherStats?.totalStudents ),image:Users },
//   ];

//   const PiechartData = [
//     { name: 'Submitted Assignments', value: parseInt(teacherStats?.totalSubmissions ) },
//     { name: 'Pending  Evaluations', value: parseInt(teacherStats?.pendingEvaluations ) },
//     { name: 'Active Assignment', value: parseInt(teacherStats?.activeAssignments )  },
    
//   ];
//   // const total = chartData?.reduce((sum, item) => sum + item.value, 0);

 
//   return (
//     <>
//     {isPending && <Loader/>}
//     <div className="flex flex-col p-5 gap-4">
    
//     <div className="bg-white p-6 rounded-xl shadow-lg border border-gray-200">
//        <h2 className="text-xl font-bold mb-4">Teacher Assignment Stats</h2>

      
       
//        <div className="grid grid-cols-2 md:grid-cols-5 gap-4 mb-6  ">
//          {chartData?.map((item, index) => (
//           <div className='flex items-center justify-between p-4 rounded-lg'style={{ backgroundColor: `${COLORS[index % COLORS.length]}15` }}>
//            <div 
//              key={item.name} 
//              className=" rounded-lg"
             
//            >
//              <p className="text-[14px] font-bold  text-gray-600">{item.name}</p>
//              <p className="text-[20px] font-bold" style={{ color: COLORS[index % COLORS.length] }}>
//                {item.value || 0}
//              </p>
//              {/* <p className="text-xs text-gray-500">
//                {((item.value / total) * 100).toFixed(1)}%
//              </p> */}
//            </div>
//            <item.image 
//                 className="w-10 h-10"
//                 style={{ color: COLORS[index % COLORS.length] }}
//               />
//            </div>
//          ))}
//        </div>
 
//        <div className="h-[350px]"> {/* Increased height to accommodate labels */}
//          <ResponsiveContainer width="100%" height="100%">
//            <PieChart>
//              <Pie
//                data={PiechartData}
//                cx="50%"
//                cy="50%"
//                innerRadius={60}
//                outerRadius={80}
//                fill="#8884d8"
//                paddingAngle={5}
//                dataKey="value"
//                labelLine={false}
//                label={renderCustomizedLabel}
//              >
//                {PiechartData?.map((_, index) => (
//                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                ))}
//              </Pie>
//              <Tooltip />
//              <Legend />
//            </PieChart>
//          </ResponsiveContainer>
//        </div>
//      </div>
 
 
    
 
//     </div>
//     </>
//   )
// }

// export default Dashboard

