import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../../assets/arrowBackIcon.svg";
import { useComprehensionContext } from "../../../components/context/comprehensionContext";
import Loader from "../../../components/sharedUi/loader";
import { useClassContext } from "../../../components/context/classContext";
import { toast } from "react-toastify";
const PassageReview = () => {
  const navigate = useNavigate();
  const {
    uploadedFiles,
    updateComprehensionData,
    selectedTopic,
    wordLength,
    passage,
    passageContent,
    ModifyPassPrompt,
    handleGeneratePassage,
    generateComPassagePending,
    setSelectedOptions,
  } = useComprehensionContext();
  const { selectedClass } = useClassContext();
  console.log("selectedClass",selectedClass);
  console.log("passagesss", passage);
  console.log("passageContent", passageContent);

  console.log("selectedTopic", selectedTopic);

  console.log("wordLength", wordLength);

  const handleSave = () => {
    if (!passageContent.trim()) {
      alert("Please ensure there is content in the passage before proceeding.");
      return;
    }
    navigate("/dashboard/comprehension/assignment-data");
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (selectedClass?.length !== 0) {
      console.log("selectedClass",selectedClass);
        // Assuming selectedClass is an array of objects
        const preselectedOptions = {
          id: selectedClass?.id, // Access the 'id' property
          name: selectedClass?.name // Access the 'name' property
      };
      setSelectedOptions([preselectedOptions]); 
    }else{
      setSelectedOptions([]);
    }
}, [selectedClass]);

useEffect(() => {
  const handleKeyPress = (e) => {
      if (e.key === "Enter") {
          e.preventDefault();
          // Check topic only
          if (!ModifyPassPrompt) {
              
              return toast.error("Please enter a prompt", {
                position: "bottom-right"
              })
          }
          if (ModifyPassPrompt ) {
            handleGeneratePassage();
          }
      }
  };

  // Add event listener
  document.addEventListener("keydown", handleKeyPress);

  // Cleanup
  return () => {
      document.removeEventListener("keydown", handleKeyPress);
  };
}, [ModifyPassPrompt, handleGeneratePassage]);



  return (
    <>
      {generateComPassagePending && <Loader />}
      <div className="bg-gray-100 min-h-screen px-6 flex flex-col pt-1">
        <div className="flex justify-start">
          <p
            className="flex gap-2 items-center py-2 w-fit cursor-pointer"
            onClick={handleBack}
          >
            <img src={BackIcon} alt="backicon" />
            Back
          </p>
        </div>

        <div className="bg-white p-4 w-full flex flex-col items-center justify-center h-auto mb-0 rounded-lg">
          <h2 className="text-2xl font-bold">Review and Edit Passage</h2>
          <p className="text-gray-600 mb-4">
            Edit the passage using an intuitive chat-style editor.
          </p>

          {/* File Info */}
          <div className={`w-full mb-4 p-2 bg-blue-50 rounded-lg flex ${uploadedFiles? 'justify-between' :'justify-end'} items-center`}>
            {uploadedFiles && (
              <p className="text-sm text-blue-600">
                File: {uploadedFiles} 
              </p>
            )}

            <button
              onClick={handleSave}
              className="bg-blue-700  text-white px-6 py-2 rounded-lg"
              // disabled={!passage.trim()}
            >
              Generate Questions
            </button>
          </div>
          {/* Topic and Word Length Info */}
          {selectedTopic?.title && (
            <div className="w-full mb-4">
              <p className="text-sm font-medium">
                Topic:{" "}
                <span className="text-blue-600">{selectedTopic?.title}</span>
              </p>
              {wordLength && (
                <p className="text-sm font-medium">
                  Target Length:{" "}
                  <span className="text-blue-600">{wordLength} words</span>
                </p>
              )}
            </div>
          )}

          {/* Passage Display */}
          <div className="mt-1 w-full">
            <h3 className="text-lg font-semibold">Passage:</h3>

            <textarea
              value={passageContent}
              onChange={(e) =>
                updateComprehensionData({ passageContent: e.target.value })
              }
              className="w-full h-[21rem] p-2 border border-gray-300 rounded-md"
              placeholder="Your passage will be displayed here..."
            />
          </div>

          {/* Command Input */}
          <div className="flex w-full pt-2 pb-4">
            <div className="flex relative w-full border border-gray-300 rounded-lg p-2 items-center justify-center">
              <input
                value={ModifyPassPrompt}
                onChange={(e) =>
                  updateComprehensionData({ ModifyPassPrompt: e.target.value })
                }
                className="block flex-1 w-full outline-none rounded-lg"
                placeholder="e.g., Make the tone simpler"
                // onKeyPress={(e) => e.key === 'Enter' && handleCommandSubmit(e)}
              />
            </div>
            <button
              onClick={handleGeneratePassage}
              className="text-white font-bold text-[14px] px-2 ml-2 rounded-lg w-[150px] bg-blue-700 disabled:bg-blue-300"
              // disabled={!command.trim()}
            >
              Modify Passage
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PassageReview;
