
import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClassProvider } from "./components/context/classContext";
import { TimerProvider } from "./components/context/timerContext";
import { NotificationProvider } from "./components/context/notificationContext";
import { TeacherEvaluationProvider } from "./components/context/teacherEvaluationContext";
import { ComprehensionProvider } from "./components/context/comprehensionContext";
// import { Toaster } from "react-hot-toast";
const  App=()=> {
  return (
    <>
    
    <BrowserRouter>
    <NotificationProvider>
      <TeacherEvaluationProvider>
    <ClassProvider>
      <ComprehensionProvider>
      <TimerProvider>
      <AppRoutes />
      </TimerProvider>
      </ComprehensionProvider>
      </ClassProvider>
      </TeacherEvaluationProvider>
      </NotificationProvider>
      
    <ToastContainer/>
      
    </BrowserRouter>
    </>
  );
}

export default App;
