import React , {useEffect} from 'react'
import FormValidationError from '../../../components/sharedUi/FormValidatorError';
// import { MultiSelect } from "react-multi-select-component";
import MultiSelect from './multiselect'
const EssayDetails = ({setSelectedOptions,selectedOptions, setClassError, selectClass,handleselectClass, wordLimit,handleWordLimit,dueDate,handleDuedate,timeLimit,handleTimeLimit,handleGenerateRubric,classError,dueDateError,classes}) => {
 console.log("classes",classes);
 const options = classes && classes?.classrooms?.map((cls) => ({
  id: cls.id, // Assuming each classroom object has an 'id' property
  name: cls.name // Assuming each classroom object has a 'name' property
})) ;


    return (
    <div>
        <div className="bg-[#FFFFFF] p-4 w-full h-auto mb-0  rounded-lg  ">
            <div className="flex justify-center items-center">
                <h1 className="text-2xl font-bold text-gray-700">Assignment Parameters</h1>
            </div>
            <div className="flex   flex-col text-left">
              <div className="flex flex-col justify-center  w-full gap-4 ">
                <label className="flex-1">
                  <span className="text-gray-700">Select Class:</span>
                  <MultiSelect
                  setSelectedOptions={setSelectedOptions}
                  selectedOptions={selectedOptions}
                  options={options}
                  setClassError={setClassError}
                  
                  />
                  {/* <select
                    value={selectClass}
                    onChange={handleSelectClass}
                    className="block w-full mt-1 p-2 border border-gray-300 rounded-md"
                  >
                    <option value="">Select Class</option>
                    {classes?.classrooms?.map((classroom) => (
                      <option value={classroom?.id}>{classroom?.name}</option>
                    ))}
                  </select> */}
                  {/* <MultiSelect
          options={options }
          value={selectClass}
          onChange={handleselectClass}
          labelledBy="Select"
          className="z-[999]"
          
        /> */}
        
                  {classError && <FormValidationError errors={classError} />}
                </label>
                <div className="flex-1 flex flex-col ">
                  <label className="flex-1 ">
                    <span className="text-gray-700">Due Date</span>

                    <div className="relative cursor-pointer">
                      <>
                        <input
                          type="datetime-local"
                          value={dueDate}
                          onChange={handleDuedate}
                         
                          className={`w-full mt-1 p-2 border border-gray-300 focus:outline-none cursor-pointer rounded-md ${
                            dueDate ? "text-[#000000]" : "opacity-0"
                          }`}
                          onClick={(e) => {
                            e.target.showPicker(); 
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                              e.preventDefault();
                              e.target.showPicker();
                            }
                          }}
                        />
                        <div
                          className={`absolute w-full ${
                            dueDate ? "border-none" : "border border-gray-300"
                          } rounded-lg p-2  top-1/2 transform -translate-y-1/2 pointer-events-none ${
                            dueDate ? "text-gray-400" : "text-gray-700"
                          }`}
                        >
                          {!dueDate && "Set date and time"}
                        </div>
                      </>
                    </div>
                  {dueDateError && (
                    <FormValidationError errors={dueDateError} />
                  )}
                  </label>
                </div>
           
                <label className="flex-1">
                  <span className="text-gray-700">Time Limit (min)</span>
                  <input
                    type="text"
                    value={timeLimit}
                    onChange={handleTimeLimit}
                    className="block w-full mt-1 p-2 border border-gray-300 rounded-md"
                    placeholder="Enter Time Limit (10min)"
                  />
                </label>
                <label className="flex-1">
                  <span className="text-gray-700">Word Limit</span>
                  <input
                    type="text"
                    value={wordLimit}
                    onChange={handleWordLimit }
                    className="block w-full mt-1 p-2 border border-gray-300 rounded-md"
                    placeholder="Enter Word Limit "
                  />
                </label>

                <button
                  onClick={handleGenerateRubric}
                  className={`h-11 w-full mt-7 text-[14px] font-bold bg-blue-700  text-white py-2 px-2 rounded-lg`}
                >
                  Next
                </button>
               
              </div>
              </div>
      
    </div>
    </div>
  )
}

export default EssayDetails
