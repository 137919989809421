import React, { useState, useEffect ,useCallback,useRef, memo} from 'react';
import { useStartCompAssignment } from '../../../../utils/api/compreApi';
import { toast } from 'react-toastify';
import CompStandaloneTimer from './compStandAloneTimer';
const AssignmentTimer = ({ selecAssignment, handleTimeUp, AutoSaveDraft }) => {
  const { mutate: startAssignment, data: timerData } = useStartCompAssignment();
  const lastFetchTimeRef = useRef(null);
 

  return (
    <div className="timer-wrapper" style={{ isolation: 'isolate' }}>
      <CompStandaloneTimer
        selecAssignment={selecAssignment}
        handleTimeUp={handleTimeUp}
        AutoSaveDraft={AutoSaveDraft}
        timerData={timerData}
        startAssignment={startAssignment}
        lastFetchTimeRef={lastFetchTimeRef}
      

      />
    </div>
  );
};

export default React.memo(AssignmentTimer);