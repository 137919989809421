import React from 'react'
import CloseIcon from "../../../assets/closeIcon.svg";
import moment from 'moment-timezone';


const AssignmentDetails = ({selectedAssignment,handleAssigDetailClose}) => {
    console.log(selectedAssignment,"selectedAssignment")
  return (
    <div
    class="relative z-50 "
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      aria-hidden="true"
    ></div>

    <div class="fixed inset-0 z-10 w-screen px-20  flex justify-center items-center">
      <div class="flex min-h-full items-end  p-4  sm:items-center sm:p-0">
        <div class="pb-4 relative transform overflow-hidden rounded-lg bg-white  shadow-xl transition-all ">
          <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-full ">
            <div class="flex justify-between items-center pb-4 gap-4">
              <h1 className="text-[18px] text-[#000000] font-[700] ">
                Assignment Details
              </h1>
             <img   src={CloseIcon} alt="close" className="w-5 cursor-pointer" onClick={handleAssigDetailClose}/>
            </div>

           


    <div className=" space-y-4 b pt-2">
    <div className="flex gap-2">
        <h3 className="font-semibold text-gray-700 mb-2">Title:</h3>
        <p className="text-gray-600 font-[700]">{selectedAssignment?.essay_title}</p>
      </div>

       <div className="grid grid-cols-4 gap-4">
       {selectedAssignment?.classroom_name && (
        <div>
          <h3 className="text-[12px] text-gray-700 font-[700] mb-1">ClassRoom:</h3>
          <p className="text-[14px] text-gray-800"> {selectedAssignment?.classroom_name}</p>
        </div>
        )}
         
        <div>
          <h3 className="text-[12px] text-gray-700 font-[700] mb-1">Due Date:</h3>
          <p className="text-[14px] text-gray-800">
          {moment.utc(selectedAssignment?.due_date).format('MM/DD/YYYY, h:mm:ss A')}</p>
        </div>
       
      {selectedAssignment?.word_limit  && (
        <div>
          <h3 className="text-[12px] text-gray-700 font-[700] mb-1">Word Limits:</h3>
          <p className="text-[14px] text-gray-800">{selectedAssignment?.word_limit} words</p>
        </div>
        )}
        {selectedAssignment?.time_limit && (
        <div>
          <h3 className="text-[12px] text-gray-700 font-[700] mb-1">Time Limits:</h3>
          <p className="text-[14px] text-gray-800">{selectedAssignment?.time_limit}min</p>
        </div>
        )}
        
      </div>

      {selectedAssignment?.assignment_type  === "comprehension" ? (''): (
        <>
      <div>
        <h3 className="font-semibold text-gray-700 mb-2">Description:</h3>
        <p className="text-gray-600">{selectedAssignment?.essay_description}</p>
      </div>
      {selectedAssignment?.essay_questions?.length > 0 && (
      <div>
        <h3 className="font-semibold text-gray-700 mb-2">Questions:</h3>
        <ul className="list-disc pl-5 space-y-2">
          {selectedAssignment?.essay_questions?.map((question, index) => (
            <li key={index} className="text-gray-600">{question.Question}</li>
          ))}
        </ul>
      </div>
      )}
      </>
    )}
    </div>
  
              
          
           
          </div>
        
        </div>
      </div>
    </div>
  </div>
  )
}

export default AssignmentDetails
