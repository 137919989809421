import React from 'react';
import AIIcon from '../assets/artificial-intelligence.svg'
const TitleList = ( {title, handleSelectedTitle }) => {
  console.log("🚀 ~ TitleList ~ titles:", title)
  const hasBoardOrYear = title?.some(item => item?.board || item?.year);
  
  return (

    <div className="bg-white p-0 mb-2 shadow rounded-lg  table-container table-wrapper max-h-[28rem] overflow-auto">
      {title?.length >  0 ? (
        <>
           {title?.length > 0 ? (
            <table className="min-w-full relative">
              <thead className='sticky top-0 bg-gray-300 border border-gray-300 z-10'>
                <tr className="border-b">
                  <th className="py-2 px-4 text-center">Title</th>
                  
                  {hasBoardOrYear && (
                  <>
                    <th className="py-2 px-4 text-center">Board</th>
                    <th className="py-2 px-4 text-center">Years</th>
                  </>
                )}

                  <th className="py-2 px-4 text-center">Description</th>
                  <th className="py-2 px-4 text-center">Questions</th>


                </tr>
              </thead>
              <tbody>
                {title?.map((title, index) => (
                  <tr 
                    key={index}
                    onClick={() => handleSelectedTitle(title)}
                    className=" hover:bg-gray-100 cursor-pointer border-b"
                  >
                    <td className="py-3 px-4">{title?.title}</td>
                    {hasBoardOrYear && (
                      <>
                    <td className="py-3 px-4">{title?.board}  </td>
                    <td className="py-3 px-4">{title?.year}</td>
                    </>
                  )}
                    <td className="py-3 px-4">{title?.description}</td>
                    <td className="py-3 px-4">
                      <ul>
                        {title?.questions?.map((question, index) => (
                          <li key={index}>{question?.Question}</li>
                        ))}
                      </ul>
                      </td>

                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-gray-500 text-center py-4">No titles found</p>
          )}
          </>
          ):''}
          </div>

//          title?.map((title, index) => (
//           <li
//             key={index}
//             className=" flex items-center  p-2 cursor-pointer hover:bg-gray-200" // Styling to make it clickable
//             onClick={()=>handleSelectedTitle(title)} // Set selected title when clicked
//           >
//             <span className="mr-2">{index + 1}-</span>
//             {title?.title ?
//             <h3 className="text-lg font-semibold">{title?.title}</h3>:
//               <div className='flex gap-2'>

//                 <h3 className="flex text-lg font-semibold">{title} 
//                 <img src={AIIcon} alt='iconAi' className='w-4'/>


//                 </h3>
//               </div>

// 

//           </li>
//         ))): (
//           <p className="text-gray-500">No titles found</p>
//         )}
      
      
    
  );
};

export default TitleList;
