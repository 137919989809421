import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetEvaluation,useApproveEvaluation } from '../../utils/api/userApi';
import {useGetCompAssignmentEvaluation, useGetCompAssignmentEvaluationApprove } from '../../utils/api/compreApi';
import {toast} from 'react-toastify';

const TeacherEvaluationContext = createContext();

export const useTeacherEvaluationContext = () => useContext(TeacherEvaluationContext);

export const TeacherEvaluationProvider = ({ children }) => {
const navigate=useNavigate()

    // Essay Evaluation states
    const [submission, setSubmission] = useState(null);
    const [showEvaluation, setShowEvaluation] = useState(false);
    const [evaluations,setEvaluations]=useState()
    const [EditEvaluation,setEditEvaluation]=useState()
    const { mutate: evaluationReport ,isPending:evaluationReportPending } = useGetEvaluation();
    const { mutate: approveEvaluation, isPending:approveEvaluationPending } = useApproveEvaluation();

    // comprehension evaluation states
    
    const [compsubmission, setCompSubmission] = useState(null);
    console.log("compsubmission:", compsubmission);
    const [compShowEvaluation, setCompShowEvaluation] = useState(false);
    const [compEvaluations,setCompEvaluations]=useState()
    const [editCompEvaluation,setEditCompEvaluation]=useState()
    const { mutate: compEvaluationReport ,isPending:compEvaluationReportPending } = useGetCompAssignmentEvaluation();
    const { mutate: approveCompEvaluation, isPending:approveCompEvaluationPending } = useGetCompAssignmentEvaluationApprove();

    // Essay Evaluation functions
    
    const handleApproveEvaluation = () => {
      let data = {
        evaluationId: evaluations?.id,
      };
      approveEvaluation(data, {
        onSuccess: (response) => {
          if(response?.success ){
          // console.log("🚀 ~ handleApproveEvaluation ~ response:", response);
          // Show success toast
          toast.success(response?.message || "Evaluation approved successfully!", {
              position: "bottom-right"
            });
          // Navigate back to the previous route
          setShowEvaluation(false)
         navigate('/dashboard/assignments')
        //   navigate(-1);
          } // Go back to the previous route
        },
        onError: (error) => {
          // Show error toast if needed
          toast.error("Failed to approve evaluation: " + error.message, {
              position: "bottom-right"
            });
        },
      });
    };
    const handleEditAiEvaluation = () => {
      if (!EditEvaluation.trim()) {
        toast.error("Please enter modification prompt");
        return;
      }
      fetchEvaluation(EditEvaluation);
    };
    const handleEvaluationChange=(e)=>{
      setEditEvaluation(e.target.value)
    }
     
    const fetchEvaluation = (EditEvaluation = '') => {
      const data = {
        id: submission?.evaluation_id,
        modificationPrompt: EditEvaluation,
        
      };
    
      evaluationReport(data, {
        onSuccess: (response) => {
          console.log("🚀 ~ fetchEvaluation ~ response:", response);
          if(response?.success ){
          console.log("response:", response);
          setEvaluations(response)
          toast.success(response?.message || "Evaluation successful!", {
              position: "bottom-right"
            });
            setEditEvaluation('')
          }
        },
        onError: (error) => {
          toast.error("Evaluation failed: " + error.message, {
              position: "bottom-right"
            });
        },
      });
    };

    //comprehension evaluation functions

    const handleCompEditAiEvaluation = () => {
      if (!editCompEvaluation.trim()) {
        toast.error("Please enter modification prompt");
        return;
      }
      fetchCompEvaluation(editCompEvaluation);
    };
   
    const handleCompEvaluationChange=(e)=>{
      setEditCompEvaluation(e.target.value)
    }

    const fetchCompEvaluation = (editCompEvaluation = '') => {
      const data = {
        id: compsubmission?.student_assignment_id,
        modificationPrompt: editCompEvaluation,
        
      };
    
      compEvaluationReport(data, {
        onSuccess: (response) => {
          console.log("🚀 ~ fetchEvaluation ~ response:", response);
          if(response?.success ){
          console.log("response:", response);
          setCompEvaluations(response)
          toast.success(response?.message || "Evaluation successful!", {
              position: "bottom-right"
            });
            setEditCompEvaluation('')
          }
        },
        onError: (error) => {
          toast.error("Evaluation failed: " + error.message, {
              position: "bottom-right"
            });
        },
      });
    };

    const handleCompApproveEvaluation = () => {
      let data = {
        evaluationId: compEvaluations?.evaluation?.metadata?.evaluationId,
      };
      approveCompEvaluation(data, {
        onSuccess: (response) => {
          if(response?.success ){
          toast.success(response?.message || "Evaluation approved successfully!", {
              position: "bottom-right"
            });
          setCompShowEvaluation(false)
         navigate('/dashboard/assignments')
        
          } 
        },
        onError: (error) => {
          toast.error("Failed to approve evaluation: " + error.message, {
              position: "bottom-right"
            });
        },
      });
    };
    

  

  const value = {
    setSubmission,
    submission,
    evaluations,
    setEvaluations,
    EditEvaluation,
    setEditEvaluation,
    handleApproveEvaluation,
    handleEditAiEvaluation,
    handleEvaluationChange,
    fetchEvaluation,
    // isPending,
    approveEvaluationPending,
    setShowEvaluation,
    showEvaluation,
    evaluationReportPending,

    // comprehension evaluation states
    compsubmission,
    setCompSubmission,
    compEvaluations,
    setCompEvaluations,
    editCompEvaluation,
    setEditCompEvaluation,
    fetchCompEvaluation,
    handleCompApproveEvaluation,
    handleCompEditAiEvaluation,
    handleCompEvaluationChange,
    compEvaluationReportPending,
    setCompShowEvaluation,
    compShowEvaluation,
    approveCompEvaluationPending,
  };

  return <TeacherEvaluationContext.Provider value={value}>{children}</TeacherEvaluationContext.Provider>;
};
