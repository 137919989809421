import React from 'react';
import CloseIcon from "../../../assets/closeIcon.svg";
import moment from 'moment-timezone';
import WarningIcon from "../../../assets/warningAssg.svg";

const AssignmentNotice = ({ selectedAssignment, handleAssigNoticeClose, handleAssigEditorOpen }) => {
  console.log(selectedAssignment, "selectedAssignment");

  return (
    <div className="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <div className="fixed inset-0 z-10 w-full px-4 sm:px-32">
        <div className="flex min-h-full p-4 items-center justify-center sm:items-center sm:p-0">
          <div className="pb-4 relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-full">
              <div className="flex justify-between items-center pb-4 gap-4">
                <h1 className="text-[20px] text-[#1DA1F2] font-[700]">Assignment Details</h1>
                <img src={CloseIcon} alt="close" className="w-5 cursor-pointer" onClick={handleAssigNoticeClose} />
              </div>

              <div className="space-y-4 ">
                <div className="flex gap-2">
                  <h3 className="font-semibold text-gray-700 mb-2">Topic :</h3>
                  <p className="text-gray-800 font-[700]">{selectedAssignment?.essayTitle}</p>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {selectedAssignment?.classroomName && (
                    <div>
                      <h3 className="text-[12px] text-gray-700 font-[700] mb-1">ClassRoom:</h3>
                      <p className="text-[14px] text-gray-800">{selectedAssignment?.classroomName}</p>
                    </div>
                  )}

                  <div>
                    <h3 className="text-[12px] text-gray-700 font-[700] mb-1">Due Date:</h3>
                    <p className="text-[14px] text-gray-800">
                      {moment.utc(selectedAssignment?.due_date).format('MM/DD/YYYY, h:mm:ss A')}
                    </p>
                  </div>

                  {selectedAssignment?.wordLimit && (
                    <div>
                      <h3 className="text-[12px] text-gray-700 font-[700] mb-1">Word Limits:</h3>
                      <p className="text-[14px] text-gray-800">{selectedAssignment?.wordLimit} words</p>
                    </div>
                  )}

                  {selectedAssignment?.timeLimit && (
                    <div>
                      <h3 className="text-[12px] text-gray-700 font-[700] mb-1">Time Limits:</h3>
                      <p className="text-[14px] text-gray-800">{selectedAssignment?.timeLimit} min</p>
                    </div>
                  )}
                </div>

                <div className="flex justify-center items-center gap-2 py-4 px-5">
                  {selectedAssignment?.timeLimit !== null ? (
                    <>
                      <img src={WarningIcon} alt="warning" className="w-8" />
                    <p className="text-red-500 text-[14px]  font-[700] w-full">
                      Once you proceed to the assignment, the timer will begin, and you must submit it before the allotted time runs out!
                    </p>
                    </>
                   
                  ) : (
                    <>
                    <img src={WarningIcon} alt="warning" className="w-7" />
                    <p className="text-red-500 text-[14px]  font-[700] w-full">
                     Are you ready to start the assignment?
                    </p>
                    </>
                  )}
                </div>

                <div className="flex flex-col sm:flex-row justify-end gap-2">
                  <button className="border border-gray-200 text-[#000000] px-4 py-2 rounded-md" onClick={handleAssigNoticeClose}>
                    Cancel
                  </button>
                  <button className="bg-[#1DA1F2] text-white px-4 py-2 rounded-md hover:bg-[#0d8ecf]" onClick={handleAssigEditorOpen}>
                    Proceed to Assignment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentNotice;