import React, { useState,useEffect } from "react";
import DeleteModal from "../../../components/sharedUi/deleteModal";
import { toast } from "react-toastify";
import DeleteIcon from "../../../assets/delete.svg";
import Loader from "../../../components/sharedUi/loader";
import { truncateText } from "../../../utils/constants";
import SortingIcon from "../../../assets/sortingIcon.svg";
import { useNavigate } from "react-router-dom";
import AssignmentIcon from "../../../assets/assignment.svg"
import EvaluationView from './evaluationView'
import AssignmentText from './assignmentText'
import { useClassContext } from "../../../components/context/classContext";
import BackIocn from "../../../assets/arrowBackIcon.svg";
import moment from 'moment-timezone';
import  DetailIcon from '../../../assets/detailicon.svg'
import CompEvaluationView from './comprehension/compEvaluationView'
import { useTeacherEvaluationContext } from "../../../components/context/teacherEvaluationContext";
const TeacherSubTable = ({
  teacherAssignment,
  totalPages,
  page,
  handlePageChange,
  getPageNumbers,
  handleAssigDetailOpen
}) => {
console.log(teacherAssignment,"teacherAssignment")
    const navigate=useNavigate()
  const [sortConfig, setSortConfig] = useState({
    key: "student_name",
    direction: "ascending",
  });
  console.log('sortConfig',sortConfig)
  const {setBackTab}=useClassContext()

  const [viewDetailsOpen,setViewDetailsOpen]=useState(false)
    const [viewDetails,setViewDetails]=useState()
    console.log(viewDetails,"viewDetails")
 
  const {
    setSubmission,
    submission,
    evaluations,
    setEvaluations,
    EditEvaluation,
    setEditEvaluation,
    setShowEvaluation,
    showEvaluation,
    handleApproveEvaluation,
    handleEditAiEvaluation,
    handleEvaluationChange,
    fetchEvaluation,
    approveEvaluationPending,
    evaluationReportPending,

    // comprehension evaluation states
    compsubmission,
    setCompSubmission,
    compEvaluations,
    setCompEvaluations,
    editCompEvaluation,
    setEditCompEvaluation,
    fetchCompEvaluation,
    handleCompApproveEvaluation,
    handleCompEditAiEvaluation,
    handleCompEvaluationChange,
    compEvaluationReportPending,
    setCompShowEvaluation,
    compShowEvaluation,
    approveCompEvaluationPending,
  }=useTeacherEvaluationContext()
  const sortedAssignments = React.useMemo(() => {
    let sortableAssignments = [...(teacherAssignment?.submissions || [])];
    if (sortConfig !== null) {
      sortableAssignments.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableAssignments;
  }, [teacherAssignment, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleNavigateSubmission=(assignment)=>{
    if (
      assignment?.status === "Not Started" ||
      assignment?.status === "In Progress" ||
      assignment?.status === "Submitted - Awaiting AI Evaluation" ||
      assignment?.status === "Submitted Late - Awaiting AI Evaluation" ||
      assignment?.status === "Evaluation Failed"
      
    ) {
      toast.warning("This submission is not yet evaluated or submitted", {
        position: "bottom-right"
      });
      return;
    }

      if(assignment?.assignment_type === "comprehension"){
        setCompShowEvaluation(true);
        setCompSubmission(assignment);
      }else{
        setShowEvaluation(true);
        setSubmission(assignment);
      }
    
  }
  const handleViewDetails=(e,submission)=>{
    e.stopPropagation()
    setViewDetails(submission)
    setViewDetailsOpen(true)
  }
  const handleViewDetailsClose=()=>{
    setViewDetailsOpen(false)
  }
  const handleBack=()=>{
    setBackTab(true)
    navigate(-1)
  }
 
  const handleSubAssigDetailOpen=(e,assignment)=>{
    e.stopPropagation()
    handleAssigDetailOpen(assignment)
  }

  

  return (
    <>
    {(evaluationReportPending  || approveEvaluationPending)&& <Loader/>}
    
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
        <thead class=" text-xs text-gray-700 uppercase bg-gray-50 ">
          <tr className=" w-full ">
          <th className="  px-6 py-3">
              <div className="flex gap-2">
                Student Name{" "}
                <img
                  src={SortingIcon}
                  onClick={() => requestSort("student_name")}
                  className="w-4 cursor-pointer"
                />
              </div>
            </th>
            <th  className="  px-6 py-3">
              <div className="flex gap-2">
                Title{" "}
                <img
                  src={SortingIcon}
                  onClick={() => requestSort("essay_title")}
                  className="w-4 cursor-pointer"
                />
              </div>
            </th>
            <th  className="  px-6 py-3">
              <div className="flex gap-2">
                  Assignment Type{" "}
                <img
                  src={SortingIcon}
                  onClick={() => requestSort("assignment_type")}
                  className="w-4 cursor-pointer"
                />
              </div>
            </th>
            <th  className=" px-6 py-3">
              <div className="flex gap-2">
                Class Name{" "}
                <img
                  src={SortingIcon}
                  onClick={() => requestSort("classroom_name")}
                  className="w-4 cursor-pointer"
                />
              </div>
            </th>
            <th  className="  px-6 py-3">
              <div className="flex gap-2">
                Due Date{" "}
                <img
                  src={SortingIcon}
                  onClick={() => requestSort("due_date")}
                  className="w-4 cursor-pointer"
                />
              </div>
            </th>
            <th  className="  px-6 py-3">
              <div className="flex gap-2">
                Status{" "}
                <img
                  src={SortingIcon}
                  onClick={() => requestSort("status")}
                  className="w-4 cursor-pointer"
                />
              </div>
            </th>
            <th  className="   px-6 py-3">
              <div className="flex gap-2">
                Ai Score{" "}
                <img
                  src={SortingIcon}
                  onClick={() => requestSort("ai_score")}
                  className="w-4 cursor-pointer"
                />
              </div>
            </th>
            <th scope="col" className="px-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {teacherAssignment?.message !==
          "No Assignments found for this teacher" ? (
            sortedAssignments?.map((assignment, index) => (
              <tr
              onClick={()=>handleNavigateSubmission(assignment)}
                key={index}
                class="bg-white border-b cursor-pointer  hover:bg-gray-50 "
              >
                 <td class="px-6 py-2">
                  {assignment?.student_name}
                </td>
                <td class="px-6 py-2">
                  {assignment?.essay_title}
                </td>
                <td class="px-6 py-2">
                  {assignment?.assignment_type}
                </td>
                <th class="px-6 py-2">{assignment?.classroom_name}</th>
                <td class="px-6 py-2">
                  {/* {new Date(assignment?.due_date).toLocaleString()} */}
                  {moment.utc(assignment?.due_date).format('MM/DD/YYYY, h:mm:ss A')}

                </td>

                <td class="px-6 py-2">{assignment?.status}</td>

                <td class="px-6 py-2">
               {(assignment?.ai_score === null || assignment?.total_possible === null)?(
                <span className="text-sm font-semibold">--</span>
               ):
                    <span className="text-sm font-semibold">
                    {assignment?.ai_score } / {assignment?.total_possible}
                    </span>
}
                  
                </td>
                
                
                <td className="px-6 py-2 flex gap-2">
                  {(assignment?.status === "Not Started" || assignment?.status === "In Progress") ? 
                  (
                    ""
                   ):
                  (
                  <div className="relative group">
                    <img
                      src={AssignmentIcon}
                      alt="view"
                      onClick={(e) => handleViewDetails(e,assignment)}
                      className="w-5 cursor-pointer"
                    />
                    <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      View Assignment
                    </span>
                  </div>
                  )}
                  <div className="relative group">
                  <img onClick={(e)=>handleSubAssigDetailOpen(e,assignment)} src={DetailIcon} alt='detail' className="w-4 cursor-pointer"/>
                  <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    Details
                  </span>
                </div>
                  {/* <div className="relative group">
                    <img
                      src={DeleteIcon}
                      alt="Delete"
                      className="w-3 cursor-pointer"
                    />
                    <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      Delete
                    </span>
                  </div> */}
                </td>
              </tr>
            ))
          ) : (
            <tr className="w-full h-full  flex justify-center items-center">
              <td class="px-6 py-4">
                <h1 className="text-[12px] font-[500]">No Classes found </h1>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="flex w-full justify-end item-center p-2">
        <nav
          className="isolate inline-flex -space-x-px rounded-md shadow-sm"
          aria-label="Pagination"
        >
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <span className="sr-only">Previous</span>
            <svg
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                clipRule="evenodd"
              />
            </svg>
          </button>

          {getPageNumbers().map((number, index) => (
          number === '...' ? (
            <span key={index} className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
              ...
            </span>
          ) : (
            <button
              key={index}
              onClick={() => handlePageChange(number)}
              className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                page === number
                  ? 'z-10 bg-[#1da1f2] text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  '
                  : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
              }`}
            >
              {number}
            </button>
          )
        ))}

          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={page === totalPages}
            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <span className="sr-only">Next</span>
            <svg
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </nav>
      </div>
    </div>

<AssignmentText viewDetails={viewDetails} viewDetailsOpen={viewDetailsOpen} handleViewDetailsClose={handleViewDetailsClose}/>
{showEvaluation && <EvaluationView 
evaluation={submission} 
setShowEvaluation={setShowEvaluation}
evaluations={evaluations} 
     EditEvaluation={EditEvaluation}
     handleApproveEvaluation={handleApproveEvaluation}
     handleEditAiEvaluation={handleEditAiEvaluation}
     handleEvaluationChange={handleEvaluationChange}
    fetchEvaluation={fetchEvaluation}
        

/>}
{compShowEvaluation && <CompEvaluationView 
 compEvaluations={compEvaluations}
 editCompEvaluation={editCompEvaluation}
 setEditCompEvaluation={setEditCompEvaluation}
 handleCompApproveEvaluation={handleCompApproveEvaluation}
 handleCompEditAiEvaluation={handleCompEditAiEvaluation}
 handleCompEvaluationChange={handleCompEvaluationChange}
 fetchCompEvaluation={fetchCompEvaluation}
 approveCompEvaluationPending={approveCompEvaluationPending}
 compsubmission={compsubmission}
 setCompShowEvaluation={setCompShowEvaluation}
/>}

</>
  );
};

export default TeacherSubTable;
