import React, { useState, useEffect } from 'react';
import BackIcon from '../../../../assets/arrowBackIcon.svg';
import { useNavigate } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import moment from 'moment-timezone';
import Loader from '../../../../components/sharedUi/loader';
import { useGetStudentEvaluation } from "../../../../utils/api/userApi";
const AssignmentEvaluationView = ({ 
    setCompShowEvaluation,
   selecAssignment
    
}) => {
  const navigate = useNavigate();

  console.log("selecAssignment:", selecAssignment);

  const [isExpanded, setIsExpanded] = useState(false);
  const [isShow, setIsShow] = useState(false);
  
  const { data: studentEvaluation ,isPending} = useGetStudentEvaluation(
    selecAssignment?.studentAssignmentId
  );

console.log("studentEvaluation:", studentEvaluation);
 
  const handleBackClick = async () => {
    setCompShowEvaluation(false); 
   
};

  return (
    <>
    {(isPending   ) && <Loader/>}
    {isPending ? '':

    <div className="fixed inset-0 bg-white z-[90] flex flex-col overflow-y-auto">
      {/* Top Bar */}
      <div className="bg-gray-50 py-4 px-4 sm:px-6 lg:px-8">
        <div className="flex items-center gap-4">
          <button 
            onClick={handleBackClick}
            className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
          >
            <img src={BackIcon} alt="back" className="w-5 h-5" />
            <span>Back</span>
          </button>
        </div>
        <div className="mx-auto">
          <div className="flex flex-col gap-1 mb-8">
            <h1 className="text-3xl font-bold text-gray-900">Comprehension Evaluation</h1>
            <div className="flex flex-col gap-2">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-4">
                  <h2 className="text-xl text-gray-700 italic">
                    {studentEvaluation?.evaluation?.metadata?.passage_title}
                  </h2>
                  <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    className="text-blue-600 hover:text-blue-800 font-medium"
                  >
                    {isExpanded ? 'View Less' : 'View More'}
                  </button>
                </div>
              </div>

              {/* Expandable Section */}
              {isExpanded && (
                <div className="space-y-3 bg-gray-50 p-4 rounded-lg">
                  <div className="grid grid-cols-3 gap-4 bg-gray-50 rounded-lg">
                  <div className="flex items-center gap-2">
                      <span className="text-sm font-semibold text-gray-700">Class Name:</span>
                      <span className="text-sm text-gray-600">
                        {studentEvaluation?.evaluation?.metadata?.classroom_name}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-sm font-semibold text-gray-700">Teacher Name:</span>
                      <span className="text-sm text-gray-600">
                        {studentEvaluation?.evaluation?.metadata?.teacher_name}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-sm font-semibold text-gray-700">Student Name:</span>
                      <span className="text-sm text-gray-600">
                        {studentEvaluation?.evaluation?.metadata?.student_name}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-sm font-semibold text-gray-700">Due Date:</span>
                      <span className="text-sm text-gray-600">
                      {new Date(studentEvaluation?.evaluation?.metadata?.due_date).toLocaleString()}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-sm font-semibold text-gray-700">Time Limit:</span>
                      <span className="text-sm text-gray-600">
                        {studentEvaluation?.evaluation?.metadata?.time_limit}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-sm font-semibold text-gray-700">Grade Level:</span>
                      <span className="text-sm text-gray-600">
                        {studentEvaluation?.evaluation?.metadata?.passage_grade}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-sm font-semibold text-gray-700">Total Questions:</span>
                      <span className="text-sm text-gray-600">
                        {studentEvaluation?.evaluation?.metadata?.total_questions}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-sm font-semibold text-gray-700">Total Marks:</span>
                      <span className="text-sm text-gray-600">
                        {studentEvaluation?.evaluation?.metadata?.total_marks}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-sm font-semibold text-gray-700">Evaluation Date:</span>
                      <span className="text-sm text-gray-600">
                        {new Date(studentEvaluation?.evaluation?.metadata?.evaluation_date).toLocaleString()}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-sm font-semibold text-gray-700">Submission Date:</span>
                      <span className="text-sm text-gray-600">
                        {new Date(studentEvaluation?.evaluation?.metadata?.submission_date).toLocaleString()}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Questions Panel */}
          <div className="bg-white rounded-lg shadow-lg flex flex-col h-[calc(100vh-45px)]">
            <div className="flex-1 overflow-y-auto mb-2">
              <div className="p-6">
              <div className='mb-4'>
            <div className={`text-gray-700 text-md whitespace-pre-line ${
                !isShow ? 'line-clamp-3' : ''
            }`}>
                {studentEvaluation?.evaluation?.metadata?.passage?.content}
            </div>
            <button
                onClick={() => setIsShow(!isShow)}
                className="mt-2 text-blue-600 hover:text-blue-800 font-medium text-sm"
            >
                {isShow ? 'Show Less' : 'View More'}
            </button>
        </div>
                {studentEvaluation?.evaluation?.data?.map((section) => (
                  <div key={section.typeId} className="mb-6">
                    <h3 className="text-lg font-semibold mb-4">{section.type} Questions</h3>
                    <div className="space-y-4">
                      {section.questions.map((question) => (
                        <div key={question.id} className="bg-white rounded-lg border border-gray-200 p-4">
                          <div className="flex justify-between items-start mb-2">
                            <div className="flex-1">
                              <p className="font-medium">Q : {question.question}</p>
                              {/* {question.line_reference && (
                                <span className="text-sm text-gray-500">Reference: {question.line_reference}</span>
                              )} */}
                            </div>
                            <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
                            {question?.marks_awarded} / {question.marks} marks
                            </span>
                          </div>
                          <div className="mt-2 flex flex-col items-start gap-2">
                            <p className="text-gray-700">
                              <span className="font-medium">Student's Answer:</span> {question.answer}
                            </p>
                            <p className="text-gray-700">
                              <span className="font-medium">Model Answer:</span> <span className='text-green-600'>{question.model_answer}</span> 
                            </p>
                            {question?.options && (
                                    <ul className="grid grid-cols-2 gap-2">
                                      {question?.options?.map((option) => (
                                        <li className={`text-gray-700 text-md border border-gray-200 p-2 rounded-md ${question?.answer === option ? "bg-blue-100" : ""}`}>{option}</li>
                                      ))}
                                    </ul>
                                  )}
                            {question.explanation && (
                              <p className="text-gray-600 mt-2 text-sm">
                                <span className="font-medium">Explanation:</span> {question.explanation}
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Analysis Panel */}
          <div className="bg-white rounded-lg shadow-lg flex flex-col h-[calc(100vh-45px)]">
            <div className="flex-1 overflow-y-auto mb-4">
              <div className="space-y-6 p-6">
                {/* Distribution Section */}
                <div className="mb-8">
                  <h3 className="text-lg font-semibold mb-4">Question Distribution</h3>
                  <div className="space-y-4">
                    {studentEvaluation?.evaluation?.question_distribution?.by_type?.map((type, index) => (
                      <div key={index}>
                        <div className="flex justify-between items-center mb-2">
                          <span className="text-gray-700">{type.type}</span>
                          <div className="flex items-center gap-4">
                            <span className="text-blue-600">{type.count} questions</span>
                            <span className="text-green-600">{type.total_marks} marks</span>
                            <span className="text-gray-500">{type.percentage}%</span>
                          </div>
                        </div>
                        <div className="h-2 bg-gray-200 rounded-full">
                          <div
                            className="h-full bg-blue-600 rounded-full"
                            style={{ width: `${type.percentage}%` }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Summary Section */}
                <div className="space-y-6">
                  {studentEvaluation?.evaluation?.summary?.map((item, index) => (
                    <div key={index} className="mb-4">
                      <h4 className="font-medium text-gray-900 mb-2">{item.title}</h4>
                      <p className="text-gray-700">{item.content}</p>
                    </div>
                  ))}
                </div>

                {/* Modification Input */}
                {/* <div className="flex flex-col items-center gap-2 mt-6">
                  <div className="w-full">
                    <label htmlFor="comments" className="block text-[16px] font-bold text-gray-700 mb-2">
                      Modify Evaluation
                    </label>
                    <textarea
                      id="comments"
                      rows={1}
                      className="shadow-sm p-2 block w-full sm:text-sm border border-gray-500 rounded-md"
                      value={editCompEvaluation}
                      onChange={ handleCompEvaluationChange}
                      placeholder="Enter your modified evaluation here..."
                    />
                  </div>
                  <button 
                    className="bg-blue-600 text-white px-4 py-2 w-full rounded-md"
                    onClick={handleCompEditAiEvaluation}
                    disabled={editCompEvaluation === ''}
                  >
                    Modify Evaluation
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
}
    </>
  );
};

export default AssignmentEvaluationView;