/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Card, Input, Button, Typography } from "@material-tailwind/react";
import { MdEmail, MdLockOutline, MdVisibility, MdVisibilityOff } from "react-icons/md";
// import SideImage from "../../assets/sideRing.png";
import Logo from "../../assets/logo.svg";
import Lock from "../../assets/lock.png";
import Email from "../../assets/Email.png";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormValidationError from "../../components/sharedUi/FormValidatorError";
import * as yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/sharedUi/loader";
import { useLogin } from "../../utils/api/authApis";

const schema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
});

const Login = () => {
  const { mutate: userLogin,isPending} = useLogin();


  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [email, setEmail] = useState("");
  const [password, setPassowrd] = useState("");

  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle the visibility state
  };

  const emailChange = (e) => {
    setEmail(e.target.value);
  };
  const passwordChange = (e) => {
    setPassowrd(e.target.value);
  };

  const navigate = useNavigate();
  const onSubmit = async (data) => {
    try {
      userLogin(data, {
        onSuccess: (response) => {
          // Check if response exists and has expected data
          if (response && response.token) {
            // toast.success("Logged in successfully");
            navigate("/dashboard");
          } else {
            // toast.error("Invalid response from server")
          }
        },
        onError: (error) => {
          toast.error(error?.message || "Login failed", {
            position: "bottom-right"
          });
        },
      });
    } catch (error) {
      toast.error(error?.message || "An error occurred", {
        position: "bottom-right"
      });
    }
  

    // const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/auth/login`,data)
    // console.log("🚀 ~ onSubmit ~ response:", response)
    // if(response?.status === 200){
    //   navigate('/dashboard')

    // }else{
    //   alert(response?.data?.message)
    // }
  };

  const handleSignUp = () => {
    // toast.info("Progress Underway – Launching Soon",{position: "bottom-right"})
  }
  return (
    <>
    {isPending && <Loader/>}
    <div className="flex justify-center items-center h-screen">
      <div className="flex flex-grow flex-col justify-center items-center  px-[6rem] h-[100vh]">
        {/* <img src={Logo} alt="Crypto Wallet" className="mb-4" /> */}
        <h1
          variant="h4"
          style={{ fontFamily: "Poppins" }}
          className="mb-6 font-bold text-[40px]  font-Poppins"
        >
          SIGN IN
        </h1>
        <form className="w-[460px] bg-white" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4 ">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <div
              className="relative "
              style={{ outline: "none", border: "none" }}
            >
              <img
                src={Email}
                alt="email"
                className="absolute z-10 left-2 top-1/2 transform -translate-y-1/2 "
              />
              <div className="absolute  left-10 top-1/2 transform -translate-y-1/2 h-12  border-r-4 border-[#FFFFFF] "></div>
              <input
                type="email"
                id="email"
                size="lg"
                placeholder="Email Address"
                {...register("email")}
                style={{ backgroundColor: "#FAFAFA", outline: "none" }}
                className="w-full pl-12 py-2.5 rounded-lg  text-gray-900 border-none focus:border-none"
              />
            </div>
            {errors?.email && (
              <FormValidationError errors={errors?.email?.message} />
            )}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <div className="relative outline-none">
              <img
                src={Lock}
                alt="lock"
                className="absolute left-2 top-1/2 transform -translate-y-1/2 "
              />
              <div className="absolute  left-10 top-1/2 transform -translate-y-1/2 h-12 border-r-4 border-[#FFFFFF]"></div>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                color="gray"
                size="lg"
                {...register("password")}
                
                placeholder="Password"
                style={{ backgroundColor: "#FAFAFA", outline: "none" }}
                className="w-full pl-12 py-2.5 rounded-lg  text-gray-900 border-none focus:border-none  "
               
              />
                 <button type="button" onClick={togglePasswordVisibility} className="absolute right-2 top-1/2 transform -translate-y-1/2">
          {showPassword ? (
            <MdVisibility className="text-gray-400 hover:text-gray-600" />
          ) : (
            <MdVisibilityOff className="text-gray-400 hover:text-gray-600" />
          )}
        </button>
            </div>
            {errors?.password && (
              <FormValidationError errors={errors?.password?.message} />
            )}
          </div>
          <div className="flex justify-end mb-6">
            <Link to={"/forgot-password"}>
              <h1
                variant="small"
                className="text-right text-[#030319] hover:underline cursor-pointer"
              >
                Forgot Password?
              </h1>
            </Link>
          </div>

          <button
            type="submit"
            size="lg"
            className="w-full mb-4 py-2 bg-[#000000] normal-case text-[#FFFFFF] text-[18px] font-[500] rounded-lg"
          >
            Login
          </button>
        </form>
        <div className="flex flex-col items-center justify-center">

        <h1 variant="small">
          Don’t have an account?{" "}
          <a
          onClick={()=>navigate('/register')}
           className="text-[#FE8664] hover:underline cursor-pointer"
          //  style={{ pointerEvents: 'none' }}
          >
            Sign Up 
          </a>
        </h1>
        {/* <span className="text-[#FE8664]">Use the Provided Credentials for Login</span> */}
        </div>
      </div>
    </div>
    </>
  );
};

export default Login;
