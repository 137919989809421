import React ,{useState,useEffect} from 'react'
import { useLocation ,useNavigate} from 'react-router-dom';
import { useGenerateSampleEvaluation ,useAssignEssay} from '../../../utils/api/userApi';
import Loader  from '../../../components/sharedUi/loader';
import { toast } from 'react-toastify';
import BackIocn from "../../../assets/arrowBackIcon.svg";
const GenerateSampleEvaluation = () => {
    const {state} = useLocation();
    console.log("data",state?.data);
    const navigate = useNavigate();
    const className = state?.data?.classroom_ids?.map(classItem => classItem.name);
     const classIds = state?.data?.classroom_ids?.map(classItem => classItem.id);

        console.log("Class Names:", className); // Output: ["ALevel1", "ALevel2", "ALevel4"]
        console.log("Class IDs:", classIds);

       
       
    const {mutate:generateSampleEvaluation ,isPending} = useGenerateSampleEvaluation();
    const {mutate:assignEssay ,isPending:assignEssayPending} = useAssignEssay();
    const [sampleEvaluation,setSampleEvaluation] = useState();
    console.log("sampleEvaluation",sampleEvaluation);
    const [modifyPrompt,setModifyPrompt] = useState("");
    const [isExpanded, setIsExpanded] = useState(false);
   
    const handleModifyPrompt = (e) => {
        setModifyPrompt(e.target.value);
    }
    useEffect(()=>{
        handleGenerateEvaluation()
    },[state?.data?.essay_id])

    const handleGenerateEvaluation = () => {
          const data={
              essayId:state?.data?.essay_id,
              sampleEvaluationId: sampleEvaluation?.sampleEvaluationId || null,
              modificationPrompt: modifyPrompt ||""
              
          }
      generateSampleEvaluation(data,{
        onSuccess: (response) => {
            console.log("response",response);
          setSampleEvaluation(response);
          setModifyPrompt("")
        },
        onError: (error) => {
          toast.error("Error " + error.message , {
            position: "bottom-right"
          });
        }   
      })
    }
    const handleAssignEssay = () => {
        const data = {
            essay_id: state?.data?.essay_id,
            classroom_ids: classIds,
            sampleEvaluationId: sampleEvaluation?.sampleEvaluationId,
            due_date: state?.data?.due_date,
            timeLimit: state?.data?.timeLimit,
            wordLimit: state?.data?.wordLimit,
        }
       
        assignEssay(data,{
            onSuccess: (response) => {
                if(response?.success){
                        toast.success(response?.message, {
                          position: "bottom-right"
                        });
                        navigate("/dashboard/assignments");
                        }
            },
            onError: (error) => {
                toast.error("Error " + error.message , {
                  position: "bottom-right"
                });
            }
        })  
    }

    const renderTableHeaders = (data, skipKeys = []) => {
        if (!data || data.length === 0) return null;
        return Object.keys(data[0])
          .filter(key => !skipKeys.includes(key))
          .map((key) => (
            <th key={key} className="px-6 py-3 text-left text-sm font-semibold text-gray-700">
              {key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
            </th>
          ));
      };
      const renderTableRow = (item, skipKeys = []) => {
        return Object.entries(item)
          .filter(([key]) => !skipKeys.includes(key))
          .map(([key, value], idx) => {
            // Add specific styling based on the key
            const cellStyle = key === 'key_gain' 
              ? 'text-green-600' 
              : key === 'key_loss' 
                ? 'text-red-600' 
                : 'text-gray-700';
    
            return (
              <td key={idx} className={`px-6 py-4 text-sm ${cellStyle}`}>
                {key === 'score' ? `${value}/${item.max_score}` : value}
              </td>
            );
          });
      };
      useEffect(() => {
        const handleKeyPress = (e) => {
          if (e.key === "Enter") {
            if(modifyPrompt){
              e.preventDefault();
              handleGenerateEvaluation()
            }
        }
        };
    
        // Add event listener
        document.addEventListener("keydown", handleKeyPress);
    
        // Cleanup
        return () => {
          document.removeEventListener("keydown", handleKeyPress);
        };
      }, [handleGenerateEvaluation]);
     
  return (
    <>
    {(isPending || assignEssayPending ) && <Loader/>}  
    {!isPending ?  
    (
        <>
    <div className="p-6 max-w-7xl mx-auto">
       
        <div className="mb-8 bg-white rounded-lg border border-gray-300 shadow-md p-6">
  <h1 className="text-2xl font-bold mb-4">{sampleEvaluation?.evaluation?.metadata?.essay_title}</h1>
  <div className="flex justify-between items-center text-gray-600">
    <div className="flex items-center gap-4">
      <p>Submission Date: {new Date(sampleEvaluation?.evaluation?.metadata?.submission_date).toLocaleDateString()}</p>
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="text-blue-600 hover:text-blue-800 font-medium"
      >
        {isExpanded ? 'View Less' : 'View More'}
      </button>
    </div>
    
    <button
      onClick={handleAssignEssay}
      className="cursor-pointer text-[14px] font-bold h-11 bg-blue-700 w-fit text-white py-2 px-2 rounded-lg"
    >
      Approve & AssignTopic
    </button>
    
  </div>

  {/* Expandable Section */}
  {isExpanded && (
    <div className="mt-4 space-y-4 border-t border-gray-300 pt-2">
       <div className="grid grid-cols-3 gap-4">
       {className?.length > 0 && (
       <div>
          <h3 className="text-[12px] text-gray-700 font-[700] mb-1"> {className?.length > 1 ? "ClassNames" : "ClassName"}:</h3>
       <ul>
       {className?.map((item,index) => (
       <li key={index}>{item}</li>
       ))}
       </ul>
       </div>
        )}
      {state?.data?.wordLimit  && (
        <div>
          <h3 className="text-[12px] text-gray-700 font-[700] mb-1">Word Limits:</h3>
          <p className="text-[14px] text-gray-800">{state?.data?.wordLimit} words</p>
        </div>
        )}
        {state?.data?.timeLimit && (
        <div>
          <h3 className="text-[12px] text-gray-700 font-[700] mb-1">Time Limits:</h3>
          <p className="text-[14px] text-gray-800">{state?.data?.timeLimit}</p>
        </div>
        )}
        
      </div>
      
      <div>
        <h3 className="font-semibold text-gray-700 mb-2">Description:</h3>
        <p className="text-gray-600">{sampleEvaluation?.evaluation?.metadata?.essay_description}</p>
      </div>
      
      <div>
        <h3 className="font-semibold text-gray-700 mb-2">Questions:</h3>
        <ul className="list-disc pl-5 space-y-2">
          {sampleEvaluation?.evaluation?.metadata?.essay_questions?.map((question, index) => (
            <li key={index} className="text-gray-600">{question.Question}</li>
          ))}
        </ul>
      </div>
    </div>
  )}
</div>

        {/* Criteria Table */}
        <div className="mb-8 bg-white rounded-lg border border-gray-300 shadow-md overflow-x-auto">
          <h2 className="text-xl font-bold p-6 pb-4">Evaluation Criteria</h2>
          <table className="min-w-full">
            <thead className="bg-gray-200">
              <tr>
              {renderTableHeaders(sampleEvaluation?.evaluation?.rubric_evaluation?.criteria ,['max_score'])}
               
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {sampleEvaluation?.evaluation?.rubric_evaluation?.criteria?.map((criterion, index) => (
                 <tr key={index} className="hover:bg-gray-50">
                 {renderTableRow(criterion, ['max_score'])}
               </tr>
              ))}
            </tbody>
          </table>
          <div className="p-6 border-t">
            <p className="text-lg font-semibold">
              Total Score: {sampleEvaluation?.evaluation?.scores?.score}/{sampleEvaluation?.evaluation?.scores?.total_possible}
            </p>
          </div>
        </div>

        {/* Level Grading Table */}
        <div className="mb-8 bg-white rounded-lg border border-gray-300 shadow-md overflow-x-auto">
          <h2 className="text-xl font-bold p-6 pb-4">Level Grading</h2>
          <table className="min-w-full">
            <thead className="bg-gray-200">
              <tr>
              {renderTableHeaders(sampleEvaluation?.evaluation?.level_grading)}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {sampleEvaluation?.evaluation?.level_grading.map((level, index) => (
                  <tr key={index} className={`hover:bg-gray-50 ${
                    level.level === sampleEvaluation?.scores?.overall_level?.level ? 'bg-blue-50' : ''
                  }`}>
                    {renderTableRow(level)}
                  </tr>
              ))}
            </tbody>
          </table>
          <div className="p-6 border-t">
            <p className="text-lg font-semibold">
              Overall Level: {sampleEvaluation?.evaluation?.scores?.overall_level?.level}
            </p>
            <p className="text-gray-600 mt-2">
              {sampleEvaluation?.evaluation?.scores?.overall_level?.description}
            </p>
          </div>
        </div>

        {/* Summary Section */}
        <div className="bg-white rounded-lg  border border-gray-300 shadow-md p-6">
          {/* <h2 className="text-xl font-bold mb-4">Summary</h2> */}
          {sampleEvaluation?.evaluation?.summary?.map((item, index) => (
            <div key={index} className="mb-4">
              <h3 className="font-semibold text-lg mb-2">{item.title}</h3>
              <p className="text-gray-700">{item.content}</p>
            </div>
          ))}
        </div>
      </div>

                <div className="flex gap-2 w-full px-6 pt-2 pb-4">
                  <div className="flex relative w-full border border-gray-300 rounded-lg p-2 items-center justify-center">
                    <input
                      name="topic"
                      value={modifyPrompt}
                      onChange={handleModifyPrompt}
                      className="block flex-1 w-full  outline-none rounded-lg  "
                      placeholder="Tell me how to improve this Evaluation! 'Make it tougher or simplify levels description,add more criteria'"
                      required
                    />
                  </div>
                  <button
                    onClick={handleGenerateEvaluation}
                    className={` text-white  font-bold text-[14px] px-2 rounded-lg w-[220px] bg-blue-700`}
                  >
                    Modify sample Evaluation
                  </button>
                  <div />
                </div>
   </> )
                :''}

    </>
  )
}

export default GenerateSampleEvaluation