import React from 'react';

const RubricTable = ( {aigenEssayRubric,AsssignTopic}) => {
  console.log("🚀 ~ aigenEssayRubric ~ titles:", aigenEssayRubric)
//   const hasBoardOrYear = title?.some(item => item?.board || item?.year);
const rubric = aigenEssayRubric?.rubric;
const levelCount = rubric?.levelConfiguration?.count || 4;
  return (

    <div className="bg-white  mb-4 shadow rounded-lg ">
                <div className='flex justify-between items-center p-2'>
                <h1 className='text-[24px] text-gray-700 font-[700]  pt-2'><span className='text-[#070207] text-[18px] font-bold'>{` ${aigenEssayRubric?.rubric?.name || ''}`}</span></h1>

                <button
                  onClick={AsssignTopic}
                  className={`h-11 text-[14px] font-bold bg-blue-700 w-[150px] text-white py-2 px-2 rounded-lg`}
                >
                  Approve Rubric
                </button>
                </div>
                <h1 className='text-[16px] text-gray-400 font-[400] p-2 '><span className='text-gray-600 text-[16px] font-bold'>{` ${aigenEssayRubric?.rubric?.response || ''}`}</span></h1>
           
           {aigenEssayRubric?.rubric ? (
            <div className='relative overflow-hidden'>
              <div className=' table-container  max-h-[420px] overflow-auto'> 
            {/* <table className="min-w-full  relative">
              <thead  className='sticky top-0 bg-white z-10'>
                <tr className="border ">
                  <th className="  py-2 px-4 text-left">Criteria</th>
                    <th className=" py-2 px-4 text-left">Poor(1)</th>
                    <th className="py-2 px-4 text-left">Needs Improvement(2)</th>
                  <th className="py-2 px-4 text-left">Satisfactory(3)</th>
                  <th className="py-2 px-4 text-left">Good(4)</th>
                  <th className="py-2 px-4 text-left">Excellent(5)</th>

                </tr>
              </thead>
              <tbody >
                {aigenEssayRubric?.rubricDetails?.map((rubric, index) => (
                  <tr key={index} className="hover:bg-gray-100  border">
                    <td className="py-3 px-4">{rubric?.criteria}</td>
                    <td className="py-3 px-4">{rubric?.Poor}</td>
                    <td className="py-3 px-4">{rubric?.Needs_Improvement}  </td>
                    <td className="py-3 px-4">{rubric?.Satisfactory}</td>
                    <td className="py-3 px-4">{rubric?.Good}</td>
                    <td className="py-3 px-4">{rubric?.Excellent}</td>
                  </tr>
                ))}
              </tbody>
            </table> */}
               <table className="min-w-full relative">
      <thead className='sticky top-0 bg-white z-10'>
        <tr className="border">
          <th className="py-2 px-4 text-left">Criteria</th>
          {[...Array(levelCount)].map((_, index) => (
            <th key={index} className="py-2 px-4 text-left">
             
              <div className="py-2 px-4 text-left">
                {rubric?.levelConfiguration?.descriptions[`level_${index + 1}`]}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rubric?.criteria?.map((criterion, index) => (
          <tr key={index} className="hover:bg-gray-100 border">
            <td className="py-3 px-4 font-medium">
              {criterion.criterion}
            </td>
            {[...Array(levelCount)].map((_, levelIndex) => (
              <td key={levelIndex} className="py-3 px-4">
                {criterion.levels[`level_${levelIndex + 1}`]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
            </div>
            </div>
          ) : (
            <p className="text-gray-500 text-center py-4">No Rubric found</p>
          )}
          </div>


      
      
    
  );
};

export default RubricTable;
