import React, {memo, useState, useRef ,useCallback,forwardRef} from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import debounce from 'lodash.debounce';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { toast } from "react-toastify";
import { useTimerContext } from "../../../components/context/timerContext";


const styles = `

  /* Hide CKEditor watermark/powered by popup */
  .ck.ck-balloon-panel {
    display: none !important;
  }
  
  /* Additional selectors to ensure watermark is hidden */
  .ck-powered-by,
  .ck-powered-by-balloon,
  .ck-balloon-panel_visible {
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important;
  }
  /* Remove blue outline on focus */
  .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    padding:5px !important;
    width:100% !important;
  }

  /* Remove border and outline from the editing area */
  .ck.ck-editor__editable_inline {
    border: none !important;
    outline: none !important;
    height: 420px !important; /* Fixed height */
    overflow-y: auto !important; /* Add vertical scroll */
  }

  /* Remove focus ring from toolbar items */
  .ck.ck-button:focus, .ck.ck-button.ck-on:focus {
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
  }

  /* Optional: Remove focus outline from toolbar dropdown items */
  .ck.ck-dropdown__button:focus {
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
  }

  /* Custom scrollbar styles (optional) */
  .ck.ck-editor__editable_inline::-webkit-scrollbar {
    width: 8px;
  }

  .ck.ck-editor__editable_inline::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .ck.ck-editor__editable_inline::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  .ck.ck-editor__editable_inline::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

    .ck.ck-editor__editable_inline[data-gramm="false"] {
    grammarly-extension: none !important;
    data-gramm: false !important;
    data-gramm_editor: false !important;
    data-enable-grammarly: false !important;
    -ms-spellcheck: false !important;
    spellcheck: false !important;
  }
`;

const AssignmentEditor = forwardRef((props, ref) => {
  
  const {essayContent, setEssayContent, handleEssayContent} = useTimerContext();
  const editorRef = useRef(null);
  const contentChangeTimeoutRef = useRef(null);
  const latestContentRef = useRef(essayContent); // Add this ref to track latest content

  // Update the ref whenever content changes
  const handleContentChange = useCallback((event, editor) => {
    const data = editor.getData();
    latestContentRef.current = data; // Update ref immediately
    
    // Clear any existing timeout
    if (contentChangeTimeoutRef.current) {
      clearTimeout(contentChangeTimeoutRef.current);
    }

    // Set new timeout for context update
    contentChangeTimeoutRef.current = setTimeout(() => {
      handleEssayContent(data);
    }, 300);
  }, [handleEssayContent]);

  // Expose method to get latest content
  React.useImperativeHandle(ref, () => ({
    getLatestContent: () => latestContentRef.current
  }));

  // const preventClipboardActions = (evt) => {
  //   evt.stop(); // Prevent the default clipboard action
  //   toast.error("Copy/Paste Not Allowed.", {
  //     position: "bottom-right"
  //   });
  // };
  return (
    <div className="mt-4">
      <style>{styles}</style>
      <div className="toolbar-container"></div>
      <div className="editor-container" style={{ 
        border: '1px solid #ccc',
        borderRadius: '4px',
        minHeight: '375px', // Adjust this value as needed
        overflow: 'auto'

      }}>
      <CKEditor
        editor={DecoupledEditor}
       
        data={essayContent || ''}
        onChange={handleContentChange}
        onReady={(editor) => {
          // Insert the toolbar before the editor content
          const toolbarContainer = document.querySelector('.toolbar-container');
          if (toolbarContainer && !toolbarContainer.firstChild) {
            toolbarContainer.appendChild(editor.ui.view.toolbar.element);
          }
          const editorElement = editor.editing.view.getDomRoot();
          if (editorElement) {
            editorElement.setAttribute('data-gramm', 'false');
            editorElement.setAttribute('data-gramm_editor', 'false');
            editorElement.setAttribute('data-enable-grammarly', 'false');
            editorElement.setAttribute('spellcheck', 'false');
            editorElement.setAttribute('autocorrect', 'off');
            editorElement.setAttribute('autocapitalize', 'off');
            editorElement.setAttribute('autocomplete', 'off');
            editorElement.setAttribute('data-enable-grammarly', 'false');
          }
          // editor.editing.view.document.on('copy', preventClipboardActions, { priority: 'high' });
          // editor.editing.view.document.on('cut', preventClipboardActions, { priority: 'high' });
          // editor.editing.view.document.on('paste', preventClipboardActions, { priority: 'high' });
    
          // editor.editing.view.document.on('clipboardInput',  customClipboardHandler, { priority: 'high' });
          // editor.editing.view.document.on('copy',  customClipboardHandler, { priority: 'high' });
          // editor.editing.view.document.on('cut', customClipboardHandler, { priority: 'high' });
        }}
        config={{
          toolbar: {
            items: [
              'heading',
              '|',
              'fontSize',
              'fontFamily',
              '|',
              'fontColor',
              'fontBackgroundColor',
              '|',
              'bold',
              'italic',
              'underline',
              'strikethrough',
              '|',
              'alignment',
              '|',
              'numberedList',
              'bulletedList',
              '|',
              'outdent',
              'indent',
              '|',
              'link',
              'blockQuote',
              'insertTable',
              'mediaEmbed',
              '|',
              'undo',
              'redo'
            ],
            shouldNotGroupWhenFull: true
          },
          
          removePlugins: ['PasteFromOffice', 'SpellCheck'],
         
          disableGramarly: true, // Additional configuration to disable Grammarly
          spelling: {
            enabled: false // Disable spell checking
          },
          fontSize: {
            options: [
              'tiny',
              'small',
              'default',
              'big',
              'huge'
            ]
          },
          fontFamily: {
            options: [
              'default',
              'Arial, Helvetica, sans-serif',
              'Courier New, Courier, monospace',
              'Georgia, serif',
              'Lucida Sans Unicode, Lucida Grande, sans-serif',
              'Tahoma, Geneva, sans-serif',
              'Times New Roman, Times, serif',
              'Trebuchet MS, Helvetica, sans-serif',
              'Verdana, Geneva, sans-serif'
            ]
          },
          table: {
            contentToolbar: [
              'tableColumn',
              'tableRow',
              'mergeTableCells'
            ]
          }
        }}
      />
      </div>
      {/* Add a container for the toolbar */}
    </div>
  );
});

export default memo(AssignmentEditor)
