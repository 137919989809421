import React ,{useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import BackIcon from "../../../assets/arrowBackIcon.svg";
import { useComprehensionContext } from '../../../components/context/comprehensionContext';
import Loader from '../../../components/sharedUi/loader';
import MultiSelect from '../essayGenerator/multiselect';
import { useGetClasses } from '../../../utils/api/userApi';
import { toast } from "react-toastify";
import FormValidationError from '../../../components/sharedUi/FormValidatorError';
const GeneratePassage = () => {
    const logData = JSON.parse(localStorage.getItem("UserData"));
    const {
        selectedTopic,
        wordLength,
        updateComprehensionData,
        handleGeneratePassage,
        dueDate,
        timeLimit,
        handleDuedate,
        setDueDateError,
        handleTimeLimit,
        dueDateError,
        classError,
        setClassError,
        selectedOptions,
        setSelectedOptions,

    } = useComprehensionContext();
  const { data: classes } = useGetClasses(logData?.userId);


    const navigate = useNavigate();

   

    const handleBack = () => {
        navigate(-1);
    };

    console.log("classes",classes);
    const options = classes && classes?.classrooms?.map((cls) => ({
     id: cls.id, // Assuming each classroom object has an 'id' property
     name: cls.name // Assuming each classroom object has a 'name' property
   })) ;

   const handleGenerateQuestion = () => {
    if(selectedOptions.length === 0){
      return  setClassError("Please select at least one class");
        
    }
    if(dueDate === ""){
      return  setDueDateError("Please select due date");
        
    }
    navigate("/dashboard/comprehension/generate-question");
   }

   useEffect(() => {
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            if(selectedOptions.length === 0){
              return  setClassError("Please select at least one class");
                
            }
            if(dueDate === ""){
              return  setDueDateError("Please select due date");
                
            }
            if ( selectedOptions.length !== 0 || dueDate !== "" ) {
              handleGenerateQuestion();
            }
        }
    };

    // Add event listener
    document.addEventListener("keydown", handleKeyPress);

    // Cleanup
    return () => {
        document.removeEventListener("keydown", handleKeyPress);
    };
}, [selectedOptions, dueDate, handleGenerateQuestion]);

    return (
        <>
        {/* {generateComPassagePending && <Loader/>} */}
        <div className="bg-gray-100 min-h-screen px-6 flex flex-col pt-6">
            <div className="flex justify-start">
                <p className="flex gap-2 items-center py-2 w-fit cursor-pointer" onClick={handleBack}>
                    <img src={BackIcon} alt="backicon" />
                    Back
                </p>
            </div>
            <div className="bg-white p-4 rounded-lg flex flex-col items-center">
                <h2 className='text-2xl font-bold'>Create Comprehension Assignment</h2>
                <p className="text-gray-600 mb-4">Provide details to create a comprehension Assignment.</p>

                <div className="  flex flex-col justify-center w-full gap-4">
                    <label className="flex-1">
                  <span className="text-gray-700">Select Class:</span>
                  <MultiSelect
                  setSelectedOptions={setSelectedOptions}
                  selectedOptions={selectedOptions}
                  options={options}
                  setClassError={setClassError}
                  
                  />
                  {classError && <FormValidationError errors={classError} />}
                </label>
                <div className="flex-1 flex flex-col ">
                  <label className="flex-1 ">
                    <span className="text-gray-700">Due Date</span>

                    <div className="relative cursor-pointer">
                      <>
                        <input
                          type="datetime-local"
                          value={dueDate}
                          onChange={handleDuedate}
                         
                          className={`w-full mt-1 p-2 border border-gray-300 focus:outline-none cursor-pointer rounded-md ${
                            dueDate ? "text-[#000000]" : "opacity-0"
                          }`}
                          onClick={(e) => {
                            e.target.showPicker(); 
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                              e.preventDefault();
                              e.target.showPicker();
                            }
                          }}
                        />
                        <div
                          className={`absolute w-full ${
                            dueDate ? "border-none" : "border border-gray-300"
                          } rounded-lg p-2  top-1/2 transform -translate-y-1/2 pointer-events-none ${
                            dueDate ? "text-gray-400" : "text-gray-700"
                          }`}
                        >
                          {!dueDate && "Set date and time"}
                        </div>
                      </>
                    </div>
                  {dueDateError && (
                    <FormValidationError errors={dueDateError} />
                  )}
                  </label>
                </div>
           
                <label className="flex-1">
                  <span className="text-gray-700">Time Limit (min)</span>
                  <input
                    type="text"
                    value={timeLimit}
                    onChange={handleTimeLimit}
                    className="block w-full mt-1 p-2 border border-gray-300 rounded-md"
                    placeholder="Enter Time Limit (10min)"
                  />
                </label>
                    
                  

                    <button 
                        onClick={handleGenerateQuestion}
                        className="bg-blue-500 text-white px-4 py-2 rounded w-full"
                    >
                        Generate Question
                    </button>
                </div>
            </div>
        </div>
        </>
    );
};

export default GeneratePassage;