import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { client } from "./clientApi";
import { toast } from "react-toastify";

export const useGenerateAiTitle = () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      const response = await client("generation/generate-topic", {
        data,
      });
      return response;
    },
    onSuccess: (response) => {
      // queryClient.invalidateQueries(["getClasses"]);
      toast.success(response?.data?.message , {
        position: "bottom-right"
      });
    },
    onError: (error) => {
      console.log("Login failed", error);
    },
  });
};

export const useSaveEssayTopics = () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      const response = await client("generation/save-generated-essay-topic", {
        data,
      });
      return response;
    },
    onSuccess: (response) => {
      // queryClient.invalidateQueries(["getClasses"]);
      toast.success(response?.data?.message , {
        position: "bottom-right"
      });
    },
    onError: (error) => {
      console.log("Login failed", error);
    },
  });
};
export const useGetAllStudent = () =>
  useQuery({
    queryKey: ["getAllStudent"],
    queryFn: async () => await client(`teacher/students`),
    staleTime: 60000,
    cacheTime: 3600000,
  });

export const useGetClasses = (id, searchValue, page) =>
  useQuery({
    queryKey: ["getClasses", id, searchValue, page],
    queryFn: async () =>
      await client(
        `teacher/get-classes/${id}?search=${searchValue || ""}&page=${
          page || 1
        }`
      ),
    staleTime: 60000,
    cacheTime: 3600000,
  });

export const useDeleteClass = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id) => {
      return client(`classroom/delete/${id}`, {
        method: "DELETE",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["getClasses"]);
    },
  });
};

export const useGetAssignment = ({ id, search, status, page }) =>
  useQuery({
    queryKey: ["getAssignment", id, search, status, page],
    queryFn: async () =>
      await client(
        `student/student-assignments/${id}?search=${search || ""}&status=${
          status || ""
        }&page=${page || 1}`
      ),
    staleTime: 60000,
    cacheTime: 3600000,
  });

export const useGetTeacherAssignment = ({ id, search, status, page }) =>
  useQuery({
    queryKey: ["getTeacherAssignment", id, search, status, page],
    queryFn: async () =>
      await client(
        `teacher/teacher-assignments/${id}?search=${search || ""}&status=${
          status || ""
        }&page=${page || 1}`
      ),
    staleTime: 60000,
    cacheTime: 3600000,
  });

export const useGetSubmissions = ({ id, search,page }) =>
  useQuery({
    queryKey: ["getSubmissions", id, search, page],
    queryFn: async () => await client(`teacher/assignment-submissions/${id}?search=${search || ""}&page=${page || 1}`),
    staleTime: 60000,
    cacheTime: 3600000,
  });
export const useGetTeacherSubmissions = ({ id, search, status,page }) =>
  useQuery({
    queryKey: ["getTeacherSubmissions", id, search, status,page],
    queryFn: async () =>
      await client(
        `teacher/teacher-submissions/${id}?search=${search || ""}&status=${
          status || ""
        }&page=${page || 1}`
      ),
    staleTime: 60000,
    cacheTime: 3600000,
  });
export const useGetStudentSubmissions = ({ Tid, Sid, search, status }) =>
  useQuery({
    queryKey: ["getStudentSubmissions", Tid, Sid, search, status],
    queryFn: async () =>
      await client(
        `teacher/teacher-student-submissions/${Tid}/${Sid}?page=1&limit=10&search=${
          search || ""
        }&status=${status || ""}`
      ),
    staleTime: 60000,
    cacheTime: 3600000,
  });

export const useGetEvaluation = (data) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return client(`teacher/get-raw-evaluation`, {
        data,
      });
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries(["getTeacherSubmissions"]);
      toast.success(response?.data?.message, {
        position: "bottom-right"
      });
    },
    onError: (error) => {
      console.log("Login failed", error);
    },
  });
};

export const useApproveEvaluation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return client("teacher/submit-teacher-evaluation", {
        data,
      });
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries(["getEvaluation"]);
      toast.success(response?.data?.message, {
        position: "bottom-right"
      });
    },
    onError: (error) => {
      console.log("Login failed", error);
    },
  });
};
export const useBulkApproveEvaluation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return client("teacher/submit-teacher-evaluation-bulk", {
        data,
      });
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries(["getSubmissions"]);
      toast.success(response?.data?.message, {
        position: "bottom-right"
      });
    },
    onError: (error) => {
      console.log("Login failed", error);
    },
  });
};

export const useGetStudentEvaluation = (id) =>
  useQuery({
    queryKey: ["getStudentEvaluation", id],
    queryFn: async () => await client(`student/evaluation/${id}`),
    staleTime: 60000,
    cacheTime: 3600000,
    onSuccess: (response) => {
      toast.success(response?.data?.message, {
        position: "bottom-right"
      });
    },
    onError: (error) => {
      console.log("Login failed", error);
    },
  });

export const useCreateClass = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return client("classroom/create", {
        data,
      });
    },
    onSuccess: (response) => {
      // console.log("🚀 ~ useCreateClass ~ response:", response);
      queryClient.invalidateQueries(["getClasses"]);
      toast.success(response?.data?.message, {
        position: "bottom-right"
      });
    },
    onError: (error) => {
      // toast.error(error?.response?.data?.message);
      console.log("Login failed", error);
    },
  });
};

export const useUpdateClass = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, data }) => {
      return client(`classroom/edit-classroom/${id}`, {
        method: "PUT",
        data,
      });
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries(["getClasses"]);
      toast.success(response?.data?.message, {
        position: "bottom-right"
      });
    },
    onError: (error) => {
      console.log("Login failed", error);
    },
  });
};
export const useSubmitEssay = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ( data ) => {
      return client(`student/submit-essay`, { data });
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries(["getAssignment"]);
      toast.success(response?.data?.message, {
        position: "bottom-right"
      });
    },
    onError: (error) => {
      console.log("Login failed", error);
    },
  });
};
export const useStartAssignment = () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ( data ) => {
      return client(`student/start-assignment`, { data });
    },
    onSuccess: (response) => {
      // queryClient.invalidateQueries(["getAssignment"]);
      toast.success(response?.data?.message, {
        position: "bottom-right"
      });
    },
    onError: (error) => {
      console.log("Login failed", error);
    },
  });
};

export const useContinousSaing = () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ( data ) => {
      return client(`student/auto-save-draft`, { data });
    },
    onSuccess: (response) => {
      // queryClient.invalidateQueries(["getAssignment"]);
      toast.success(response?.data?.message, {
        position: "bottom-right"
      });
    },
    onError: (error) => {
      console.log("Login failed", error);
    },
  });
};
export const useGenerateSampleEvaluation = () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ( data ) => {
      return client(`generation/generate-sample-evaluation`, { data });
    },
    onSuccess: (response) => {
      // queryClient.invalidateQueries(["getAssignment"]);
      toast.success(response?.data?.message, {
        position: "bottom-right"
      });
    },
    onError: (error) => {
      console.log("Error", error);
    },
  });
};
export const useAssignEssay = () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ( data ) => {
      return client(`classroom/assign-essay`, { data });
    },
    onSuccess: (response) => {
      // queryClient.invalidateQueries(["getAssignment"]);
      toast.success(response?.data?.message, {
        position: "bottom-right"
      });
    },
    onError: (error) => {
      console.log("Error", error);
    },
  });
};
export const useGetSaveContent = (id) =>
  useQuery({
    queryKey: ["getSaveContent", id],
    queryFn: async () => await client(`student/get-draft/${id}`),
    
      enabled: !!id, // Ensure the query runs only if ID is provided
      staleTime: 5 * 60 * 1000, // Adjust as needed
      cacheTime: 10 * 60 * 1000, // Adjust as needed
    
  });

  export const useGetNotification = (id, search, page,notificationRead) =>{
  console.log("useGetNotification hook called", { id, search, page }); // Add this line

   return useQuery({
      queryKey: ["getNotification", id, search, page,notificationRead],
      queryFn: async () =>{
      console.log("getNotification queryFn executed"); // Add this line

       return await client(
          `notifications/user-notifications/${id}?search=${search || ""}&page=${page || 1}&notificationRead=${notificationRead ? 'true' : false}`
        )},
        // enabled:  !!id,
    enabled: Boolean(id), // Will only be true when id has a value

        // refetchOnWindowFocus: false,
        //   refetchOnMount: false,
        //   refetchOnReconnect: false,
        //   enabled: false, 
          staleTime: 60000,
          cacheTime: 3600000
     
    })};
    export const useGetStudentStats = (id) =>
      useQuery({
        queryKey: ["getStudentStats", id],
        queryFn: async () =>
          await client(
            `statistics/student-dashboard-stats/${id}`
          ),
        staleTime: 60000,
        cacheTime: 3600000,
      });
      export const useGetTeacherStats = (id) =>
        useQuery({
          queryKey: ["getTeacherStats", id],
          queryFn: async () =>
            await client(
              `statistics/teacher-dashboard-stats/${id}`
            ),
          staleTime: 60000,
          cacheTime: 3600000,
        });

        export const useJoinClass = () => {
          // const queryClient = useQueryClient();
          return useMutation({
            mutationFn: async (data) => {
              return client("classroom/join", {
                data,
              });
            },
            onSuccess: (response) => {
              // queryClient.invalidateQueries(["getEvaluation"]);
              toast.success(response?.data?.message, {
                position: "bottom-right"
              });
            },
            onError: (error) => {
              console.log("Login failed", error);
            },
          });
        };


        // export const useUploadFile = () => {
        //   // const queryClient = useQueryClient();
        //   return useMutation({
        //     mutationFn: async (data) => {
        //       return imageUploadClient(`ocr/extract-text`, {
        //         method: "POST",
        //         data,
        //       });
        //     },
        //     onSuccess: (response) => {
        //       // queryClient.invalidateQueries(["getClasses"]);
        //       toast.success(response?.data?.message);
        //     },
        //     onError: (error) => {
        //       console.log("Login failed", error);
        //     },
        //   });
        // };
 

// export const useGetFee = () =>
//   useQuery(["getFee"], async () => await client(`wallet/getFee`));

// export const useGetUserList = (isAdmin) =>
//   useQuery(["getUserList"], async () =>
//     await client(`auth/getAllUsers${isAdmin ? "?filterOnlyAdmins=true" : ""}`)
//   );

// export const useUpdateUser = () => {
//   const queryClient = useQueryClient();
//   return useMutation(
//     async (data) => await client(`auth/updateUserActive`, { data }),
//     {
//       onSuccess() {
//         void queryClient.invalidateQueries(["getUserList"]);
//       },
//     }
//   );
// };

// export const useGetCoins = () =>
//   useQuery(["getCoins"], async () => await client(`coins/getCoins`));

// export const useUpdateCoinPrice = () => {
//   const queryClient = useQueryClient();
//   return useMutation(
//     async (data) => await client(`coins/updatePrice`, { data }),
//     {
//       onSuccess() {
//         void queryClient.invalidateQueries(["getCoins"]);
//       },
//     }
//   );
// };

// export const useSetFee = () => {
//   const queryClient = useQueryClient();
//   return useMutation(
//     async (data) => await client(`wallet/setFee`, { data }),
//     {
//       onSuccess() {
//         void queryClient.invalidateQueries(["getFee"]);
//       },
//     }
//   );
// };

// export const useUpdateEmail = () =>
//   useMutation(async (data) => await client(`auth/changeEmail`, { data }));

// export const useUpdatePassword = () =>
//   useMutation(async (data) => await client(`auth/changePassword`, { data }));

// export const useUpdateAdmin = () => {
//   const queryClient = useQueryClient();
//   return useMutation(
//     async (data) => await client(`auth/updateUserRole`, { data }),
//     {
//       onSuccess() {
//         void queryClient.invalidateQueries(["getUserList"]);
//       },
//     }
//   );
// };

// export const useGetUserWalletBalance = (id) =>
//   useQuery(
//     ["getUserWalletBalance", id],
//     async () => await client(`wallet/getUserWalletWithBalance/${id}`),
//     { enabled: !!id }
//   );

// export const useUpdateTransaction = () => {
//   const queryClient = useQueryClient();
//   return useMutation(
//     async (data) => await client(`wallet/updateTransaction`, { data }),
//     {
//       onSuccess() {
//         void queryClient.invalidateQueries(["getTransactions", "Pending"]);
//       },
//     }
//   );
// };
