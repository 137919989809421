import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BackIcon from '../../../assets/arrowBackIcon.svg';
import { useComprehensionContext } from '../../../components/context/comprehensionContext';
import { useGenerateComQuestion } from '../../../utils/api/compreApi';
import Loader from '../../../components/sharedUi/loader';
import { toast } from 'react-toastify';


const defaultQuestionTypes = [
  { id: 1, name: 'Inferential Questions', example: "What does the author imply by the phrase 'a sea of uncertainty'?" },
  { id: 2, name: 'Vocabulary Questions', example: "What does the word 'perplexed' mean as used in paragraph 3?" },
  { id: 3, name: 'Summarization Questions', example: "Summarize the main argument of the passage in no more than 50 words." },
  { id: 4, name: 'Direct Questions', example: "What is the main idea of the second paragraph?" },
  { id: 5, name: 'Analytical Questions', example: "Why does the author use rhetorical questions in paragraph four?" },
  { id: 6, name: 'True/False Questions', example: "State whether the following statement is True or False and provide evidence." },
  { id: 7, name: 'Gap-Fill Questions', example: "Fill in the blanks with appropriate words from the passage." },
  { id: 8, name: 'MCQs', example: "Which of the following best describes the author's tone?" }
];

const QuestionTypeSelection = () => {
  const navigate = useNavigate();
  const { questionTypes,setQuestions,updateComprehensionData, passage} = useComprehensionContext();
console.log("passage",passage);

  const { mutate: generateComQuestion, isPending: generateComQuestionPending } = useGenerateComQuestion();

     // Initialize state with all question types and their counts
     const [selectedTypes, setSelectedTypes] = useState(() => {
      const savedTypes = questionTypes || [];
      return defaultQuestionTypes.map(type => ({
          ...type,
          count: savedTypes.find(saved => saved.type === type.name)?.count || 0
      }));
  });
console.log("selectedTypes", selectedTypes);

const handleCountChange = (type, count) => {
  const newCount = Math.max(0, parseInt(count) || 0);
  
  const updatedTypes = selectedTypes.map(t => 
      t.id === type.id ? { ...t, count: newCount } : t
  );
  
  setSelectedTypes(updatedTypes);
  
  // Update context with only types that have counts > 0
  const contextFormat = updatedTypes
      .filter(t => t.count > 0)
      .map(t => ({
          type: t.name,
          count: t.count
      }));
      
  updateComprehensionData({ questionTypes: contextFormat });
};

 

  const handleSaveAndNext = () => {
    const typesWithCounts = selectedTypes.filter(type => type.count > 0);

    if (typesWithCounts.length === 0) {
      toast.error('Please select at least one question type by setting a count greater than 0',{position:"bottom-right"});
      return;
    }
    const data={
        passageId: passage?.passage?.id,
        questionTypes: typesWithCounts?.map(type => ({
          type: type.name,
          count: type.count
        }))
    }
    
    generateComQuestion(data,{
      onSuccess:(resposne)=>{
        console.log("resposne",resposne);
        if(resposne?.success){
          setQuestions(resposne?.data)
        navigate('/dashboard/comprehension/question-review');
        }
      },
      onError:(error)=>{
        toast.error(error?.response?.data?.message,{position:"bottom-right"});
      }
    });   
  };

  // Calculate total questions from selected types
  const totalQuestions = selectedTypes.reduce((sum, type) => sum + type.count, 0);


  useEffect(() => {
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

          if(selectedTypes.length === 0){
            return toast.error("Please select at least one question type",{position:"bottom-right"});
          }
          if (selectedTypes.length !== 0) {
            handleSaveAndNext();
          }
        }
    };

    // Add event listener
    document.addEventListener("keydown", handleKeyPress);

    // Cleanup
    return () => {
        document.removeEventListener("keydown", handleKeyPress);
    };
}, [selectedTypes, handleSaveAndNext]);
  

  return (
    <>
    {generateComQuestionPending && <Loader />}
    <div className="p-6 max-w-4xl mx-auto">
      <div className="flex items-center gap-2 mb-6">
        <img 
          src={BackIcon} 
          alt="back" 
          className="cursor-pointer" 
          onClick={() => navigate(-1)} 
        />
        <h2 className="text-2xl font-bold">Question Type Selection</h2>
      </div>

      <div className="bg-white rounded-lg shadow p-4">
        <div className="mb-4 flex justify-between items-center">
          <h3 className="font-semibold text-lg">Select Question Types</h3>
          <p className="text-blue-600 font-medium">
            Total Questions: {totalQuestions}
          </p>
        </div>

        <div className="space-y-4">
         
         { selectedTypes?.map((type) => {
            return (
              <div 
                key={type.id} 
                className={`p-4 rounded-lg transition-colors ${
                  type.count > 0 ? 'bg-blue-50 border border-blue-200' : 'bg-gray-50 border border-gray-200'
                }`}
              >
                <div className="flex justify-between items-start gap-4">
                  <div className="flex-1">
                    <h4 className="font-medium text-gray-900">{type.name}</h4>
                    <p className="text-sm text-gray-500 mt-1">{type.example}</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <label className="text-sm font-medium text-gray-600">Count:</label>
                    <input
                      type="number"
                      min="0"
                      value={type.count}
                      onChange={(e) => handleCountChange(type, e.target.value)}
                      className={`w-20 p-2 border rounded text-center ${
                        type.count> 0 ? 'border-blue-300' : 'border-gray-300'
                      }`}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="mt-6 flex justify-end">
          <button
            onClick={handleSaveAndNext}
            disabled={totalQuestions === 0}
            className={`px-6 py-2 rounded-lg text-white font-medium ${
              totalQuestions > 0 
                ? 'bg-blue-600 hover:bg-blue-700' 
                : 'bg-gray-400 cursor-not-allowed'
            }`}
          >
            Generate {totalQuestions} Questions
          </button>
        </div>
      </div>
    </div>
    </>
  );
};

export default QuestionTypeSelection;