import React, { useEffect, useState } from "react";
import {
  useGetTeacherAssignment,
  useGetTeacherSubmissions
} from "../../../utils/api/userApi";

import { useNavigate } from "react-router-dom";
import TeacherAssgTable from "./teacherAssgTable";
import TeacherSubTable from "./TSubmissionTable";
import { useClassContext } from "../../../components/context/classContext";
import Loader from "../../../components/sharedUi/loader";
import AssignmentDetail from '../../../pages/Assignment/teacherAsignment/assignmentDetails'

const TeacherAssignment = () => {
  const TeacherId = JSON.parse(localStorage.getItem("UserData"));
  const navigate = useNavigate();
  const {backtab}=useClassContext()
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState(backtab?"SubmissionView":"AssignmentView");
const [assigDetailOpen,setAssigDetailOpen]=useState(false)
const [selectedAssignment,setSelectedAssignment]=useState(null)

const handleAssigDetailOpen=(assignment)=>{
  setAssigDetailOpen(true)
  setSelectedAssignment(assignment)
}
const handleAssigDetailClose=()=>{
  setAssigDetailOpen(false)
  setSelectedAssignment(null)
}

  const [status, setStatus] = useState(""); 
  const [Sstatus, setSStatus] = useState(""); 


  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };
  const handleSStatusChange = (e) => {
    setSStatus(e.target.value);
  };
  const [page, setPage] = useState(1);
  const [subPage,setSubPage]=useState(1)
  console.log('page',page)
  const { data: teacherSubmission } = useGetTeacherSubmissions({
    id: TeacherId?.userId,
    search: searchValue,
    status: Sstatus,
    page:subPage

  });
console.log('teacherSubmission',teacherSubmission)
 
  const { data: teacherAssignment ,isPending:teacherAssignmentLoading} = useGetTeacherAssignment({
    id: TeacherId?.userId,
    search: searchValue,
    status: status,
    page:page
    });
  console.log('teacherAssignment',teacherAssignment)

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };


  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };
  const totalPages = teacherAssignment?.pagination?.totalPages || 1;
  const totalSubPages = teacherSubmission?.pagination?.totalPages || 1;

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleSubPageChange = (newPage) => {
    setSubPage(newPage);
  };
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const leftOffset = Math.max(page - Math.floor(maxVisiblePages / 2), 1);
      const rightOffset = Math.min(
        leftOffset + maxVisiblePages - 1,
        totalPages
      );

      if (leftOffset > 1) pageNumbers.push(1, "...");
      for (let i = leftOffset; i <= rightOffset; i++) {
        pageNumbers.push(i);
      }
      if (rightOffset < totalPages) pageNumbers.push("...", totalPages);
    }

    return pageNumbers;
  };

  const getSubPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;

    if (totalSubPages <= maxVisiblePages) {
      for (let i = 1; i <= totalSubPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const leftOffset = Math.max(subPage - Math.floor(maxVisiblePages / 2), 1);
      const rightOffset = Math.min(
        leftOffset + maxVisiblePages - 1,
        totalSubPages
      );

      if (leftOffset > 1) pageNumbers.push(1, "...");
      for (let i = leftOffset; i <= rightOffset; i++) {
        pageNumbers.push(i);
      }
      if (rightOffset < totalSubPages) pageNumbers.push("...", totalSubPages);
    }

    return pageNumbers;
  };
  const handleNavigate=(assignment)=>{
    navigate('/dashboard/assignments/detail',{state:assignment})
  }
  const handleAssignmentNavigate=()=>{
    navigate('/dashboard/tools/essay-title')
  }


  return (
    <>
    {/* {teacherAssignmentLoading && <Loader/>} */}
      <div className="p-4">
          <div>
            <div class="flex justify-end items-center gap-2 pb-4 bg-white ">
            <div className="w-full flex  justify-center items-center  mx-auto pt-6">
        <div className="relative right-0 w-full ">
          <ul
            className="relative flex justify-center items-center   list-none  border-b-2 border-[#F2F5F8]"
            role="list"
          >
            <li
              className={`z-30 flex w-[300px] text-center justify-center ${
                activeTab === "AssignmentView" ? "border-b-2 border-[#FE8664]" : ""
              }`}
            >
              <a
                className={`z-30 flex items-center justify-center  px-0 py-1 mb-0 transition-all ease-in-out border-0  cursor-pointer text-slate-700 ${
                  activeTab === "AssignmentView" ? "text-[#FE8664] " : "text-[#8F92A1]"
                }`}
                onClick={() => handleTabClick("AssignmentView")}
                role="tab"
                aria-selected={activeTab === "AssignmentView"}
                aria-controls="AssignmentView"
              >
                <span className="ml-1">Student Assignments</span>
              </a>
            </li>
            <li
              className={`z-30 flex w-[300px] text-center justify-center ${
                activeTab === "SubmissionView" ? "border-b-2 border-[#FE8664]" : ""
              }`}
            >
              <a
                className={`z-30 flex items-center justify-center  px-0 py-1 mb-0 transition-all ease-in-out border-0  cursor-pointer text-slate-700 ${
                  activeTab === "SubmissionView" ? "text-[#FE8664] " : "text-[#8F92A1]"
                }`}
                onClick={() => handleTabClick("SubmissionView")}
                role="tab"
                aria-selected={activeTab === "SubmissionView"}
                aria-controls="SubmissionView"
              >
                <span className="ml-1">Student Submissions</span>
              </a>
            </li>
          </ul>
          <div className="pt-4">
            <div
              className={`${
                activeTab === "AssignmentView" ? "block opacity-100" : "hidden opacity-0"
              }`}
              id="AssignmentView"
              role="tabpanel"
            >
              <div className="flex  justify-between items-center py-4">
              <label for="table-search" class="sr-only">
                Search
              </label>
              <div class="relative ">
                <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    class="w-4 h-4 text-gray-500 "
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  value={searchValue}
                  onChange={handleSearch}
                  type="text"
                  id="table-search"
                  class="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                  placeholder="Search By Name"
                />
              </div>
              <div className="flex gap-3">
              <button onClick={handleAssignmentNavigate} className='bg-[#1da1f2] text-white rounded-lg py-2  px-4 text-[12px]'>Add New Assignment</button>
              <select
                value={status}
                onChange={handleStatusChange}
                className="w-40 p-2 text-sm bg-gray-50  text-[#000] border border-gray-300 rounded-lg"
              >
                <option value="" >
                  All
                </option>

                <option value="Assigned">Assigned</option>
                <option value="In Review">In Review</option>
                <option value="Completed">Completed</option>
                <option value="Overdue">Overdue</option>
              </select>
              </div>
            </div>
            <TeacherAssgTable
              teacherAssignment={teacherAssignment}
              totalPages={totalPages}
              page={page}
              handlePageChange={handlePageChange}
              getPageNumbers={getPageNumbers}
              handleNavigate={handleNavigate}
              handleAssigDetailOpen={handleAssigDetailOpen}
            />
              </div>
              
            </div>
            <div
              className={`${
                activeTab === "SubmissionView" ? "block opacity-100" : "hidden opacity-0"
              }`}
              id="SubmissionView"
              role="tabpanel"
            >
               <div className="flex justify-between items-center py-4">
              <label for="table-search" class="sr-only">
                Search
              </label>
              <div class="relative ">
                <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    class="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  value={searchValue}
                  onChange={handleSearch}
                  type="text"
                  id="table-search"
                  class="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Search By Student Name"
                />
              </div>
              
              <select
                value={Sstatus}
                onChange={handleSStatusChange}
                className="w-40 p-2 text-sm bg-gray-50  text-[#000] border border-gray-300 rounded-lg"
              >
                <option value="" >
                  All
                </option>
                <option value="Submitted">Submitted</option>
                <option value="Graded">Graded</option>
                <option value="Evaluation Failed">Evaluation Failed</option>
                <option value="Not Started">Not Started</option>
                <option value="In Progress">In Progress</option>
                
              </select>
            </div>
              <TeacherSubTable 
               teacherAssignment={teacherSubmission}
               totalPages={totalSubPages}
               page={subPage}
               handlePageChange={handleSubPageChange}
               getPageNumbers={getSubPageNumbers}
               handleNavigate={handleNavigate}
              handleAssigDetailOpen={handleAssigDetailOpen}

              
              />
            </div>
          </div>
        </div>
        </div>
             
           
          
          </div>
        
      </div>
      {assigDetailOpen && (
      <AssignmentDetail
       
       selectedAssignment={selectedAssignment}
       handleAssigDetailClose={handleAssigDetailClose}
         />
    )}
    </>
  );
};

export default TeacherAssignment;
