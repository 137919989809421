import React, {memo, useRef, useCallback, forwardRef} from "react";
import { useComprehensionContext } from "../../../../components/context/comprehensionContext";

const ComAssigEditor = forwardRef(({compAssignnment}, ref) => {
    const { answers, handleAnswerChange } = useComprehensionContext();
    const latestContentRef = useRef(answers);

    // Update latestContentRef when answers change
    React.useEffect(() => {
        latestContentRef.current = answers;
    }, [answers]);

    // Expose method to get latest content for autosave
    React.useImperativeHandle(ref, () => ({
        getLatestContent: () => latestContentRef.current
    }));

    const getAnswerForQuestion = (questionId) => {
        const answer = answers.find(a => a.question_id === questionId);
        return answer ? answer.answer : '';
    };

    const renderQuestionInput = (question) => {
        if (question?.choices && question?.choices?.length > 0) {
            if (question?.gaps && question?.gaps?.length > 0) {
                // Gap Fill questions
                return (
                    <input
                        type="text"
                        placeholder="Type your answer here..."
                        value={getAnswerForQuestion(question.id)}
                        onChange={(e) => handleAnswerChange(question.id, e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                );
            } else {
                // MCQ questions
                return (
                    <div className="space-y-2">
                        {question?.choices?.map((choice, idx) => (
                            <label key={idx} className="flex items-center space-x-3 cursor-pointer">
                                <input
                                    type="radio"
                                    name={`question-${question.id}`}
                                    value={choice}
                                    checked={getAnswerForQuestion(question.id) === choice}
                                    onChange={(e) => handleAnswerChange(question.id, e.target.value)}
                                    className="form-radio h-4 w-4 text-blue-600"
                                />
                                <span className="text-gray-700">{choice}</span>
                            </label>
                        ))}
                    </div>
                );
            }
        } else {
            // Analytical/Text questions
            return (
                <textarea
                    rows="4"
                    placeholder="Type your answer here..."
                    value={getAnswerForQuestion(question.id)}
                    onChange={(e) => handleAnswerChange(question.id, e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
            );
        }
    };

    return (
        <div>
            {compAssignnment?.data?.map((section) => (
                <div key={section.typeId} className="bg-white rounded-lg shadow-md p-6">
                    <div className="space-y-8">
                        {section.questions.map((question) => (
                            <div key={question.id} className="p-2">
                                <div className="flex justify-between items-start mb-4">
                                    <div className="flex-1">
                                        <p className="text-lg text-gray-800 mb-1">
                                            Q{question.position}. {question.question}
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    {renderQuestionInput(question)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
});

export default memo(ComAssigEditor);