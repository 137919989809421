import React from 'react'
import CloseIcon from "../../assets/closeIcon.svg";
import FormValidationError from '../../components/sharedUi/FormValidatorError';
const JoinClass = ({ classCodeError, joinClassSuccess, handleJoinClassClose,handleJoinClass ,classCode ,handleClassCodeChange}) => {
 
 console.log("🚀 ~ JoinClass ~ joinClassSuccess:", joinClassSuccess)
    return (
    <div className="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <div className="fixed inset-0 z-10 w-full px-4 sm:px-32">
      <div className="flex min-h-full p-4 items-center justify-center sm:items-center sm:p-0">
        <div className="pb-4 relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all">
          <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-[400px]">
          {!joinClassSuccess?.success  && (
            <div className="flex justify-between items-center pb-2 gap-4 border-b border-gray-200">
              <h1 className="text-[20px] text-[#1DA1F2] font-[700]">join Class</h1>
              <img src={CloseIcon} alt="close" className="w-5 cursor-pointer" onClick={handleJoinClassClose} />
            </div>
          )
}
            <div className="space-y-2  ">
                {joinClassSuccess?.success ?  (
                  <div className='flex flex-col gap-2'>
                    <div className="flex justify-between items-center  ">
                    <h1 className="text-[14px] text-gray-700 font-[700]">Class: <span className='text-[#1DA1F2] font-[700] text-[16px]'>{joinClassSuccess?.classroom?.name}</span></h1>
                  <h1 className="text-[14px] text-gray-700 font-[700]">Teacher: <span className='text-[#1DA1F2] font-[700] text-[16px]'>{joinClassSuccess?.classroom?.teacher_name}</span></h1>
                  <h1 className="text-[14px] text-gray-700 font-[700]">Grade: <span className='text-[#1DA1F2] font-[700] text-[16px]'>{joinClassSuccess?.classroom?.grade}</span></h1>

                  </div>
                  <div className='flex justify-center my-2 items-center  p-8 rounded-md border border-green-700'>
                    <p className='text-[14px] text-green-700 font-[700]'>
                        {joinClassSuccess?.message}
                    </p>

                  </div>
                  </div>
                ):(
                <div className="flex flex-col gap-2 py-4">
                    <h3 className="text-[14px] text-gray-700 font-[700]">Class Code :</h3>
                    <input onChange={handleClassCodeChange} value={classCode} type="text" className="border border-gray-200 text-[#000000] px-4 py-2 rounded-md" placeholder="Enter Class Code" />
                {classCodeError && <FormValidationError errors={classCodeError} />}
                </div>
                )}
              <div className="flex flex-col sm:flex-row justify-end gap-2">
                <button className="border border-gray-200 text-[#000000] px-4 py-2 rounded-md" onClick={handleJoinClassClose}>
                  Cancel
                </button>
                {!joinClassSuccess?.success &&(
                <button className="bg-[#1DA1F2] text-white px-4 py-2 rounded-md hover:bg-[#0d8ecf]" onClick={handleJoinClass} >
                  send Code
                </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default JoinClass
