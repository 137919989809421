import React, { useState } from "react";
import axios from "axios";
import AIButton from "../../../components/AiButton";
import { toast } from "react-toastify";
import {
  useGetSubmissions,
  useBulkApproveEvaluation,
  useGetTeacherAssignment,
} from "../../../utils/api/userApi";
import { useLocation, useNavigate } from "react-router-dom";
import BackIocn from "../../../assets/arrowBackIcon.svg";
import AssignmentEvaluationView from "./evaluationView";
import { useTeacherEvaluationContext } from "../../../components/context/teacherEvaluationContext";
import TeacherAssigDetailTable from "./teacherAssignmentDetailTable";
import Loader from "../../../components/sharedUi/loader";
import ComprehensionEvaluationView from "./comprehension/compEvaluationView";
const TeacherAssignmentDetails = () => {
  const navigate = useNavigate();
  const teacherId = JSON.parse(localStorage.getItem("UserData"));
  console.log("teacherId:", teacherId);
  const { state } = useLocation();
  const AssigID = state?.id || null;
  console.log("state:", AssigID);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [showEvaluation, setShowEvaluation] = useState(false);

  const [selectedSubmissions, setSelectedSubmissions] = useState([]);
  const {
    setSubmission,
    submission,
    evaluations,
    setEvaluations,
    EditEvaluation,
    setEditEvaluation,
    handleApproveEvaluation,
    handleEditAiEvaluation,
    handleEvaluationChange,
    fetchEvaluation,
    approveEvaluationPending,
    // isPending
    evaluationReportPending,

    // comprehension evaluation states
    compsubmission,
    setCompSubmission,
    compEvaluations,
    setCompEvaluations,
    editCompEvaluation,
    setEditCompEvaluation,
    fetchCompEvaluation,
    handleCompApproveEvaluation,
    handleCompEditAiEvaluation,
    handleCompEvaluationChange,
    compEvaluationReportPending,
    setCompShowEvaluation,
    compShowEvaluation,
    approveCompEvaluationPending,
  }=useTeacherEvaluationContext()
  console.log(
    "🚀 ~ TeacherAssignmentDetails ~ selectedSubmissions:",
    selectedSubmissions
  );

  const { data: submissions,isPending:submissionsPending } = useGetSubmissions({ id: AssigID, search: searchValue, page });
  const { mutate: bulkApproveEvaluation ,isPending:bulkApproveEvaluationPending} = useBulkApproveEvaluation();

  console.log("🚀 ~ TeacherAssignmentDetails ~ submissions:", submissions);
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };
  const totalPages = submissions?.pagination?.totalPages || 1;

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleEvaluationNavigate = (submission) => {
    console.log("submission:", submission);
    console.log("hello")
    if (
      submission?.status === "Not Started" ||
      submission?.status === "In Progress" ||
      submission?.status === "Submitted - Awaiting AI Evaluation" ||
      submission?.status === "Submitted Late - Awaiting AI Evaluation" ||
      submission?.status === "Evaluation Failed"
      
    ) {
      toast.warning("This submission is not yet evaluated or submitted", {
        position: "bottom-right"
      });
      return;
    }
    if(submission?.assignment_type === "comprehension"){
      setCompShowEvaluation(true);
      setCompSubmission(submission);
    }else{
      setShowEvaluation(true);
      setSubmission(submission);
    }
    
  };
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const leftOffset = Math.max(page - Math.floor(maxVisiblePages / 2), 1);
      const rightOffset = Math.min(
        leftOffset + maxVisiblePages - 1,
        totalPages
      );

      if (leftOffset > 1) pageNumbers.push(1, "...");
      for (let i = leftOffset; i <= rightOffset; i++) {
        pageNumbers.push(i);
      }
      if (rightOffset < totalPages) pageNumbers.push("...", totalPages);
    }

    return pageNumbers;
  };
  const handleBulkApprove = () => {
    if (selectedSubmissions.length === 0) {
      toast.warning("Please select at least one submission", {
        position: "bottom-right"
      });
      return;
    }

    const data = {
      teacherID: teacherId?.userId,
      studentAssignmentIds: selectedSubmissions,
    };
    bulkApproveEvaluation(data, {
      onSuccess: (response) => {
        if(response?.success){
          toast.success("Bulk Approved Successfully", {
            position: "bottom-right"
          });
          setSelectedSubmissions([]); // Clear selections after successful approval
        }
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message, {
          position: "bottom-right"
        });
      },
    });
  };
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <>
    {( evaluationReportPending||submissionsPending|| approveEvaluationPending || bulkApproveEvaluationPending )&& <Loader/>}
    <div className="  p-4">
      <div className="flex justify-between items-center">
        <p
          className="flex gap-2 items-center py-2  cursor-pointer"
          onClick={handleBack}
        >
          <img src={BackIocn} alt="backicon" />
          Back
        </p>
        <div className="flex  justify-end items-center gap-2 pb-4">
          <button
            onClick={handleBulkApprove}
            disabled={selectedSubmissions.length === 0}
            className={`px-4 py-1.5 rounded-md ${
              selectedSubmissions.length > 0
                ? "bg-blue-500 text-white hover:bg-blue-600"
                : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }`}
          >
            Bulk Approve
          </button>
          <div class="flex justify-end items-center gap-2  bg-white ">
            <label for="table-search" class="sr-only">
              Search
            </label>
            <div class="relative ">
              <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  class="w-4 h-4 text-gray-500 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                value={searchValue}
                onChange={handleSearch}
                type="text"
                id="table-search"
                class="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                placeholder="Search By Name"
              />
            </div>
          </div>
        </div>
      </div>

      <TeacherAssigDetailTable
        handlePageChange={handlePageChange}
        totalPages={totalPages}
        page={page}
        submissions={submissions}
        getPageNumbers={getPageNumbers}
        handleEvaluationNavigate={handleEvaluationNavigate}
        selectedSubmissions={selectedSubmissions}
        setSelectedSubmissions={setSelectedSubmissions}
      />
    </div>
    {showEvaluation && <AssignmentEvaluationView
    evaluations={evaluations}
    EditEvaluation={EditEvaluation}
    setEditEvaluation={setEditEvaluation}
    handleApproveEvaluation={handleApproveEvaluation}
    handleEditAiEvaluation={handleEditAiEvaluation}
    handleEvaluationChange={handleEvaluationChange}
    fetchEvaluation={fetchEvaluation}
    approveEvaluationPending={approveEvaluationPending}
    evaluation={submission} 
    setShowEvaluation={setShowEvaluation}
    evaluationReportPending={evaluationReportPending}
    />}

    {compShowEvaluation && <ComprehensionEvaluationView
    compEvaluations={compEvaluations}
    editCompEvaluation={editCompEvaluation}
    setEditCompEvaluation={setEditCompEvaluation}
    handleCompApproveEvaluation={handleCompApproveEvaluation}
    handleCompEditAiEvaluation={handleCompEditAiEvaluation}
    handleCompEvaluationChange={handleCompEvaluationChange}
    fetchCompEvaluation={fetchCompEvaluation}
    approveCompEvaluationPending={approveCompEvaluationPending}
    compsubmission={compsubmission}
    setCompShowEvaluation={setCompShowEvaluation}
    compEvaluationReportPending={compEvaluationReportPending}
    />}
    </>
  );
};

export default TeacherAssignmentDetails;
