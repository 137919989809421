import React,{useState} from 'react'
import ClassTables from './classTable'
import {useGetClasses,useDeleteClass} from '../../utils/api/userApi'
import DeleteModal from '../../components/sharedUi/deleteModal'
import {toast} from 'react-toastify'
import { useClassContext } from '../../components/context/classContext'
import AddNewClass from './addNewClassDialog'
import EditClass from './editClass'
import Loader from '../../components/sharedUi/loader'
const ClassData = () => {
  
  const logData=JSON.parse(localStorage.getItem('UserData'))

  const [searchValue,setSearchValue]=useState('')
  const [deleteOpen,setDeleteOpen]=useState(false)
 

  const [deleteId,setDeleteId]=useState()
  const [page,setPage]=useState(1)


  const {data:classes}=useGetClasses(logData?.userId ,searchValue ,page)
  console.log("🚀 ~ ClassData ~ classes:", classes)
  const {mutate:deleteClass ,isPending:deletePending}=useDeleteClass()
  const [addClassOpen,setAddClassOpen]=useState(false)
  const [editClassOpen,setEditClassOpen]=useState(false)
  const [editSelectClassOpen,setEditSelectClassOpen]=useState()
  console.log('editSelectClassOpen:', editSelectClassOpen);



  const handleAddClassOpen=()=>{
    setAddClassOpen(true)
  
  }
  const handleAddClassClose=()=>{
    setAddClassOpen(false)
  }
  
  const handleEditClassOpen=(e,selectClass) => {
    console.log("🚀 ~ handleEditClassOpen ~ selectClass:", selectClass)
   
    e.stopPropagation()
    setEditClassOpen(true)
    setEditSelectClassOpen(selectClass)
  
  }
  const handleEditClassClose=()=>{
    setEditClassOpen(false)
  }
  
  const handleSearch=(e)=>{
    const value=e.target.value
    setSearchValue(value)
  }
  const totalPages = classes?.pagination?.totalPages || 1;

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const leftOffset = Math.max(page - Math.floor(maxVisiblePages / 2), 1);
      const rightOffset = Math.min(leftOffset + maxVisiblePages - 1, totalPages);

      if (leftOffset > 1) pageNumbers.push(1, '...');
      for (let i = leftOffset; i <= rightOffset; i++) {
        pageNumbers.push(i);
      }
      if (rightOffset < totalPages) pageNumbers.push('...', totalPages);
    }

    return pageNumbers;
  };
  const handleDeleteOpen=(e,id)=>{
    e.stopPropagation()
    setDeleteOpen(true)
    setDeleteId(id)
  }
  const handleDeleteClose=()=>{
    setDeleteOpen(false)
  }
  const HandleDelete=()=>{
    deleteClass(deleteId, {
      onSuccess: (response) => {
        if(response.success){
        handleDeleteClose()
        toast.success("Class Deleted Successfully", {
          position: "bottom-right"
        });
      }
      },
      onError: (error) => {
        alert(error)
        console.log("Login failed", error);
      },
    });
  }
 
 
  return (
    <>
    {deletePending && <Loader/>}
    <div className='p-5'>
        <div class="flex justify-end items-center gap-2 pb-4 bg-white ">
        <label for="table-search" class="sr-only">Search</label>
        <div class="relative ">
            <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg class="w-4 h-4 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                </svg>
            </div>
            <input value={searchValue} onChange={handleSearch} type="text" id="table-search" class="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500" placeholder="Search By Name"/>
        </div>
        <div>

        <button onClick={handleAddClassOpen} className='bg-[#1da1f2] text-white rounded-lg py-2  px-4 text-[12px]'>Add New Class</button>
        </div>
    </div>
        <ClassTables handleEditClassOpen={handleEditClassOpen} handleDeleteOpen={handleDeleteOpen} totalPages={totalPages} classes={classes} page={page} handlePageChange={handlePageChange} getPageNumbers={getPageNumbers} />
      
    </div>
       

      <DeleteModal 
      isOpen={deleteOpen}
       handleDelete={HandleDelete} 
        closeModal={handleDeleteClose} 
        text={'Are you want to Delete this Class'}/>
        <AddNewClass 
        addClassOpen={addClassOpen}
        handleAddClassClose={handleAddClassClose}
        />
        <EditClass
        editClassOpen={editClassOpen}
        handleEditClassClose={handleEditClassClose}
        editSelectClassOpen={editSelectClassOpen}
        />
      
  </>
    )
}

export default ClassData
