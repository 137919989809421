import React, { useState, useEffect ,useCallback,useRef, memo} from 'react';
import { useStartAssignment } from '../../../utils/api/userApi';
import { toast } from 'react-toastify';
import StandaloneTimer from './stanAloneTimer';
const AssignmentTimer = ({ selecAssignment, handleTimeUp, autoSaveDraft }) => {
  const { mutate: startAssignment, data: timerData } = useStartAssignment();
  console.log(timerData,'timerData')
  const lastFetchTimeRef = useRef(null);

  return (
    <div className="timer-wrapper" style={{ isolation: 'isolate' }}>
      <StandaloneTimer
        selecAssignment={selecAssignment}
        handleTimeUp={handleTimeUp}
        autoSaveDraft={autoSaveDraft}
        timerData={timerData}
        startAssignment={startAssignment}
        lastFetchTimeRef={lastFetchTimeRef}
      

      />
    </div>
  );
};

export default React.memo(AssignmentTimer);