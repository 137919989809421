import React, { useState ,useCallback,useEffect,useRef} from 'react';
import { useGetStudentCompAssignment,useSubmitComAssignment } from '../../../../utils/api/compreApi';
import { useLocation,useNavigate } from 'react-router-dom';
import Loader from '../../../../components/sharedUi/loader';
import { toast } from 'react-toastify';
import CompAssignmentTimer from './compAssignmentTimer';
import { useAutoSaveDraft  ,useStartCompAssignment} from '../../../../utils/api/compreApi';
import { useComprehensionContext } from '../../../../components/context/comprehensionContext';
import ComAssigEditor from './comAssigEditor';
const ComprehensionAssignment = () => {
    const navigate=useNavigate()
    const {state}=useLocation()
    console.log(state,"state")
    const answerRef = useRef(null);
    const {answers ,setAnswers}=useComprehensionContext()

    const {data:compAssignnment,isPending ,refetch}=useGetStudentCompAssignment(state?.selecAssignment?.studentAssignmentId,
        {
            enabled: !!state?.selecAssignment?.studentAssignmentId,
            refetchOnWindowFocus: false,
            cacheTime: 0
        }
    )
    const {mutate:submitAssignment ,isPending:submitPending}=useSubmitComAssignment()
    const {mutate:autoSaveDraft ,isPending:autoSavePending}=useAutoSaveDraft()
  

    const extractSavedAnswers = useCallback((data) => {
        if (!data?.data) return [];
        
        const savedAnswers = [];
        
        data.data.forEach(section => {
            section.questions.forEach(question => {
                if (question.studentAnswer !== null) {
                    savedAnswers.push({
                        question_id: question.id,
                        answer: question.studentAnswer
                    });
                }
            });
        });
        
        return savedAnswers;
    }, []);

    useEffect(() => {
        if (compAssignnment) {
            const formattedAnswers = extractSavedAnswers(compAssignnment);
            if (formattedAnswers.length > 0) {
                setAnswers(formattedAnswers);
                console.log("Loaded saved answers:", formattedAnswers);
            }
        }
    }, [compAssignnment, setAnswers, extractSavedAnswers]);

    useEffect(() => {
        if (state?.selecAssignment?.studentAssignmentId) {
          refetch();
        }
      }, [state?.selecAssignment?.studentAssignmentId, refetch]);
     

  const handleTimeUp = () => {
    // Auto submit logic here
    toast.error("Time is up!", {
      position: "bottom-right"
    });
    handleSubmit()
  };  

 const AutoSaveDraft = useCallback(() => {
;

    const latestAnswer = answerRef.current?.getLatestContent()

    const data = {
        student_assignment_id: state?.selecAssignment?.studentAssignmentId,
        answers: latestAnswer,
    };
    console.log("Sending payload:", data);

    autoSaveDraft(data, {
      onSuccess: (response) => {
        console.log("response:", response);
        // toast.success(response?.message || "Content auto-saved!");
      },
      onError: (error) => {
        toast.error("Auto-save failed: " + error.message, {
          position: "bottom-right"
        });
      },
    });
  }, [state?.selecAssignment?.studentAssignmentId,autoSaveDraft]);

 


 
  const handleSubmit = () => {
    // Prepare payload
    const latestAnswer = answerRef.current?.getLatestContent() || answers;

    const data = {
      student_assignment_id: state?.selecAssignment?.studentAssignmentId,
      answers: latestAnswer
    };
    console.log("Submitting payload:", data);

    submitAssignment(data,{
        onSuccess:(response)=>{
            console.log(response,"response")
            if(response?.success){
                navigate("/dashboard/assignment")
                toast.success(response?.message,{position:"bottom-right"})
            }
        },
        onError:(error)=>{
            toast.error(error?.message,{position:"bottom-right"})
        }   
    })
  };

 
    return (
        <>
        {(isPending || submitPending) && <Loader/>}
        <div className="min-h-screen bg-gray-50 pb-8 pt-2">
            <div className='flex justify-between items-center p-4 mx-6 my-2  sticky top-1 z-10 bg-white/50 backdrop-blur-sm border border-gray-200 rounded-lg'>
            <h1 className="text-xl font-bold text-gray-900 ">
           Topic : {compAssignnment?.assignment?.passage?.title}
          </h1>
          {state?.selecAssignment?.timeLimit === null || state?.selecAssignment?.timeLimit === 0 ? (
        ''
      ) : (
            <CompAssignmentTimer selecAssignment={state?.selecAssignment} handleTimeUp={handleTimeUp} AutoSaveDraft={AutoSaveDraft}/>
      )}
            </div>
      <div className="max-w-7xl mx-auto px-4">
        {/* Header Section */}
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          
          <div className="flex gap-4 text-sm text-gray-600 mb-4">
            {/* {compAssignnment?.assignment?.timeLimit && (
              <span className="flex items-center">
                <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Time Limit: {compAssignnment?.assignment?.timeLimit} minutes
              </span>
            )} */}
            {/* <span className="flex items-center">
              <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                  d="M3 5h18M3 12h18M3 19h18" />
              </svg>
              Words: {compAssignnment?.assignment?.passage?.wordCount}
            </span> */}
            <h1 className='text-xl font-bold text-gray-900'>Passage :</h1>
          </div>
          
          {/* Passage Content */}
          <div className="prose max-w-none bg-gray-50 p-6 rounded-lg whitespace-pre-line">
            {compAssignnment?.assignment?.passage?.content}
          </div>
        </div>

        {/* Questions Section */}
        <div className="space-y-6">
          <ComAssigEditor  compAssignnment={compAssignnment}
                    isPending={isPending}
          ref={answerRef}  
          />
        </div>

        {/* Submit Button */}
        <div className="mt-8 flex justify-end">
          <button
            onClick={handleSubmit}
            className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
          >
            Submit Assignment
          </button>
        </div>
      </div>
    </div>
        </>
    );
};

export default ComprehensionAssignment;