import React from "react";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import { TypeAnimation } from 'react-type-animation';
import {
  Navigation,
  Pagination,
  Autoplay,
  EffectCoverflow,
} from "swiper/modules";
import 'swiper/css/navigation'; // Add this import
import 'swiper/css/pagination'; // Add this import
import "swiper/css";
import "swiper/css/effect-coverflow";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
import {
  Sparkles,
  School,
  Rocket,
  Users,
  ArrowRight,
  GraduationCap,
  Star,
  Monitor,
  FilePenLine,
  FilePen,
  Mail,
  Phone,
  MapPin,
  Facebook,
  Twitter,
  Instagram,
  Linkedin,
  Heart,
  CheckCircle2, 
  Clock,
  Target, 
  BarChart2, 
  Lightbulb, 
  MessageCircle, 
  TrendingUp, 
  User,
  Play,
  Compass,Home,Zap,PlayCircle,Tag,LifeBuoy,HelpCircle,Code2,Shield,PhoneCall,FileText

} from "lucide-react";
import Homework from "../../assets/assets/homeWork.svg";
import Grading from "../../assets/assets/grading.svg";
import TableOfContents from "../../assets/assets/TableContent.svg";

function Website () {
    const navigate = useNavigate();
  console.log('navigate function:', navigate); // Debug log 1
   
  const handleClick = (path) => {
    console.log('Button clicked, path:', path); // Debug log 2
    try {
      navigate(path);
    } catch (error) {
      console.error('Navigation error:', error); // Debug log 3
    }
  };
  const features = [
    {
      icon: Monitor,
      title: "Virtual Classrooms",
      description:
        "Effortlessly manage students and assignments in a single, streamlined interface",
    },
    {
      icon: FilePen,
      title: "Assignment Creation",
      description: "Quickly create and assign AI-generated tasks customized to curriculum needs",
    },
    {
      icon: Rocket,
      title: "Smart Rubrics",
      description: "Ensure consistency and fairness in grading with AI-powered rubric templates",
    },
    {
      icon: Sparkles,
      title: "AI Evaluation",
      description: "Save time with automatic grading and instant feedback tailored to student performance",
    },
    {
      icon: Users,
      title: "Student Performance Analytics",
      description: "Track class progress with detailed insights and analytics for informed decision-making",
    },
  ];



  return (
    <div className="min-h-screen bg-gradient-to-b from-sky-50 via-white to-rose-50">

<section className="relative overflow-hidden min-h-screen flex items-center bg-gradient-to-b from-[#2B3A67] via-[#384B89] to-[#496BAF]">
  {/* Background Effects */}
  <div className="absolute inset-0 z-[1]">
    <div className="absolute top-20 left-10 w-96 h-96 bg-[#4C9AFF] rounded-full mix-blend-multiply filter blur-[128px] opacity-40 animate-glow"></div>
    <div className="absolute top-20 right-10 w-96 h-96 bg-[#7B61FF] rounded-full mix-blend-multiply filter blur-[128px] opacity-40 animate-glow-delay"></div>
    <div className="absolute -bottom-20 left-1/2 w-96 h-96 bg-[#FF61DC] rounded-full mix-blend-multiply filter blur-[128px] opacity-40 animate-glow-slow"></div>
  </div>

  {/* Floating Elements */}
  <div className="absolute inset-0 z-2 overflow-hidden">
    {[...Array(8)].map((_, i) => (
      <motion.div
        key={i}
        className="absolute"
        animate={{
          y: [0, -20, 0],
          rotate: [0, 360],
          scale: [1, 1.1, 1],
        }}
        transition={{
          duration: 8 + i,
          repeat: Infinity,
          ease: "easeInOut",
          delay: i * 0.5,
        }}
        style={{
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
        }}
      >
        <div className={`w-12 h-12 rounded-${i % 2 ? 'full' : 'xl'} border border-white/20 backdrop-blur-sm bg-gradient-to-br from-white/10 to-white/5`}></div>
      </motion.div>
    ))}
  </div>

  {/* Logo */}
  <div className="absolute top-6 left-6 z-[10]">
    <motion.div
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      className="flex items-center gap-2 cursor-pointer"
      onClick={() => handleClick('/')}
    >
      <div className="w-10 h-10 bg-gradient-to-r from-[#4C9AFF] to-[#7B61FF] rounded-xl flex items-center justify-center">
        <Sparkles className="h-6 w-6 text-white" />
      </div>
      <span className="text-white text-xl font-bold">EduAI</span>
    </motion.div>
  </div>

  {/* Login Button */}
  <div className="absolute top-6 right-6 z-[10]">
    <motion.button
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => navigate('/signin')}
      className="px-6 py-2 cursor-pointer bg-white/10 backdrop-blur-md border border-white/10 rounded-xl font-semibold text-white hover:bg-white/20 transition-all duration-300"
    >
      <span className="flex cursor-pointer items-center gap-2">
        Login
        <User className="h-4 w-4" />
      </span>
    </motion.button>
  </div>

  {/* Main Content */}
  <div className="relative z-[10 ]container mx-auto px-4">
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className="text-center max-w-4xl mx-auto  "
    >
      {/* Badge */}
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ type: "spring", stiffness: 200 }}
        className="inline-flex items-center px-6 py-3 bg-white/5 rounded-full mb-8 backdrop-blur-md border border-white/10 group hover:border-white/20 transition-all duration-300"
      >
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 4, repeat: Infinity, ease: "linear" }}
          className="mr-2"
        >
          <Sparkles className="h-5 w-5 text-[#4C9AFF]" />
        </motion.div>
        <span className="text-white  font-medium group-hover:text-[#4C9AFF] transition-colors duration-300">
          Transforming Education with AI
        </span>
      </motion.div>

      {/* Heading */}
      <h1 className="text-5xl font-bold mb-12 relative">
        <span className="inline-block text-white">
          Reimagining Classrooms with
          <br />
          <TypeAnimation
            sequence={[
              'AI-Powered Learning',
              2000,
              'Intelligent Education',
              2000,
              'Smart Teaching Tools',
              2000,
              'AI-Driven Future',
              2000,
            ]}
            wrapper="span"
            speed={50}
            className="bg-gradient-to-r from-[#4C9AFF] via-[#7B61FF] to-[#FF61DC] bg-clip-text text-transparent"
            repeat={Infinity}
          />
        </span>
      </h1>

      {/* Description */}
      <p className="text-xl text-gray-300 mb-14 max-w-3xl mx-auto leading-relaxed">
        Revolutionizing education with cutting-edge AI tools that simplify
        teaching and empower learning, while ensuring the human connection
        remains at the heart of every interaction.
      </p>

      {/* Action Buttons */}
      <div className="flex flex-col sm:flex-row justify-center gap-4 relative z-[40]">
        <motion.button
          whileHover={{ scale: 1.05, y: -2 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => handleClick('/register/teacher')}
          className="group relative  z-[30] cursor-pointer  px-8 py-4 bg-gradient-to-r from-[#4C9AFF] to-[#7B61FF] rounded-xl font-semibold text-white overflow-hidden shadow-xl shadow-[#4C9AFF]/20"
        >
          <div className="absolute  inset-0 z-[31] bg-gradient-to-r from-[#7B61FF] to-[#FF61DC] opacity-0 group-hover:opacity-100 transition-all duration-500"></div>
          <span className="relative z-[32] cursor-pointer flex items-center justify-center">
            Join as Teacher
            <GraduationCap className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform duration-300" />
          </span>
        </motion.button>

        <motion.button
          whileHover={{ scale: 1.05, y: -2 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => handleClick('/register/student')}
          className="group cursor-pointer relative  z-[30] px-8 py-4 bg-gradient-to-r from-[#4C9AFF] to-[#7B61FF] rounded-xl font-semibold text-white overflow-hidden shadow-xl shadow-[#4C9AFF]/20"
        >
          <div className="absolute inset-0 z-[31] bg-gradient-to-r from-[#7B61FF] to-[#FF61DC] opacity-0 group-hover:opacity-100 transition-all duration-500"></div>
          <span className="relative z-[32] cursor-pointer flex items-center justify-center">
            Join as Student
            <Users className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform duration-300" />
          </span>
        </motion.button>

        {/* <motion.button
          whileHover={{ scale: 1.05, y: -2 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => navigate('/demo')}
          className="group relative px-8 py-4 bg-gradient-to-r from-[#4C9AFF] to-[#7B61FF] rounded-xl font-semibold text-white overflow-hidden shadow-xl shadow-[#4C9AFF]/20"
        >
          <div className="absolute inset-0 bg-gradient-to-r from-[#7B61FF] to-[#FF61DC] opacity-0 group-hover:opacity-100 transition-all duration-500"></div>
          <span className="relative flex items-center justify-center">
            Try Demo
            <PlayCircle className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform duration-300" />
          </span>
        </motion.button> */}
      </div>
    </motion.div>
  </div>
</section>


<section className="py-20 bg-gradient-to-b from-[#2B3A67]/10 via-[#eef2ff] to-[#e0e7ff]">
  {/* Subtle Grid Pattern */}
  <div className="absolute inset-0 z-0">
    <div className="absolute inset-0 bg-[linear-gradient(to_right,#4338ca05_1px,transparent_1px),linear-gradient(to_bottom,#4338ca05_1px,transparent_1px)] bg-[size:14px_24px]"></div>
  </div>

  <div className="container relative z-10 mx-auto px-4">
    <div className="text-center mb-16">
    <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ type: "spring", stiffness: 200 }}
        className="inline-flex items-center px-6 py-3 bg-gradient-to-r from-[#4C9AFF]/10 to-[#7B61FF]/10 rounded-full mb-8 backdrop-blur-md border border-[#4C9AFF]/20 group hover:border-[#4C9AFF]/30 transition-all duration-300"
      >
        
          <Monitor className="h-5 w-5 text-[#4C9AFF] mr-2" />
        
        <span className="text-[#384B89] font-medium group-hover:text-[#4C9AFF] transition-colors duration-300">
          Platform Features
        </span>
      </motion.div>
      <h2 className="text-4xl font-bold mb-4 bg-gradient-to-r from-[#2B3A67] to-[#496BAF] bg-clip-text text-transparent">
        Core Features of Our Platform
      </h2>
      <p className="text-gray-600">
        Everything you need to create, manage, grade assignments, and track student progress seamlessly
      </p>
    </div>

    <div className="px-10 flex flex-wrap items-center justify-center gap-8">
    {features.map((feature, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.2 }}
          whileHover={{ y: -5 }}
          className="group relative bg-white/70 w-96 p-6 rounded-2xl transition-all duration-300 shadow-lg hover:shadow-xl overflow-hidden"
        >
          {/* Gradient Border */}
          <div className="absolute inset-0 p-[1px] rounded-2xl bg-gradient-to-b from-[#2B3A67] via-[#384B89] to-[#496BAF] mask-border">
            <div className="absolute inset-0 bg-white/70 rounded-2xl backdrop-blur-sm" />
          </div>

          {/* Content */}
          <div className="relative">
            <motion.div 
              className="w-12 h-12 bg-gradient-to-r from-[#4C9AFF] to-[#7B61FF] rounded-xl flex items-center justify-center mb-6 group-hover:scale-110 transition-transform duration-300"
              animate={{
                rotate: [0, 360],
                scale: [1, 1.1, 1]
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                ease: "linear",
                delay: index * 0.2
              }}
            >
              <feature.icon className="h-6 w-6 text-white" />
            </motion.div>
            <h3 className="text-xl font-semibold mb-3 bg-gradient-to-r from-[#2B3A67] to-[#496BAF] bg-clip-text text-transparent">
              {feature.title}
            </h3>
            <p className="text-gray-600">{feature.description}</p>
          </div>
        </motion.div>
      ))}
    </div>
  </div>
</section>


<section className="py-20 relative overflow-hidden">
  {/* Curved Background Container */}
  <div className="absolute inset-0">
    {/* Main gradient background with rounded corners */}
    <div className="absolute inset-0 bg-gradient-to-b from-[#2B3A67] via-[#384B89] to-[#496BAF] 
      [clip-path:polygon(0%_0%,_100%_10%,_100%_100%,_0%_90%)]">
      
      {/* Decorative curved lines */}
      <svg className="absolute top-0 right-0 w-full h-32 text-[#2B3A67]" viewBox="0 0 1440 320" preserveAspectRatio="none">
        <path fill="currentColor" d="M0,96L48,112C96,128,192,160,288,186.7C384,213,480,235,576,213.3C672,192,768,128,864,128C960,128,1056,192,1152,208C1248,224,1344,192,1392,176L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
      </svg>
      
      <svg className="absolute bottom-0 left-0 w-full h-32 text-[#496BAF]/10 transform rotate-180" viewBox="0 0 1440 320" preserveAspectRatio="none">
        <path fill="currentColor" d="M0,96L48,112C96,128,192,160,288,186.7C384,213,480,235,576,213.3C672,192,768,128,864,128C960,128,1056,192,1152,208C1248,224,1344,192,1392,176L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
      </svg>
    </div>

    {/* Animated background elements */}
    <div className="absolute inset-0">
      <div className="absolute top-20 left-10 w-96 h-96 bg-[#4C9AFF] rounded-full mix-blend-multiply filter blur-[128px] opacity-40 animate-glow"></div>
      <div className="absolute top-20 right-10 w-96 h-96 bg-[#7B61FF] rounded-full mix-blend-multiply filter blur-[128px] opacity-40 animate-glow-delay"></div>
      <div className="absolute -bottom-20 left-1/2 w-96 h-96 bg-[#f2e9f0] rounded-full mix-blend-multiply filter blur-[128px] opacity-40 animate-glow-slow"></div>
    </div>

    {/* Decorative dots pattern */}
    <div className="absolute inset-0 opacity-10">
      <div className="absolute inset-0" style={{ 
        backgroundImage: 'radial-gradient(circle, #fff 1px, transparent 1px)',
        backgroundSize: '30px 30px'
      }}></div>
    </div>
  </div>

  <div className="container mx-auto px-4 relative z-10">
    <div className="text-center mb-16">
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ type: "spring", stiffness: 200 }}
        className="inline-flex items-center px-6 py-3 bg-white rounded-full mb-8 backdrop-blur-md border border-gray-700 group hover:border-white/20 transition-all duration-300"
      >
        <Play className="h-5 w-5 text-[#384B89] mr-2" />
        <span className="text-[#384B89] font-medium">How It Works</span>
      </motion.div>
      <p className="text-gray-300">
        A seamless workflow designed to elevate teaching and learning experiences
      </p>
    </div>

    <div className="relative">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={5}
        coverflowEffect={{
          rotate: 20,
          stretch: 0,
          depth: 200,
          modifier: 1.5,
          slideShadows: true,
        }}
        pagination={{ clickable: true }}
        modules={[EffectCoverflow, Pagination, Autoplay]}
        className="mySwiper"
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
      >
        {[
          {
            icon: School,
            title: "Set Up Classrooms",
            description: "Easily create and manage virtual classrooms for assigning and tracking homework"
          },
          {
            icon: FilePenLine,
            title: "Generate Assignments",
            description: "AI generates high-quality, grade-appropriate assignments tailored to your curriculum"
          },
          {
            icon: GraduationCap,
            title: "Students Work Online",
            description: "Students complete tasks on an interactive, user-friendly platform"
          },
          {
            image: Grading,
            title: "Instant Grading & Insights",
            description: "Get instant feedback and analytics based on expert reviews and trusted criteria"
          },
          {
            image: Homework,
            title: "Smart Homework Suggestions",
            description: "Real-time recommendations to improve assignments based on student data"
          }
        ].map((item, index) => (
          <SwiperSlide key={index} className="w-80">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="group relative bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300"
            >
              {/* Subtle gradient overlay */}
              <div className="absolute inset-0 bg-gradient-to-b from-[#2B3A67]/5 to-transparent rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              
              {/* Gradient border on hover */}
              <div className="absolute inset-0 rounded-2xl border border-transparent group-hover:border-[#4C9AFF]/20 transition-colors duration-300" />
              
              <div className="relative">
                <div className="w-16 h-16 bg-gradient-to-r from-[#4C9AFF] to-[#7B61FF] rounded-xl flex items-center justify-center mb-6 group-hover:scale-110 transition-transform duration-300">
                  {item.icon ? (
                    <item.icon className="h-8 w-8 text-white" />
                  ) : (
                    <img src={item.image} alt={item.title} className="h-8 w-8" />
                  )}
                </div>
                <h3 className="text-xl font-semibold mb-4 bg-gradient-to-r from-[#2B3A67] to-[#496BAF] bg-clip-text text-transparent">
                  {item.title}
                </h3>
                <p className="text-gray-600">
                  {item.description}
                </p>

                {/* Decorative elements */}
                <div className="absolute top-0 right-0 w-20 h-20 bg-gradient-to-b from-[#2B3A67]/5 to-transparent rounded-bl-full" />
                <div className="absolute bottom-0 left-0 w-16 h-16 bg-gradient-to-t from-[#496BAF]/5 to-transparent rounded-tr-full" />
              </div>
            </motion.div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  </div>

  {/* Custom styles for Swiper pagination */}
  <style jsx global>{`
    .swiper-pagination-bullet {
      background: rgba(255, 255, 255, 0.5);
    }
    .swiper-pagination-bullet-active {
      background: #4C9AFF;
    }
    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right {
      background-image: linear-gradient(to left, rgba(43, 58, 103, 0.2), rgba(255, 255, 255, 0)) !important;
    }
  `}</style>
</section>

      
<section className="py-20 relative overflow-hidden">
  {/* Curved Background Container */}
  <div className="absolute inset-0">
    {/* Main gradient background with rounded corners */}
    <div className="absolute inset-0 bg-gradient-to-b from-[#2B3A67] via-[#384B89] to-[#496BAF] 
      [clip-path:polygon(0%_10%,_100%_0%,_100%_90%,_0%_100%)]">
      
      {/* Decorative curved lines */}
      <svg className="absolute top-0 left-0 w-full h-32 text-[#2B3A67]" viewBox="0 0 1440 320" preserveAspectRatio="none">
        <path fill="currentColor" d="M0,96L48,112C96,128,192,160,288,186.7C384,213,480,235,576,213.3C672,192,768,128,864,128C960,128,1056,192,1152,208C1248,224,1344,192,1392,176L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
      </svg>
      
      {/* Animated background elements */}
      <div className="absolute inset-0">
        <div className="absolute top-20 left-10 w-96 h-96 bg-[#4C9AFF] rounded-full mix-blend-multiply filter blur-[128px] opacity-40 animate-glow"></div>
        <div className="absolute -bottom-20 right-10 w-96 h-96 bg-[#7B61FF] rounded-full mix-blend-multiply filter blur-[128px] opacity-40 animate-glow-delay"></div>
      </div>

      {/* Dot pattern overlay */}
      <div className="absolute inset-0 opacity-10">
        <div className="absolute inset-0" style={{ 
          backgroundImage: 'radial-gradient(circle, #fff 1px, transparent 1px)',
          backgroundSize: '30px 30px'
        }}></div>
      </div>
    </div>
  </div>

  <div className="container relative z-10 mx-auto px-4">
    <div className="text-center mb-16">
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ type: "spring", stiffness: 200 }}
        className="inline-flex items-center px-6 py-3 bg-white rounded-full mb-8 backdrop-blur-md border border-white/5 group hover:border-white/20 transition-all duration-300"
      >
        <GraduationCap className="h-5 w-5 text-[#384B89] mr-2" />
        <span className="text-[#384B89] font-medium">For Teachers</span>
      </motion.div>
      <h2 className="text-4xl font-bold mb-4 text-white">
        Empower Your Teaching Journey
      </h2>
      <p className="text-gray-300 max-w-2xl mx-auto">
        Transform your classroom with AI-powered tools that save time and enhance student engagement
      </p>
    </div>

    <div className="grid md:grid-cols-3 gap-8">
      {[
        {
          icon: Clock,
          title: "Save 70% Time",
          description: "Automated grading and assignment creation lets you focus on what matters most - teaching"
        },
        {
          icon: Target,
          title: "Personalized Learning",
          description: "AI adapts to each student's needs, helping you provide targeted support where it's needed"
        },
        {
          icon: BarChart2,
          title: "Data-Driven Insights",
          description: "Get real-time analytics on student performance to make informed teaching decisions"
        }
      ].map((item, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.2 }}
          whileHover={{ y: -5 }}
          className="group relative bg-white backdrop-blur-sm p-6 rounded-2xl transition-all duration-300 shadow-lg hover:shadow-xl"
        >
          {/* Gradient Border */}
          <div className="absolute inset-0 p-[1px] rounded-2xl bg-gradient-to-b from-[#2B3A67] via-[#384B89] to-[#496BAF] mask-border">
            <div className="absolute inset-0 bg-white rounded-2xl" />
          </div>

          <div className="relative">
            <motion.div 
              className="w-14 h-14 bg-gradient-to-r from-[#4C9AFF] to-[#7B61FF] rounded-xl flex items-center justify-center mb-6 group-hover:scale-110 transition-transform duration-300"
              animate={{
                rotate: [0, 360],
                scale: [1, 1.1, 1]
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                ease: "linear",
                delay: index * 0.2
              }}
            >
              <item.icon className="h-7 w-7 text-white" />
            </motion.div>
            
            <h3 className="text-xl font-semibold mb-3 bg-gradient-to-r from-[#2B3A67] to-[#496BAF] bg-clip-text text-transparent">
              {item.title}
            </h3>
            <p className="text-gray-600">{item.description}</p>

            {/* Decorative elements */}
            <div className="absolute top-0 right-0 w-20 h-20 bg-gradient-to-b from-[#2B3A67]/10 to-transparent rounded-bl-full" />
            <div className="absolute bottom-0 left-0 w-16 h-16 bg-gradient-to-t from-[#496BAF]/10 to-transparent rounded-tr-full" />
          </div>
        </motion.div>
      ))}
    </div>
  </div>
</section>   
<footer className="bg-gradient-to-br from-white via-white to-rose-50 pt-16 pb-8 border-t border-gray-200">
  <div className="container mx-auto px-4">
    <div className="w-full">
      <svg className="w-full h-12 -mt-20 text-sky-50" viewBox="0 0 1440 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0,50 C150,20 350,80 720,50 C1080,20 1290,80 1440,50 L1440,100 L0,100 Z" fill="currentColor"/>
      </svg>
    </div>

    <div className="grid grid-cols-1 md:grid-cols-4 gap-12 mb-12">
      {/* About Us Section */}
      <div className="space-y-6">
        <div className="flex items-center gap-3">
          <Lightbulb className="h-6 w-6 text-[#4C9AFF]" />
          <h3 className="text-2xl font-bold bg-gradient-to-r from-[#2B3A67] to-[#496BAF] bg-clip-text text-transparent">
            About Us
          </h3>
        </div>
        <p className="text-gray-600 leading-relaxed">
          Empowering educators and students with AI-driven tools for a better learning experience.
        </p>
        <div className="flex space-x-4">
          {[
            { name: 'Facebook', icon: Facebook },
            { name: 'Twitter', icon: Twitter },
            { name: 'Instagram', icon: Instagram },
            { name: 'Linkedin', icon: Linkedin }
          ].map((social) => (
            <a
              key={social.name}
              href="#"
              className="w-10 h-10 rounded-full bg-gradient-to-r from-[#4C9AFF]/10 to-[#7B61FF]/10 flex items-center justify-center group transition-all duration-300 hover:scale-110"
            >
              <social.icon className="h-5 w-5 text-[#4C9AFF] group-hover:text-[#7B61FF] transition-colors" />
            </a>
          ))}
        </div>
      </div>

      {/* Quick Links Section */}
      <div className="space-y-6">
        <div className="flex items-center gap-3">
          <Compass className="h-6 w-6 text-[#4C9AFF]" />
          <h3 className="text-2xl font-bold bg-gradient-to-r from-[#2B3A67] to-[#496BAF] bg-clip-text text-transparent">
            Quick Links
          </h3>
        </div>
        <ul className="space-y-3">
          {[
            { name: 'Home', icon: Home },
            { name: 'Features', icon: Zap },
            { name: 'How It Works', icon: PlayCircle },
            { name: 'Pricing', icon: Tag }
          ].map((link) => (
            <li key={link.name}>
              <a href="#" className="text-gray-600 hover:text-[#4C9AFF] transition-colors flex items-center group gap-2">
                <link.icon className="h-4 w-4" />
                <span>{link.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/* Support Section */}
      <div className="space-y-6">
        <div className="flex items-center gap-3">
          <LifeBuoy className="h-6 w-6 text-[#4C9AFF]" />
          <h3 className="text-2xl font-bold bg-gradient-to-r from-[#2B3A67] to-[#496BAF] bg-clip-text text-transparent">
            Support
          </h3>
        </div>
        <ul className="space-y-3">
          {[
            { name: 'Help Center', icon: HelpCircle },
            { name: 'Documentation', icon: FileText },
            { name: 'API Reference', icon: Code2 },
            { name: 'Privacy Policy', icon: Shield }
          ].map((link) => (
            <li key={link.name}>
              <a href="#" className="text-gray-600 hover:text-[#4C9AFF] transition-colors flex items-center group gap-2">
                <link.icon className="h-4 w-4" />
                <span>{link.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/* Contact Section */}
      <div className="space-y-6">
        <div className="flex items-center gap-3">
          <PhoneCall className="h-6 w-6 text-[#4C9AFF]" />
          <h3 className="text-2xl font-bold bg-gradient-to-r from-[#2B3A67] to-[#496BAF] bg-clip-text text-transparent">
            Contact
          </h3>
        </div>
        <ul className="space-y-4">
          {[
            { icon: Mail, text: 'product.aideai@gmail.com' },
            { icon: Phone, text: '+1 (555) 123-4567' },
            { icon: MapPin, text: '123 Education St, NY 10001' }
          ].map((item) => (
            <li key={item.text} className="flex items-center gap-3 group">
              <item.icon className="h-5 w-5 text-[#4C9AFF] group-hover:text-[#7B61FF] transition-colors" />
              <span className="text-gray-600">{item.text}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>

    <div className="pt-8 border-t border-gray-200">
      <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
        <p className="text-gray-600 text-sm">
          © 2024 <span className="text-[#4C9AFF] font-semibold">AI Education Platform</span>. All rights reserved.
        </p>
        <p className="text-gray-600 text-sm flex items-center space-x-2">
          <span>Made with</span>
          <Heart className="h-4 w-4 text-[#7B61FF] animate-pulse" />
          <span>for educators</span>
        </p>
      </div>
    </div>
  </div>
</footer>
    </div>
  );
}

export default Website;
