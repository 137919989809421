import React, { useState } from "react";
import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
// import { XIcon } from "@heroicons/react/solid"; 
// Cross icon for selected values
import XIcon from '../../../assets/crossIcon.svg'

const MultiSelectWithSearchAndRemove = ({selectedOptions,setSelectedOptions, options ,setClassError}) => {

  // Default selected options
  const [searchQuery, setSearchQuery] = useState("");

  // Filtered options based on search query
  const filteredOptions = options?.filter((option) =>
    option.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Toggle option in selectedOptions array
  const toggleOption = (option) => {
    setSelectedOptions((prev) =>
      prev.find((item) => item.id === option.id)
        ? prev.filter((item) => item.id !== option.id) // Remove if already selected
        : [...prev, option] // Add if not selected
    );
    setClassError("");
  };

  // Remove option when clicking the cross icon
  const removeOption = (id) => {
    setSelectedOptions((prev) => prev.filter((item) => item.id !== id));
  };

  return (
    <div className="w-full mx-auto mt-2">
      <Listbox value={selectedOptions} onChange={setSelectedOptions} multiple>
        {({ open }) => (
          <>
            <Listbox.Button className="relative w-full cursor-default rounded-lg border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500">
              <div className="flex flex-wrap items-center gap-1">
                {selectedOptions.length > 0 ? (
                  selectedOptions.map((option) => (
                    <span
                      key={option.id}
                      className="flex items-center rounded-full border border-gray-300 px-2 py-1 text-sm font-medium text-gray-700"
                    >
                      {option.name}
                      <button
                        type="button"
                        className="ml-1 rounded-full bg-transparent hover:text-blue-600 focus:outline-none"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent dropdown from opening
                          removeOption(option.id);
                        }}
                      >
                        <img src={XIcon} alt='x' className="h-4 w-4" />
                      </button>
                    </span>
                  ))
                ) : (
                  <span className="text-gray-400">Select options</span>
                )}
              </div>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <div className="w-[50%]">
            <Listbox.Options
              className="absolute z-75  mt-1 max-h-60 w-[67%] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              {/* Search bar */}
              {open && (
                <div className="sticky top-0 z-10 bg-white px-3 py-2 w-full">
                  <input
                    type="text"
                    placeholder="Search options..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full   rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              )}
              {/* Filtered options */}
              {filteredOptions?.map((option) => (
                <Listbox.Option
                  key={option.id}
                  value={option}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? "bg-blue-100 text-blue-900" : "text-gray-900"
                    }`
                  }
                  onClick={() => toggleOption(option)}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {option.name}
                      </span>
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <input
                          type="checkbox"
                          checked={selectedOptions?.some(
                            (item) => item.id === option.id
                          )}
                          readOnly
                          className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                        />
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
              {/* No options message */}
              {filteredOptions?.length === 0 && (
                <div className="px-4 py-2 text-gray-500">No options found</div>
              )}
            </Listbox.Options>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default MultiSelectWithSearchAndRemove;
