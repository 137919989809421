// SearchFilter.js
import React from 'react';
import FormValidationError from './sharedUi/FormValidatorError';
import {grades} from '../utils/constants'

const SearchFilter = ({handleFilterChange, filters, topicError,GradeError}) => {
 
  

  return (
    <div className="mt-16 mb-6 w-[750px] flex flex-col  gap-4">
          <label className="block">
          <span className="text-[#000] font-semibold text-[16px]">Grade:</span>
          <select
            name="Grade"
            value={filters.Grade}
            onChange={handleFilterChange}
            className="block w-full mt-1 p-2 border border-gray-300 rounded-lg"
          >
            <option value="">Select Grade </option>
            {grades?.map((grade ,index)=>(
            <option key={index} value={grade?.value}>{grade?.label}</option>
            ))}
          
          </select>
          {GradeError && 
          <FormValidationError errors={GradeError}/>
          }
        </label>
      <label className="block">
        <span className="text-[#000] font-semibold text-[16px]">Assignment Description:</span>
        <div className='flex relative border border-gray-300 rounded-lg p-2 items-center justify-center'>
        <input
          name="topic"
          value={filters.topic}
          onChange={handleFilterChange}
          className="block flex-1  outline-none rounded-lg h-10 "
          placeholder="Type Here to generate a new essay topic, assign your own, or search past paper by year"
          required
        />
        {filters?.Grade &&
        <div>
         <span className='text-[#000] flex-1 font-semibold text-[16px]'> For Grade {filters?.Grade}</span>
        </div>
} 
        </div>
         {topicError && 
        <FormValidationError errors={topicError}/>
        }
      </label>
    </div>
  );
};

export default SearchFilter;
