import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

const StandaloneTimer = ({ 
  selecAssignment,
  handleTimeUp, 
  AutoSaveDraft,
  timerData,
  startAssignment,
  lastFetchTimeRef,
  
}) => {
  const [timeRemaining, setTimeRemaining] = useState(null);

const timeRemainingRef = useRef(timeRemaining);

  const [isTimerActive, setIsTimerActive] = useState(false);
  const timerIntervalRef = useRef(null);
  const autoSaveIntervalRef = useRef(null);

  // Handle Start Timer
  const handleStartTimer = () => {
    const data = { student_assignment_id: selecAssignment?.studentAssignmentId };
    lastFetchTimeRef.current = Date.now();
    
    startAssignment(data, {
      onSuccess: (response) => {
        if (response.success) {
          const timeElapsed = Math.floor((Date.now() - lastFetchTimeRef.current) / 1000);
          const minutes = response.minutesLeft || 0;
          const seconds = response.secondsLeft || 0;
          const totalSeconds = (minutes * 60) + seconds - timeElapsed;
          setTimeRemaining(Math.max(0, totalSeconds));
          setIsTimerActive(true);
          toast.success(response?.message, {
            position: "bottom-right"
          });
        }
      },
      onError: (error) => {
        toast.error('Failed to start assignment', {
          position: "bottom-right"
        });
      }
    });
  };

  // Initial timer setup
  useEffect(() => {
    if (timerData?.status === 'In Progress') {
      lastFetchTimeRef.current = Date.now();
      const timeElapsed = Math.floor((Date.now() - lastFetchTimeRef.current) / 1000);
      const minutes = timerData?.minutesLeft || 0;
      const seconds = timerData?.secondsLeft || 0;
      const totalSeconds = (minutes * 60) + seconds - timeElapsed;
      
      if (!isNaN(totalSeconds)) {
        setTimeRemaining(Math.max(0, totalSeconds));
        setIsTimerActive(true);
      }
    } else {
      if (selecAssignment?.studentAssignmentId) {
        handleStartTimer();
      }
    }
  }, [timerData?.status]);

  // Timer countdown
  useEffect(() => {
    if (isTimerActive && timeRemaining !== null && timeRemaining > 0) {
      timerIntervalRef.current = setInterval(() => {
        setTimeRemaining((prev) => {
          if (prev <= 1) {
            clearInterval(timerIntervalRef.current);
            clearInterval(autoSaveIntervalRef.current);
            handleTimeUp();
            setIsTimerActive(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
      }
    };
  }, [isTimerActive, timeRemaining, handleTimeUp]);

  useEffect(() => {
    timeRemainingRef.current = timeRemaining;
  }, [timeRemaining]);

  useEffect(() => {
    if (isTimerActive && timerData?.status === 'In Progress') {
      autoSaveIntervalRef.current = setInterval(() => {
        AutoSaveDraft(); // This will now only save if there are changes
      }, 5000); // Every 60 seconds
    }
    return () => {
      if (autoSaveIntervalRef.current) {
        clearInterval(autoSaveIntervalRef.current);
      }
    };
  }, [isTimerActive, timerData?.status]);

  // Warning notifications
  useEffect(() => {
    if (timeRemaining === 300) { // 5 minutes
      toast.warning('5 minutes remaining!', {
        position: "bottom-right"
      });
    } else if (timeRemaining === 60) { // 1 minute
      toast.warning('1 minute remaining!', {
        position: "bottom-right"
      });
    }
  }, [timeRemaining]);

  const formatTime = (totalSeconds) => {
    if (totalSeconds === null || isNaN(totalSeconds)) return '00:00';
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="flex flex-col justify-end" style={{ isolation: 'isolate' }}>
      {/* {(timerData?.status === 'In Progress' && !timerData?.isStarted) && (
        <div className="text-center">
          <button
            onClick={handleStartTimer}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Start Assignment
          </button>
        </div>
      )} */}
      {timerData?.status === 'In Progress' && (
        <div className="flex gap-2 text-center items-center">
          <h3 className="text-lg font-bold">Time Remaining:</h3>
          <div 
            className={`text-2xl font-bold border border-gray-600 shadow-lg rounded-lg p-1 ${
              timeRemaining <= 300 ? 'text-red-500' : 'text-blue-500'
            }`}
          >
            {formatTime(timeRemaining)}
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(StandaloneTimer, (prevProps, nextProps) => {
  // Custom comparison for memo
  return (
    prevProps.selecAssignment?.studentAssignmentId === 
    nextProps.selecAssignment?.studentAssignmentId &&
    prevProps.timerData?.status === nextProps.timerData?.status
  );
});