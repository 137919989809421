import React, { useRef,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { grades } from "../../../utils/constants";
import UploadFileIcon from "../../../assets/uploadFile.svg";
import BackIcon from "../../../assets/arrowBackIcon.svg";
import Loader from "../../../components/sharedUi/loader";
import { toast } from "react-toastify";
import { useComprehensionContext } from "../../../components/context/comprehensionContext";
import { useUploadPassageFile } from "../../../utils/api/compreApi";
import CrossIcon from "../../../assets/closeIcon.svg";
import FormValidationError from "../../../components/sharedUi/FormValidatorError";
const ComprehensionData = () => {
  const {
    topic,
    grade,
    uploadedFiles,
    updateComprehensionData,
    handleGenerate,
    generateComAiTopicPending,
    resetComprehensionData,
    wordLength,
    errors,
    setErrors

  } = useComprehensionContext();

  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  console.log("uploadedFiles", uploadedFiles);
  const { mutate: uploadPassageFile } = useUploadPassageFile();

  const handleBack = () => {
    resetComprehensionData();
    navigate(-1);
  };

  const handleUploadPassage = (event) => {
    event.preventDefault(); // Prevent any default behavior

    const file = event.target.files[0];
    console.log("Initial file selection", file);

    if (!grade) {
      console.log("grade", grade);
      setErrors(prev => ({ ...prev, grade: 'Grade is required' }));
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
    }
    return;
      
  }
    if (file) {
      console.log("file", file);
      updateComprehensionData({ uploadedFiles: file?.name });
      uploadPassageFile(file, {
        onSuccess: (response) => {
          if (response?.success) {
            console.log("fileresponse", response);
            updateComprehensionData({
              passage: response,
              selectedTopic: {
                  id: response?.passage?.topic_id,
                  title: response?.passage?.title,
                  word_length: response?.passage?.word_count,
              },
              passageContent: response?.passage?.content
          });

            navigate("/dashboard/comprehension/passage-review");
          }
        },
        onError: (error) => {
          console.log("error", error);
          updateComprehensionData({ uploadedFiles: '' });
          if (fileInputRef.current) {
              fileInputRef.current.value = '';
          }
        },
      });
    }
  };

  const handleDivClick = () => {
    fileInputRef.current?.click();
  };

  const handleRemoveFile = (e) => {
    e.stopPropagation(); // Prevent click from bubbling to parent div
    updateComprehensionData({ uploadedFiles: "" });
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset file input
    }
  };


  useEffect(() => {
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            
            // Check both fields
            if (!grade && !topic) {
                setErrors({
                    grade: 'Grade is required',
                    topic: 'Topic is required'
                });
                return;
            }

            // Check grade only
            if (!grade) {
                setErrors(prev => ({
                    ...prev,
                    grade: 'Grade is required'
                }));
                return;
            }

            // Check topic only
            if (!topic) {
                setErrors(prev => ({
                    ...prev,
                    topic: 'Topic is required'
                }));
                return;
            }

            // If both fields are filled, proceed with generation
            if (topic && grade) {
                handleGenerate();
            }
        }
    };

    // Add event listener
    document.addEventListener("keydown", handleKeyPress);

    // Cleanup
    return () => {
        document.removeEventListener("keydown", handleKeyPress);
    };
}, [topic, grade, handleGenerate, setErrors]); // Add all dependencies
  return (
    <>
      {generateComAiTopicPending && <Loader />}
      <div className="bg-gray-100 min-h-screen px-6 flex flex-col pt-2 item-center">
        <div className="flex justify-start">
          <p
            className="flex gap-2 items-center py-2 w-fit cursor-pointer"
            onClick={handleBack}
          >
            <img src={BackIcon} alt="backicon" />
            Back
          </p>
        </div>
        <div className="bg-white p-4 rounded-lg">
          <div className="p-4 flex flex-col items-center justify-center gap-4">
            <div className="flex flex-col justify-center items-center w-full pb-10">
              <h1 className="text-2xl font-bold">
                Generate Comprehension Topics
              </h1>
              <p className="text-gray-500">
                Generate high-quality comprehension topics using AI
              </p>

              <div className="mt-16 mb-6 w-[750px] flex flex-col gap-4">
                <label className="block">
                  <span className="text-black font-semibold">Grade:</span>
                  <select
                    value={grade}
                    onChange={(e) =>
                      updateComprehensionData({ grade: e.target.value })
                    }
                    className="block w-full mt-1 p-2 border rounded-lg"
                  >
                    <option value="">Select Grade</option>
                    {grades?.map((grade) => (
                      <option key={grade.value} value={grade.value}>
                        {grade.label}
                      </option>
                    ))}
                  </select>
                  {errors.grade && <FormValidationError errors={errors.grade} />}

                </label>

                <div className="flex flex-col gap-4 items-center w-full mt-4">
                  <div
                    onClick={handleDivClick}
                    className="cursor-pointer flex border rounded-lg p-2 items-center justify-center w-full relative"
                  >
                    <img
                      src={UploadFileIcon}
                      alt="Upload"
                      className="w-6 h-6"
                    />
                    <input
                      type="file"
                      accept=".txt,.doc,.docx"
                      onChange={handleUploadPassage}
            onClick={(e) => e.stopPropagation()} // Prevent click event bubbling

                      className="hidden"
                      ref={fileInputRef}
                    />
                    <div className="flex items-center ml-2">
                      <span className="font-semibold">
                        { uploadedFiles || "Upload Your Own Passage"}
                      </span>

                      {/* Cross button aligned with text */}
                      {uploadedFiles && (
                        <img className="mb-5 p-1  h-7 w-7" 
                         onClick={handleRemoveFile}
                          src={CrossIcon} alt="crossicon" />
                      )}
                    </div>
                  </div>
                  <p className="text-gray-500">Or</p>
                </div>

                <input
                  type="text"
                  value={topic}
                  onChange={(e) =>
                    updateComprehensionData({ topic: e.target.value })
                  }
                  placeholder="Enter topic or generate from AI"
                  className="w-full p-2 border rounded-md"
                />
                {errors.topic && <FormValidationError errors={errors.topic} />}

                  <label className="block">
                        <span className="text-black font-semibold text-md"> Word Length :</span>
                        <input
                            type="number"
                            value={wordLength}
                            onChange={(e) => updateComprehensionData({ wordLength: e.target.value })}
                            placeholder="Set word length for the passage (e.g., 100-500)"
                            className="w-full p-2 border rounded-md"
                        />
                    </label>

                <button
                  onClick={handleGenerate}
                  className="mt-2 bg-blue-600 text-white py-3 font-bold text-lg px-6 rounded-lg w-full"
                >
                  Generate Comprehension Topics
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComprehensionData;
