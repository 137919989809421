import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGenerateComAiTopic ,useGenerateComPassage} from '../../utils/api/compreApi';

const ComprehensionContext = createContext();

export const useComprehensionContext = () => useContext(ComprehensionContext);

export const ComprehensionProvider = ({ children }) => {
    const navigate = useNavigate();
    const [questions, setQuestions] = useState([]);
    const [dueDate, setDueDate] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [answers, setAnswers] = useState([]);

    
    const [timeLimit, setTimeLimit] = useState('');
    const [dueDateError, setDueDateError] = useState('');
    const [classError, setClassError] = useState('');
    console.log("questions",questions);

    const updateQuestions = (newQuestions) => {
        setQuestions(newQuestions);
        // Add any API calls or persistence logic here
      };

    // Single state object to manage all comprehension data
    const [comprehensionData, setComprehensionData] = useState({
        topic: '',
        grade: '',
        sessionId: null,
        uploadedFiles:'',
        generatedTopics: [],
        selectedTopic: '',
        wordLength: null,
        questionTypes: [],
        passage:{},
        passageContent:'',
        ModifyPassPrompt:'',
        questionTypes: [],

    });
    console.log("comprehensionData",comprehensionData);
    const [errors, setErrors] = useState({
        grade: '',
        topic: ''
    });
    const updateComprehensionData = (updates) => {
        setComprehensionData(prev => ({
            ...prev,
            ...updates
        }));
        if ('grade' in updates) {
            setErrors(prev => ({ ...prev, grade: '' }));
        }
        if ('topic' in updates) {
            setErrors(prev => ({ ...prev, topic: '' }));
        }
    };

    const resetComprehensionData = () => {
        setComprehensionData({
            topic: '',
            grade: '',
            sessionId: null,
            uploadedFiles: '',
            generatedTopics: [],
            selectedTopic: '',
            wordLength: '',
           
            questionTypes: [],
            passage:{},
        passageContent:'',
        ModifyPassPrompt:'',
        questionTypes: []


        });
    };
    

    const { mutate: generateComAiTopic, isPending: generateComAiTopicPending } = useGenerateComAiTopic();
    const { mutate: generateComPassage, isPending: generateComPassagePending } = useGenerateComPassage();

    const handleGenerate = () => {

        if (!comprehensionData.grade) {
            setErrors(prev => ({ ...prev, grade: 'Grade is required' }));
            return;
        }
        if (!comprehensionData.topic) {
            setErrors(prev => ({ ...prev, topic: 'Topic is required' }));
            return;
        }
        const data = {
            query: comprehensionData.topic,
            sessionId: comprehensionData.sessionId || null,
            grade: comprehensionData.grade
        };

        generateComAiTopic(data, {
            onSuccess: (response) => {
                if (response?.success) {
                    updateComprehensionData({
                        generatedTopics: response,
                        sessionId: response?.sessionId
                    });
                    navigate("/dashboard/comprehension/passage-title");
                }
            },
            onError: (error) => {
                console.error('Error generating topics:', error);
                // Handle error (show toast, etc.)
            }
        });
    };

    const handleSelectedTopic =  (topic) => {
        if (topic) {
            updateComprehensionData({ selectedTopic: topic});
             handleGeneratePassage(topic)
        }
    };

    const handleGeneratePassage = (topic) => {
        const data = {
        topic_id: topic?.id || comprehensionData.selectedTopic?.id,
        grade: comprehensionData.grade,
        wordCount:comprehensionData.wordLength,
        sessionId: comprehensionData?.passage?.sessionId || null,
        modificationPrompt:comprehensionData.ModifyPassPrompt || null,
        content:comprehensionData?.passageContent === comprehensionData?.passage?.content ? null :comprehensionData?.passageContent
    }
    generateComPassage(data, {
            onSuccess: (response) => {
                console.log("response",response);
                if(response?.success){
                    
                    updateComprehensionData({passage:response ,passageContent:response?.passage?.content})
                    navigate('/dashboard/comprehension/passage-review');
                }
            },
             onError:()=>{
             console.log("error")
             }   
        });
    };
    const handleDuedate = (e) => {
        const selectedDateTime = new Date(e.target.value);
        const now = new Date();
    
        // Compare full datetime
        if (selectedDateTime < now) {
          setDueDateError("Due date and time cannot be in the past.");
          setDueDate(""); // Clear the invalid date
          return;
        }
    
        // If it's today, check if time is at least 30 minutes from now
        if (selectedDateTime.toDateString() === now.toDateString()) {
          const minTimeGap = 30; // minimum minutes gap required
          const minutesDiff = (selectedDateTime - now) / (1000 * 60);
    
          if (minutesDiff < minTimeGap) {
            setDueDateError(
              `Due time must be at least ${minTimeGap} minutes from now.`
            );
            setDueDate("");
            return;
          }
        }
    
        // If all validations pass
        setDueDate(e.target.value);
        setDueDateError("");
      };

      

          const handleTimeLimit = (e) => {
            const value = e.target.value;
            if (value === "" || /^[0-9]+$/.test(value)) {
              setTimeLimit(value);
            }
          };



          const handleAnswerChange = (questionId, value) => {
            setAnswers(prevAnswers => {
                // Find if an answer for this question already exists
                const existingAnswerIndex = prevAnswers?.findIndex(
                    ans => ans.question_id === questionId
                );
    
                if (existingAnswerIndex !== -1) {
                    // Update existing answer
                    const newAnswers = [...prevAnswers];
                    newAnswers[existingAnswerIndex] = {
                        question_id: questionId,
                        answer: value
                    };
                    return newAnswers;
                } else {
                    // Add new answer
                    return [...prevAnswers, {
                        question_id: questionId,
                        answer: value
                    }];
                }
            });
        };


    const value = {
        ...comprehensionData,
        updateComprehensionData,
        resetComprehensionData,
        handleGenerate,
        handleSelectedTopic,
        generateComAiTopicPending,
        generateComPassagePending,
        handleGeneratePassage,
        questions,
        setQuestions,
        updateQuestions,
        dueDate,
        timeLimit,
        handleDuedate,
        setDueDateError,
        handleTimeLimit,
        dueDateError,
        classError,
        setClassError,
        dueDateError,
        selectedOptions,
        setSelectedOptions,
        answers,
        setAnswers,
        handleAnswerChange,
        errors,
        setErrors


    };

    return <ComprehensionContext.Provider value={value}>{children}</ComprehensionContext.Provider>;
};