/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useMutation } from "@tanstack/react-query";
import { client } from "./clientApi";
import { toast } from "react-toastify";



export const useLogin = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await client("auth/login", { data });
      // If response is a string, it's an error message
      if (typeof response === "string") {
        throw new Error(response);
      }
      return response;
    },
    onSuccess: (data) => {
      // Store user data
      localStorage.setItem("UserData", JSON.stringify(data));
      localStorage.setItem("Token", JSON.stringify(data.token));
      return data;
    },
    onError: (error) => {
      throw error;
    },
  });

export const useSignUp = () =>
  useMutation({
    mutationFn: async (data) => {
      return client("auth/register-teacher", {
        data,
      });
    },
    onSuccess: (response) => {
      console.log("🚀 ~ response:", response)
      // toast.success(response?.message)
      // localStorage.setItem("UserData", JSON.stringify(response.user));
    },
    onError: (error) => {
      toast.error(error?.message, {
        position: "bottom-right"
      })
      console.log("Login failed", error);
    },
  });

  export const useSignUpStudent = () =>
    useMutation({
      mutationFn: async (data) => {
        return client("auth/register-student", {
          data,
        });
      },
      onSuccess: (response) => {
        // toast?.success(response?.message)
       
        // localStorage.setItem("UserData", JSON.stringify(response.user));
      },
      onError: (error) => {
        console.log("Login failed", error);
      },
    });

export const useVerifyEmail = () =>
  useMutation({
   mutationFn: async (data) =>{
    return  await client(`recovery/forgot-password`, {
        data,
      })
    },
    onSuccess: (response) => {
      console.log("🚀 ~ response:", response)
      // localStorage.setItem("UserData", JSON.stringify(response.user));
    },
    onError: (error) => {
      console.log("Login failed", error);
    },
  });
  export const useResetPassword = () =>
    useMutation({
     mutationFn: async (data) =>{
      return  await client(`recovery/reset-password`, {
          data,
        })
      },
      onSuccess: (response) => {
        console.log("🚀 ~ response:", response)
        // localStorage.setItem("UserData", JSON.stringify(response.user));
      },
      onError: (error) => {
        console.log("Login failed", error);
      },
    });

    export const useChangePassword = () =>
      useMutation({
       mutationFn: async (data) =>{
        return  await client(`auth/change-password`, {
            data,
          })
        },
        onSuccess: (response) => {
          console.log("🚀 ~Password changedresponse:", response)
          // localStorage.setItem("UserData", JSON.stringify(response.user));
        },
        onError: (error) => {
          console.log("Login failed", error);
        },
      });
// export const useGetOtp = () =>
//   useMutation(
//     async (email) =>
//       await client(`auth/forgotPassword`, {
//         data: { email },
//       })
//   );

// export const useVerifyOtp = () =>
//   useMutation(
//     async (data) =>
//       await client(`auth/verifyOtpForForgotPassword`, {
//         data,
//       })
//   );

// export const useReSendOtp = () =>
//   useMutation(
//     async (email) =>
//       await client("auth/resendOtp", {
//         data: { email },
//       })
//   );

// export const useResetPassword = () =>
//   useMutation(
//     async (password) =>
//       await client("auth/resetPassword", {
//         data: { password },
//         headers: {
//           Authorization: localStorage.getItem("otp_token") || "",
//         },
//       })
//   );

// export const useChangePassword = () =>
//   useMutation({
//     mutationFn: async (body) => {
//       const oldPassword = body?.oldPassword;
//       const password = body?.password;
//       let token = localStorage.getItem("Token");

//       if (token.startsWith('"') && token.endsWith('"')) {
//         token = token.slice(1, -1);
//       }
//       return client("auth/changePassword", {
//         data: { oldPassword, password },
//         headers: {
//           Authorization: token,
//         },
//       });
//     },
//     onSuccess: (response) => {
//       console.log("Password updated successfully");
//     },
//     onError: (error) => {
//       console.log("Change password failed", error);
//     },
//   });

// export const useUpdateProfile = () =>
//   useMutation({
//     mutationFn: async (data) => {
//       return client("auth/updateProfile", {
//         data,
//       });
//     },
//     onSuccess: (response) => {
//       console.log(response);
//     },
//     onError: (error) => {
//       console.log("Login failed", error);
//     },
//   });

//hook for image file upload
// export const useImageUpload = () =>
//   useMutation({
//     mutationFn: async (file) => {
//       const formData = new FormData();
//       formData.append("file", file);
//       return imageUploadClient("media-upload/mediaFiles/image", {
//         data: formData,
//       });
//     },
//     onSuccess: (response) => {
//       console.log("Image uploaded successfully:", response);
//     },
//     onError: (error) => {
//       console.error("Image upload failed", error);
//     },
//   });
