import React, { useCallback, useEffect ,useState,useRef} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AssignmentTimer from "./AssignmentTimer";
import AssignmentEditor from "./AssignmentEditor";
import { toast } from "react-toastify";
import axios from "axios";
import moment from 'moment-timezone';
import Loader from "../../../components/sharedUi/loader";
import AIButton from "../../../components/AiButton";
import {
  useGetAssignment,
  useSubmitEssay,
  useContinousSaing,
  useGetSaveContent,
} from "../../../utils/api/userApi";
import { useTimerContext } from "../../../components/context/timerContext";


const AssignmentEditing = () => {
  const { state } = useLocation();
  console.log("🚀 ~ AssignmentEditing ~ state:", state)
  const navigate = useNavigate();
  const editorRef = useRef(null);

  const data = JSON.parse(localStorage.getItem("UserData"));
  const { essayContent, setEssayContent,
    handleEssayContent } =useTimerContext();

 
  const { data: getSaveContent, refetch } = useGetSaveContent(
    state?.selecAssignment?.studentAssignmentId,
    {
      enabled: !!state?.selecAssignment?.studentAssignmentId, // Enable fetch when ID exists
      refetchOnWindowFocus: false,
      refetchOnMount: true, // Fetch when component mounts
      cacheTime: 0, // Don't cache the result
    }
  );
  console.log("🚀 ~ AssignmentEditing ~ essayContent:", state);
  const [isExpanded, setIsExpanded] = useState(false);
  const { mutate: submitEssay, isPending } = useSubmitEssay();
  const { mutate: continousSaing, isPending: isContinousSaingPending } =
    useContinousSaing();

  const handleTimeUp = () => {
    // Auto submit logic here
    toast.error("Time is up!", {
      position: "bottom-right"
    });
    handleSubmission()
  };
  const autoSaveDraft = useCallback((timeRemaining) => {

    console.log("Auto Save Triggered");
    const latestContent = editorRef.current?.getLatestContent() || essayContent;
    console.log(timeRemaining,'timeRemaining in autosave')

    const data = {
      student_assignment_id: state?.selecAssignment?.studentAssignmentId,
      content: latestContent,
      clientTimeRemaining:timeRemaining
    };
    continousSaing(data, {
      onSuccess: (response) => {
        console.log("response:", response);
        // toast.success(response?.message || "Content auto-saved!");
      },
      onError: (error) => {
        toast.error("Auto-save failed: " + error.message, {
          position: "bottom-right"
        });
      },
    });
  }, [state?.selecAssignment?.studentAssignmentId, essayContent,continousSaing]);

  useEffect(() => {
    if (state?.selecAssignment?.studentAssignmentId) {
      refetch();
    }
  }, [state?.selecAssignment?.studentAssignmentId, refetch]);

  useEffect(() => {
    console.log("hello");
    if (getSaveContent?.content !== null) {
      console.log("hello2");

      setEssayContent(getSaveContent?.content);
    } else {
      console.log("hello3");
    }
  }, [getSaveContent, setEssayContent]);

  const handleSubmission = async () => {
    const latestContent = editorRef.current?.getLatestContent() || essayContent;
    const data = {
      essayContent: latestContent,
      student_assignment_id: state?.selecAssignment?.studentAssignmentId,
    };
    submitEssay(data, {
      onSuccess: (response) => {
        if(response?.success){
        console.log("response:", response);
        navigate("/dashboard/assignment");
        setEssayContent("");
        toast.success(response?.message || "Essay submitted successfully!", {
          position: "bottom-right"
        });
        }
      },
      onError: (error) => {
        toast.error("Submission failed: " + error.message, {
          position: "bottom-right"
        });
      },
    });
  };

  const handleUpload = async (file) => {
    if (!file) {
      toast.error('Please select a file first', {
        position: "bottom-right"
      });
      return;
    }

    
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/ocr/extract-text`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success('File uploaded and text extracted successfully!', {
        position: "bottom-right"
      });
      console.log('Extracted text:', response);
      setEssayContent(response?.data?.text);

      
      // Reset file input
      
      // Reset file input element
      document.getElementById('fileInput').value = '';
      
    } catch (error) {
      console.error('Upload error:', error);
      toast.error(error.response?.data?.message || 'Error uploading file', {
        position: "bottom-right"
      });
    } 
  };

 

  return (
    <>
      {isPending && <Loader />}
      <div className="w-[100%] p-5">
        {/* <div className="flex justify-between">
          <div className="flex flex-col">
            <div className="flex items-center gap-2">
              <h1 className="text-[12px] text-gray-700 font-[700]">Title :</h1>
              <h1 className="text-[14px] font-extrabold text-[#000000]">
                {state?.assignment?.essayTitle}
              </h1>
            </div>
            <div className="flex items-center gap-2">
              <h1 className="text-[12px] text-gray-700 font-[700]">
                Student Name :
              </h1>
              <h1 className="text-[14px] font-extrabold text-[#000000]">
                {data?.name}
              </h1>
            </div>
            <div className="flex items-center gap-2">
              <h1 className="text-[12px] text-gray-700 font-[700]">
                Class Name :
              </h1>
              <h1 className="text-[14px] font-extrabold text-[#000000]">
                {state?.assignment?.classroomName}
              </h1>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-2 ">
              <input
                id="fileInput"
                className="hidden"
                type="file"
                onChange={(e) => handleUpload(e.target.files[0])}
              />
              <button
                onClick={() => document.getElementById("fileInput").click()}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Upload File
              </button>
            </div>
            {state?.assignment?.timeLimit === null || state?.assignment?.timeLimit === 0 ?'':
            <AssignmentTimer
              selecAssignment={state?.assignment}
              handleTimeUp={handleTimeUp}
              autoSaveDraft={autoSaveDraft}
            />
}
          </div>
        </div> */}
        <div className="flex flex-col gap-4">
  <div className="flex justify-between">
    <div className="flex flex-col">
      <div className="flex items-center gap-2">
        <h1 className="text-[12px] text-gray-700 font-[700]">Title :</h1>
        <div className="flex items-center gap-2">
          <h1 className="text-[14px] font-extrabold text-[#000000]">
            {state?.selecAssignment?.essayTitle}
          </h1>
         
        </div>
      </div>
      
      <div className="flex items-center gap-2">
        <h1 className="text-[12px] text-gray-700 font-[700]">Teacher Name :</h1>
        <h1 className="text-[14px] font-extrabold text-[#000000]">
          {state?.selecAssignment?.teacherName}
        </h1>
      </div>
      
      <div className="flex items-center gap-2">
        <h1 className="text-[12px] text-gray-700 font-[700]">Class Name :</h1>
        <h1 className="text-[14px] font-extrabold text-[#000000]">
          {state?.selecAssignment?.classroomName}
        </h1>
        <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-blue-600 hover:text-blue-800 text-sm font-medium"
          >
            {isExpanded ? 'View Less' : 'View More'}
          </button>
      </div>
     
    </div>
    <div className="flex items-center gap-2 ">
      {state?.selecAssignment?.timeLimit === null || state?.selecAssignment?.timeLimit === 0 ? (
        ''
      ) : (
        <div className="timer-container" style={{ isolation: 'isolate' }}>
        <AssignmentTimer
          selecAssignment={state?.selecAssignment}
          handleTimeUp={handleTimeUp}
          autoSaveDraft={autoSaveDraft}
        
        />
      </div>
      )}
    </div>
  </div>

  {/* Expandable Section */}
  {isExpanded && (
    <div className="mt-2 space-y-4 bg-gray-50 p-4 rounded-lg">
      <div className="grid grid-cols-2 gap-4">
        {state?.selecAssignment?.wordLimit && (
        <div>
          <h3 className="text-[12px] text-gray-700 font-[700] mb-1">Word Limit:</h3>
          <p className="text-[14px] text-gray-800">{state?.selecAssignment?.wordLimit} words</p>
        </div>
        )}
        {state?.selecAssignment?.timeLimit && (
        <div>
          <h3 className="text-[12px] text-gray-700 font-[700] mb-1">Time Limit:</h3>
          <p className="text-[14px] text-gray-800">{state?.selecAssignment?.timeLimit} minutes</p>
        </div>
        )}
        <div>
          <h3 className="text-[12px] text-gray-700 font-[700] mb-1">Due Date:</h3>
          <p className="text-[14px] text-gray-800">
             {/* {new Date(state?.selecAssignment?.dueDate).toLocaleString()} */}
             {moment.utc(state?.selecAssignment?.dueDate).format('MM/DD/YYYY, h:mm:ss A')}
             
             </p>
        </div>
      </div>  

      <div>
        <h3 className="text-[12px] text-gray-700 font-[700] mb-1">Description:</h3>
        <p className="text-[14px] text-gray-800">{state?.selecAssignment?.essayDescription}</p>
      </div>
      
      <div>
        <h3 className="text-[12px] text-gray-700 font-[700] mb-1">Questions:</h3>
        <ul className="list-disc pl-5 space-y-2">
          {state?.selecAssignment?.essayQuestions.map((question, index) => (
            <li key={index} className="text-[14px] text-gray-800">{question.Question}</li>
          ))}
        </ul>
      </div>
    </div>
  )}
</div>
        <div className=" w-full">
             <AssignmentEditor  ref={editorRef}/>
          
        </div>
            
        <div className="flex justify-end">
          <button onClick={handleSubmission} className="bg-blue-500 mt-2 text-white px-4 py-2 rounded hover:bg-blue-600">
            Submit Assignment
          </button>
          
        </div>
      </div>
    </>
  );
};

export default AssignmentEditing;
