import React, { useState, useEffect } from 'react';
import BackIcon from '../../../assets/arrowBackIcon.svg';
import { useNavigate } from 'react-router-dom';
import { useGetEvaluation } from "../../../utils/api/userApi";
import { useApproveEvaluation } from "../../../utils/api/userApi";
import Loader from '../../../components/sharedUi/loader';
import { toast } from 'react-toastify';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import moment from 'moment-timezone';
const AssignmentEvaluationView = ({ 
    setShowEvaluation,assignment,
     evaluation ,EditEvaluation,
     evaluations,
    handleApproveEvaluation,
    handleEditAiEvaluation,
    handleEvaluationChange,
    fetchEvaluation,
    evaluationReportPending,
    approveEvaluationPending
    
}) => {
  const navigate = useNavigate();
  console.log("evaluations:", evaluations);
  console.log("evaluation:", evaluation);

  const [isExpanded, setIsExpanded] = useState(false);


  useEffect(() => {
    if (evaluation?.evaluation_id) {
        console.log("fetching evaluation");
        fetchEvaluation();
    }
  }, [evaluation?.evaluation_id]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        if(EditEvaluation){
          e.preventDefault();
          handleEditAiEvaluation()
        }
    }
    };



    // Add event listener
    document.addEventListener("keydown", handleKeyPress);

    // Cleanup
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleEditAiEvaluation]);

 
  const handleBackClick = async () => {
    // Fetch the evaluation data before navigating back
    
    
    setShowEvaluation(false); // Close the evaluation view
   
};

  return (
    <>
    {(evaluationReportPending || approveEvaluationPending ) && <Loader/>}
  
    <div className="fixed inset-0 bg-white z-[90] flex flex-col overflow-y-auto ">
      {/* Top Bar */}
      <div className=" bg-gray-50 py-4 px-4 sm:px-6 lg:px-8">
       
         <div className="flex items-center gap-4">
          <button 
            onClick={handleBackClick}
            className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
          >
            <img src={BackIcon} alt="back" className="w-5 h-5" />
            <span>Back</span>
          </button>
         
        </div>
    <div className="mx-auto">
  <div className="flex flex-col gap-1 mb-8">
    <h1 className="text-3xl font-bold text-gray-900">Assignment Evaluation</h1>
    <div className="flex flex-col gap-2">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <h2 className="text-xl text-gray-700 italic">{evaluations?.evaluation?.metadata?.essay_title}</h2>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-blue-600 hover:text-blue-800 font-medium"
          >
            {isExpanded ? 'View Less' : 'View More'}
          </button>
        </div>
        
        {evaluation?.status === 'Graded' ? (
          ''
        ) : (
          <button
            onClick={handleApproveEvaluation}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            <CheckCircleIcon className="h-5 w-5 mr-2" />
            Approve Evaluation
          </button>
        )}
      </div>    
      {/* Expandable Section */}
      {isExpanded && (

        <div className=" space-y-3 bg-gray-50 p-4 rounded-lg">
        <div className="grid grid-cols-3 gap-4  bg-gray-50  rounded-lg">
       <div className="flex items-center gap-2">
         <span className="text-sm font-semibold text-gray-700">Student:</span>
         <span className="text-sm text-gray-600">{evaluations?.evaluation?.metadata?.student_name}</span>
       </div>
       <div className="flex items-center gap-2">
         <span className="text-sm font-semibold text-gray-700">Teacher:</span>
         <span className="text-sm text-gray-600">{evaluations?.evaluation?.metadata?.teacher_name}</span>
       </div>
       <div className="flex items-center gap-2">
         <span className="text-sm font-semibold text-gray-700">Class:</span>
         <span className="text-sm text-gray-600">{evaluations?.evaluation?.metadata?.classroom_name} (Grade {evaluations?.evaluation?.metadata?.classroom_grade})</span>
       </div>
     
    {evaluations?.evaluation?.metadata?.word_limit &&(
       <div className="flex items-center gap-2">
         <span className="text-sm font-semibold text-gray-700">Word Limit:</span>
         <span className="text-sm text-gray-600">{evaluations?.evaluation?.metadata?.word_limit} words</span>
       </div>
     ) }
       <div className="flex items-center gap-2">
         <span className="text-sm font-semibold text-gray-700">Word Count:</span>
         <span className="text-sm text-gray-600">{evaluations?.evaluation?.metadata?.word_count} words</span>
       </div>
       {evaluations?.evaluation?.metadata?.time_limit &&(
       <div className="flex items-center gap-2">
         <span className="text-sm font-semibold text-gray-700">Time Limit:</span>
         <span className="text-sm text-gray-600">{evaluations?.evaluation?.metadata?.time_limit} minutes</span>
       </div>
        )}
       <div className="flex items-center gap-2">
       <span className="text-sm font-semibold text-gray-700">Submission Date:</span>
       <span className="text-sm text-gray-600">
         {new Date(evaluations?.evaluation?.metadata?.submission_date).toLocaleString()}
         
       </span>
     </div>
     <div className="flex items-center gap-2">
       <span className="text-sm font-semibold text-gray-700">Evaluation Date:</span>
       <span className="text-sm text-gray-600">
         {new Date(evaluations?.evaluation?.metadata?.evaluation_date).toLocaleString()}
         {/* {moment.utc(evaluations?.evaluation?.metadata?.evaluation_date).format('MM/DD/YYYY, h:mm:ss A')} */}

       </span>
     </div>
     </div>
          <div>
            <h3 className="font-semibold text-gray-700 mb-2">Description:</h3>
            <p className="text-gray-600">{evaluations?.evaluation?.metadata?.essay_description}</p>
          </div>
          <div>
            <h3 className="font-semibold text-gray-700 mb-2">Questions:</h3>
            <ul className="list-disc pl-5 space-y-2">
              {evaluations?.evaluation?.metadata?.essay_questions.map((question, index) => (
                <li key={index} className="text-gray-600">{question.Question}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  </div>
</div>
         <div className="grid grid-cols-1 lg:grid-cols-2 gap-8  ">
          {/* Assignment Panel */}
          <div className="bg-white rounded-lg shadow-lg  flex flex-col h-[calc(100vh-45px)]">
          <div className=" flex-1 overflow-y-auto mb-2">
            <div className="p-6">
              <div className="prose max-w-none" >
              <div dangerouslySetInnerHTML={{ __html: evaluation?.submission_text }} />
              </div>
            </div>
            </div>
          </div>
            {/* Evaluation Content */}
      <div className="bg-white rounded-lg shadow-lg flex flex-col h-[calc(100vh-45px)] ">
            <div className=" flex-1 overflow-y-auto mb-4">
            <div className=" space-y-6  p-6">
                {/* criteria table */}
            <div className="mb-8">
      <h3 className="text-lg font-semibold mb-4">Evaluation Criteria</h3>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Criterion</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Score</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Key Gains</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Areas for Improvement</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
        {evaluations?.evaluation?.rubric_evaluation?.criteria?.map((criterion, index) => (
          <tr key={index} className="hover:bg-gray-50">
            <td className="px-6 py-4 text-sm font-medium text-gray-900">{criterion.criterion}</td>
            <td className="px-6 py-4 text-sm text-gray-700">
              {criterion?.score}/{criterion?.max_score}
            </td>
            <td className="px-6 py-4 text-sm text-green-600">{criterion.key_gain}</td>
            <td className="px-6 py-4 text-sm text-red-600">{criterion.key_loss}</td>
          </tr>
        ))}
      </tbody>
        </table>
      </div>
    </div>
    {/* level grading table */}
    <div className="mb-8">
      <h3 className="text-lg font-semibold mb-4">Level Grading</h3>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Level</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Marks Range</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Description</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
        {evaluations?.evaluation?.level_grading?.map((level, index) => (
          <tr key={index} className={`hover:bg-gray-50 ${
            level.level === evaluations?.evaluation?.scores?.overall_level?.level 
              ? 'bg-blue-50' 
              : ''
          }`}>
            <td className="px-6 py-4 text-sm font-medium text-gray-900">{level.level}</td>
            <td className="px-6 py-4 text-sm text-gray-700">{level.marks_range}</td>
            <td className="px-6 py-4 text-sm text-gray-700">{level.description}</td>
          </tr>
        ))}
      </tbody>
        </table>
      </div>
    </div>
    {/* score summary */}
    <div className="bg-gray-50 p-6 flex flex-col justify-center items-center gap-4  rounded-lg mb-8">
      <div className="flex justify-evenly item-center gap-8">
        <div className="text-center">
          <p className="text-sm text-gray-500 uppercase">Total Score</p>
          <p className="text-2xl font-bold text-gray-900">{evaluations?.evaluation?.scores?.score}/{evaluations?.evaluation?.scores?.total_possible}</p>
        </div>
        <div className="text-center">
          <p className="text-sm text-gray-500 uppercase">Overall Level</p>
          <p className="text-2xl font-bold text-gray-900">{evaluations?.evaluation?.scores?.overall_level?.level}</p>
        </div>
      </div>
      <div>
          <p className="text-sm text-gray-600">{evaluations?.evaluation?.scores?.overall_level?.description}</p>
      </div>
    </div>
    {/* feedback summary */}
    <div className="space-y-6">
    {evaluations?.evaluation?.summary?.map((item, index) => (
      <div key={index} className="mb-4">
        <h4 className="font-medium text-gray-900 mb-2">{item.title}</h4>
        <p className="text-gray-700">{item.content}</p>
      </div>
    ))}
    </div>
              {/* Modify Evaluation Input */}
              {evaluation?.status === 'Graded'?(
              ''
            ):
              <div className="flex flex-col items-center gap-2 mt-6">
                <div className='w-full'>
                <label htmlFor="comments" className="block text-[16px] font-bold text-gray-700 mb-2">
                  Modify Evaluation
                </label>
                <textarea
                  id="comments"
                  rows={1}
                  className="shadow-sm  p-2 block w-full sm:text-sm border border-gray-500 rounded-md"
                  value={EditEvaluation}
                  onChange={handleEvaluationChange}
                  placeholder="Enter your modified evaluation here..."
                />
                </div>
                <button className="bg-blue-600 text-white px-4 py-2 w-full rounded-md" onClick={handleEditAiEvaluation}>Modify Evaluation</button>
              </div>
}
            </div>
          </div>
          </div>
        </div>
      </div>
      </div>
    
    </>
  );
};

export default AssignmentEvaluationView;