import React,{useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import BackIcon from "../../../assets/arrowBackIcon.svg";
import Loader from '../../../components/sharedUi/loader';
import { useComprehensionContext } from '../../../components/context/comprehensionContext';
import FormValidationError from '../../../components/sharedUi/FormValidatorError';
const PassageTitle = () => {
    const navigate = useNavigate();
    const {
        grade,
        generatedTopics,
        generateComAiTopicPending,
        handleSelectedTopic,
        handleGenerate,
        topic,
        updateComprehensionData,
        generateComPassagePending,
        errors,
        setErrors
    } = useComprehensionContext();

    const handleBack = () => {
        navigate(-1);
    };
    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === "Enter") {
                e.preventDefault();
                // Check topic only
                if (!topic) {
                    setErrors(prev => ({
                        ...prev,
                        topic: 'Topic is required'
                    }));
                    return;
                }
                if (topic ) {
                    handleGenerate();
                }
            }
        };
    
        // Add event listener
        document.addEventListener("keydown", handleKeyPress);
    
        // Cleanup
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [topic, handleGenerate, setErrors]);

    return (
        <>
            {(generateComAiTopicPending || generateComPassagePending)&& <Loader />}
            <div className="bg-gray-100 min-h-screen px-4 flex flex-col item-center">
                <div className="flex justify-start">
                    <p className="flex gap-2 items-center py-2 w-fit cursor-pointer" onClick={handleBack}>
                        <img src={BackIcon} alt="backicon" />
                        Back
                    </p>
                </div>
                <div className='bg-white p-4 rounded-lg'>
                    <h1 className='text-lg font-semibold'>{generatedTopics?.response}</h1>
                    <div className="bg-white shadow rounded-lg table-container table-wrapper max-h-[28rem] overflow-auto">
                        {generatedTopics?.topics?.length > 0 ? (
                            <table className="min-w-full">
                                <thead className='sticky top-0 bg-gray-300 border z-10'>
                                    <tr className="border-b">
                                        <th className="py-2 px-4 text-left">Title</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {generatedTopics?.topics?.map((title, index) => (
                                        <tr
                                            key={index}
                                            onClick={() => handleSelectedTopic(title)}
                                            className="hover:bg-gray-100 cursor-pointer border-b"
                                        >
                                            <td className="py-3 px-4">{title?.title}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <p className="text-gray-500 text-center py-4">No titles found</p>
                        )}
                    </div>

                    <div className="mt-4">
                        <div className="flex relative border rounded-lg p-2 items-center">
                            <input
                                value={topic}
                                onChange={(e) => updateComprehensionData({ topic: e.target.value })}
                                className="flex-1 outline-none"
                                placeholder="Enter a keyword for new topic ideas..."
                            />
                            {grade && (
                                <span className="text-black font-semibold">
                                    For Grade {grade}
                                </span>
                            )}
                        </div>
                        {errors.topic && <FormValidationError errors={errors.topic} />}
                        <button
                            onClick={handleGenerate}
                            className="mt-4 bg-blue-600 text-white py-2 font-bold text-lg px-6 rounded-lg w-full"
                        >
                            Explore More Topics
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PassageTitle;